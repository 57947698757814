import { Box, DialogActions, DialogContent, DialogTitle, IconButton, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Dialog from '@mui/material/Dialog'
import closeIcon from '../../assets/icons/close-modal.svg'
import Button from '../ui/Button'
import { FaceSwapAPI } from '../../bdreams/FaceSwapAPI'
import { useNavigate } from 'react-router-dom'
import styles from '../AgreementsDialog/styles.module.scss'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'

interface IProps {
  open: boolean;
  handleClose: () => void;
  jobId?: string;
}

const ErrorModal = ({ open, handleClose, jobId }: IProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isTablet = useMediaQuery('(max-width:768px)');
  const [swapVideoId, setSwapVideoId] = useState<string | null>(null);

  useEffect(() => {
    const getSwapVideoId = async () => {
      if (jobId) {
        try {
          const videoResult = await FaceSwapAPI.jobStatus(jobId);
          if (videoResult?.swapVideoId) {
            setSwapVideoId(videoResult.swapVideoId);
          }
        } catch {
          // job was not found, id is wrong or missing
        }
      }
    }
    getSwapVideoId();
  }, [jobId])

  const handleTryAgain = () => {
    if (swapVideoId) {
      const videoIndex = FaceSwapAPI.videos.findIndex((video) => video.id === swapVideoId);
      navigate(`/scene${videoIndex + 1}/create`, { state: { videoId: swapVideoId } });
    } else {
      navigate('/selectyourscene');
    }
  }

  const handleErrorClose = () => {
    handleTryAgain();
    handleClose();
  }

  const content = (
    <>
      <DialogTitle sx={{ fontSize: isTablet ? 17 : 40, lineHeight: 1.35, mr: isTablet ? 0 : 3, padding: isTablet ? '0 0.5rem 1.5rem 0.5rem' : undefined }}>
        {t('error_modal_title')}
      </DialogTitle>
      <DialogContent sx={{ mb:3, padding: isTablet ? 1 : undefined }}>
        <Typography variant="body1" sx={{ mb: isTablet ? 2 : 4, fontWeight: 300, color: "#4A525D", fontSize: isTablet ? 14 : 16 }}>
          {
            t('error_modal_description')
          }
        </Typography>
      </DialogContent>
      <DialogActions sx={{ padding: isTablet ? 1 : undefined }}>
        <Box width="100%" display="flex" justifyContent="center">
          <Button
            type="button"
            onClick={handleErrorClose}
          >
            {t('try_again')}
          </Button>
        </Box>
      </DialogActions>
    </>
  )

  return isTablet ? (
    <SwipeableDrawer
      disableSwipeToOpen
      anchor="bottom"
      open={open}
      onOpen={f=>f}
      onClose={handleErrorClose}
      className={styles.dialog}
      sx={{
        // blurring the background when the dialog is open
        backdropFilter: open ? 'blur(5px)' : 'none',
      }}
    >
      {content}
    </SwipeableDrawer>
    ) : (
    <Dialog maxWidth="sm" open={open} onClose={handleErrorClose} sx={{
      '& .MuiDialog-paper': {
        borderRadius: "12px",
        padding: 3,
        width: 830,
        maxWidth: '100%',
      }
    }}>
      {content}
    </Dialog>
  )
}

export default ErrorModal;
