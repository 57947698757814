import React, { useEffect } from 'react';
import clsx from 'clsx';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import setCookieValue from '../../helpers/setCookieValue';
import { useSearchParams } from 'react-router-dom';
import ArText from './ar';
import EnText from './en';
import zhCNText from './zh-CN';
import FrText from './fr';
import DeText from './de';
import ItText from './it';
import EsText from './es';
import PtText from './pt-BR';

const PrivacyPolicy = () => {
    const { t } = useTranslation();

    const [currentQueryParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        document.title = t('pp_page_title');
    }, [t]);

    let PPText = () => <></>;
    switch (t('legal_notice')) {
        case 'ar':
            PPText = ArText;
            break;
        case 'en':
            PPText = EnText;
            break;
        case 'zh-CN':
            PPText = zhCNText;
            break;
        // case 'de':
        //     PPText = DeText;
        //     break;
        case 'fr':
            PPText = FrText
            break;
        case 'it':
            PPText = ItText;
            break;
        case 'es':
            PPText = EsText;
            break;
        case 'pt-BR':
            PPText = PtText;
            break;
        default:
            break;
    }

    return (
        <div className={clsx(styles.wrap, 'wrap', 'qatar-legal')}>
            <p className={clsx('h1', styles.title)}>{t('pp_content_title')}</p>
            <div className="text-container">
                <div className={clsx(styles.text, 'text')}>
                    <PPText />
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
