import React, { useEffect, useRef } from 'react';
import Link from '../../components/ui/Link';
import styles from './styles.module.scss';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { FaceSwapAPI } from '../../bdreams/FaceSwapAPI';
import triggerDataLayer from '../../helpers/dataLayerTrigger';
import pauseIcon from '../../assets/icons/pause.svg';
import playIcon from '../../assets/icons/play.svg';
import VideoVolume from '../../components/ui/VideoVolume';
import { isMobile } from 'react-device-detect';

const SelectStory = () => {
    const { t, i18n: { language } } = useTranslation();
    const isChineseLanguage = language === 'zh-CN';

    const [isVideoPlays, setIsVideoPlays] = React.useState(true);
    const videoRef = useRef<any>();
    const [mouseOverVideo, setMouseOverVideo] = React.useState(false);
    const [videoDidPlay, setVideoDidPlay] = React.useState(false);

    useEffect(() => {
        document.addEventListener('click', onWindowClick);
        return () => {
            document.removeEventListener('click', onWindowClick);
        };
    }, [isVideoPlays, videoDidPlay]);

    useEffect(() => {
        if (isVideoPlays) {
            videoRef.current?.play();
        } else {
            videoRef.current?.pause();
        }
    }, [isVideoPlays, mouseOverVideo]);

    const tryToPlayTheVideo = () => {
        let promise = videoRef.current?.play();
        if (promise !== undefined) {
            promise
              .then(() => {
                  setIsVideoPlays(true);
                  setVideoDidPlay(true);
              })
              .catch((err: any) => {
                  setIsVideoPlays(false);
                  setVideoDidPlay(false);
              });
        }
    }

    useEffect(() => {
        tryToPlayTheVideo();
    }, []);

    const onWindowClick = () => {
        if (!videoDidPlay && !isVideoPlays) {
            tryToPlayTheVideo();
        }
    }

    const onClickStart = () => {
        triggerDataLayer('start');
    };

    useEffect(() => {
        document.title = t('home_page_title');
    }, [t]);

    const videoUrl =
        process.env.NODE_ENV === 'production'
            ? `/content/intro/${
                (localStorage.getItem('user-selected-language') || 'en').toLowerCase()
              }.mp4`
            : FaceSwapAPI.videos[0]?.url;

    return (
        <div className={clsx(styles.wrap, 'wrap')}>
            <div
                onMouseEnter={() => {
                    if (!isMobile) {
                        setMouseOverVideo(true);
                    }
                }}
                onMouseLeave={() => {
                    if (isVideoPlays) {
                        if (!isMobile) {
                            setMouseOverVideo(false);
                        }
                    }
                }}
                className={styles.videobox}
            >
                <video
                    ref={videoRef}
                    src={videoUrl}
                    autoPlay
                    loop
                    playsInline
                    className={styles.video}
                    title={t('title_video_preview')}
                    onLoadedMetadata={() => (videoRef.current.controls = false)}
                    onPlay={() => !isVideoPlays && setIsVideoPlays(true)}
                />
                <VideoVolume
                    videoRef={videoRef}
                    className={styles.videoVolume}
                />
                <button
                    type="button"
                    id={`play-homepage-video-button`}
                    className={clsx(styles.playBtn, {
                        [styles.hide]: isVideoPlays,
                    })}
                    onClick={() => {
                        setIsVideoPlays((prev) => !prev);
                    }}
                    title={isVideoPlays ? t('pause') : t('play')}
                >
                    <img src={isVideoPlays ? pauseIcon : playIcon} alt="" />
                </button>
                <Link
                    id="start-story-button"
                    to="/selectyourscene"
                    title={t('start')}
                    onClick={onClickStart}
                >
                    {t('start')}
                </Link>
            </div>
            <div className={clsx(styles.descbox, { [styles.descboxChinese]: isChineseLanguage })} aria-label={t('begin_love_story')}>
                <h1 className="h1">{t('begin_love_story')}</h1>
                <p className="body1">{t('swap_your_face')}</p>
                <Link
                    id="desktop-start-story-button"
                    to="/selectyourscene"
                    title={t('start')}
                    onClick={onClickStart}
                >
                    {t('start')}
                </Link>
            </div>
        </div>
    );
};
export default SelectStory;
