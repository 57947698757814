export default function ArText(){
  return (
    <ol className="decimal">
      <li>نظرة عامة</li>
      <br/>
      <p>
      تريد لك <b>مجموعة الخطوط الجوية القطرية (ش.م.ق.م)</b> (ويُشار إليها فيما بعد باسم <b>"الخطوط الجوية</b>
      <br />
      <b>القطرية"، أو "نحن" أو غير ذلك من ضمائر الجمع للمتكلم)</b> أن تشعر بالأمان عند استخدام هذا الموقع
      <br />
      الإلكتروني ("<b>الموقع</b>")، ولهذا تلتزم بحماية بياناتك الشخصية وحقك في الخصوصية عند استخدامك له.
      </p>
      <p>
        وإشعار خصوصية Flyqatar.com هذا ("<b>إشعار الخصوصية</b>")، ومعه <a target="_blank" rel="noreferrer" href="/termsandconditions?selLang=ar">الشروط والأحكام</a> الصادرة عنا وسياستنا لملفات تعريف <a target="_blank" rel="noreferrer" href="https://www.qatarairways.com/ar/legal/cookie-policy.html">الارتباط،</a>
        <br />
        <a target="_blank" rel="noreferrer" href="https://www.qatarairways.com/ar/legal/cookie-policy.html">وسياستنا لملفات تعريف الارتباط،</a> يشرح الأساليب التي تتبعها، أو ستتبعها، الخطوط الجوية القطرية ومقدمي الخدمات
        <br />
        الذين تتعامل معهم في معالجة بياناتك الشخصية. وعند استخدام مصطلح "<b>البيانات الشخصية</b>" فإننا نقصد به
        <br />
        أي معلومات تتعلق بشخص طبيعي معروف الهوية أو يمكن تحديد هويته.
      </p>

      <p>
        يُرجى قراءة ما يلي جيداً لتفهم طريقة معالجتنا لبياناتك الشخصية على هذا الموقع.
        <br />
        عند كشفك عن بيانات شخصية نيابةً عن شخص آخر، فأنت تتعهد و تضمن بأن ذلك الشخص الذي تقدم
        <br />
        بياناته إلى الخطوط الجوية القطرية قد أﺫِن لك بالكشف عنها و  قد تم منحه نسخة من إشعار الخصوصية هذا
        <br />
        قبل أن تكشف عن بياناته الشخصية.
      </p>

      <p>
        إذا أدخلنا تغييرات على إشعار الخصوصية هذا، فسنبلغك بالتغييرات بنشر النسخة المعدَّلة على موقعنا. ومع
        <br />
        مراعاة القوانين واجبة التطبيق، فإن النسخة الإنجليزية من هذا الإشعار لها الأولوية في التطبيق على أي ترجمة له
        <br />
        إلى أي لغة أخرى.
      </p>

      <li>ما الحالات التي يسري عليها إشعار الخصوصية هذا؟</li>
      <br/>
      <p>يسري إشعار الخصوصية هذا على معالجة البيانات الشخصية على هذا الموقع.</p>

      <li>مَن المسؤول عن معالجة البيانات الشخصية؟</li>
      <br/>
      <p>
        الخطوط الجوية القطرية، ويقع مقرها الرسمي في "<b>برج الخطوط الجوية القطرية رقم 1، ص.ب. 22550،</b>
        <br />
        <b>الدوحة، دولة قطر</b>"، هي المسؤولة، بصفتها الجهة المتحكمة في البيانات، عن معالجة البيانات الشخصية على
        <br />
        النحو الموضح في إشعار الخصوصية هذا.
      </p>

      <li>أساليبنا في جمع البيانات الشخصية والحصول عليها</li>
      <br/>

      <p>
        يجوز لنا ولمقدمي الخدمات الذين نتعامل معهم جمع البيانات الشخصية أو الحصول عليها منك مباشرة بعدة
        <br />
        طرق، ومنها:
      </p>
      <p>
        <u>من خلال الموقع</u>: يجوز لنا جمع البيانات الشخصية عن طريق الموقع، وذلك مثلاً عندما تستخدم الخدمات
        <br />
        المقدمة عليه.
        <br />
        <u>في المواقع العامة</u>: في أماكن عامة معينة، مثل مراكز التسوق التجارية، يجوز لنا جمع بياناتك الشخصية استناداً إلى
        <br />
        اهتمامك بالاستفادة من الخدمات المقدمة على الموقع واستعدادك لذلك. ومن أمثلة ذلك، سماحك بالتقاط
        <br />
        صور لك عن طريق أجهزتنا الرسمية وتحميلها على الموقع للحصول على مقطع فيديو مُعد لك وتظهر فيه
        <br />
        صورتك.
        <br />
        قد يحتوي موقعنا على روابط تسمح لك بزيارة مواقع تابعة للغير. يُرجى العلم أننا ليس لنا أي نفوﺫ على معالجة
        <br />
        الجهات المتحكمة في البيانات المسؤولة عن تلك المواقع لبياناتك الشخصية ولا نتحمل المسؤولية، بما في ذلك
        <br />
        المسؤولية القانونية، عن طريقة عملهم ولا معالجتهم لبياناتك الشخصية. ومن الضروري أن وتراجع سياسات
        <br />
        خصوصية تلك المواقع وشروط استخدامها.
        <br />
      </p>

      <li>ما البيانات الشخصية التي تُجمع؟</li>
      <br />

      <p>
        "<b>البيانات الشخصية</b>" هي بيانات يمكن تحديد هويتك الشخصية من خلالها أو تتعلق بشخص يمكن التعرف على
        <br />
        هويته، وتشمل:
        <br />
        (i) الصور/ الصور الفوتوغرافية؛ و
        <br />
        (ii) البريد الإلكتروني التجاري.
      </p>

      <p>
        قد نحتاج إلى جمع هذه الأنواع من البيانات الشخصية من أجل السماح لك بإعداد فيلم وفقاً للشروط والأحكام
        <br />
        التي نطبقها. وإذا لم تقدم المعلومات التي نطلبها، فقد لا تتمكن من المشاركة في إعداد فيلم على موقعنا.
      </p>

      <li>ما الهدف من جمع البيانات الشخصية؟</li>
      <br/>

      <p>
        يجوز لنا ولمقدمي الخدمات الذين نتعامل معهم استخدام البيانات الشخصية بالاستناد إلى موافقتك.
      </p>
      <p>
        يجوز استخدام صورتك، بما في ذلك صورتك الفوتوغرافية، لإعداد فيلم/ مقطع فيديو حسب اختيارك، والسماح
        <br />
        لك بالمشاركة في الخدمات المقدمة على هذا الموقع والاستفادة منها. وفي الأماكن العامة وبعد تقديمك
        <br />
        لموافقتك، قد تُعرض صورتك، بما في ذلك صورتك الفوتوغرافية، مؤقتاً على لوحة الإعلانات الرقمية الخاصة بنا
        <br />
        في المكان الذي شاركت فيه.
      </p>
      <p>
        نستخدم بريدك الإلكتروني لنرسل لك رابط الفيلم/ مقطع الفيديو الذي أُعد بعد استخدامك لموقعنا، ولا نستخدم
        <br />
        بياناتك لأي غرض آخر. وتقديم عنوان بريدك الإلكتروني إلينا أمر اختياري إذا رغبت في استلام الفيلم/ مقطع
        <br />
        الفيديو المعد لك على بريدك الإلكتروني.
        <br />
      </p>
      <p>
        عند اشتراكك في نشرتنا الإخبارية، فإننا نستخدم عنوان بريدك الإلكتروني لنرسل لك نشرات إخبارية تضم آخر
        <br />
        أخبارنا. ويمكنك في أي وقت إنهاء اشتراكك في تلقي تلك النشرات باستخدام رابط إلغاء الاشتراك الموجود في كل
        <br />
        نشرة.
      </p>

      <li> اتخاذ القرار آلياً</li>
      <br/>

      <p>
        نستخدم الذكاء الاصطناعي ("<b>AI</b>") لإعداد الفيلم/ مقطع الفيديو الخاص بك.
      </p>
      <p>
        تستخدم تقنية تبديل الوجوه خوارزميات التعلم الآلي المتقدمة والذكاء الاصطناعي للتعرف على وجه شخص في
        <br />
        الصورة و وضع صورة شخص آخر بدلاً منها. وتتضمن خطوات الاستبدال عادة تحديد الوجه ضمن المحتوى ثم
        <br />
        تحليل أهم ملامحه ثم دمج الوجه البديل في الصورة الأصلية أو مقطع الفيديو الأصلي مع مطابقة درجة لون
        <br />
        البشرة والإضاءة والتعبيرات من أجل ضمان دمج الوجه البديل بدون آثار لهذا الاستبدال.
      </p>

      <li>ملفات تعريف الارتباط</li>
      <br/>

      <p>
        ملفات تعريف الارتباط هي ملفات صغيرة تتضمن معلومات وتتولى حفظ واسترجاع معلومات عن زيارتك إلى
        <br />
        موقعنا، مثل كيف دخلت إلى موقعنا أو كيف تنقلت بين أقسامه، وما المعلومات التي أثارت اهتمامك. ويمكنك
        <br />
        معرفة أساليبنا في استخدام ملفات تعريف الارتباط  بالرجوع إلى <a  target="_blank" rel="noreferrer" href="https://www.qatarairways.com/ar/legal/cookie-policy.html">سياسة ملفات تعريف الارتباط</a>.
      </p>

      <li>نقل البيانات</li>
      <br/>

      <p>
        <u>ضمن مجموعة الخطوط الجوية القطرية</u>
      </p>
      <p>
        لا يُصرح لموظفي الخطوط الجوية القطرية بالوصول إلى البيانات الشخصية إلا بالقدر اللازم لتحقيق الغرض
        <br />
        المعني و آداء واجباتهم المهنية.
      </p>
      <p>
        ولأن الخطوط الجوية القطرية مؤسسة عالمية يقع مقرها الرئيسي في الدوحة بدولة قطر، فإن البيانات الشخصية
        <br />
        التي نجمعها قد تُنقل بين الدول التي توجد بها كيانات تابعة لمجموعة الخطوط الجوية القطرية لتحقيق أغراض
        <br />
        تجارية أو إدارية، ويشمل ذلك النقل إلى بلدان قد تكون قوانينها لحماية البيانات أقل شمولاً من قوانين حماية
        <br />
        البيانات في المنطقة الاقتصادية الأوروبية ("<b>EEA</b>"). وعند الضرورة، ستتحقق الخطوط الجوية القطرية من توافر
        <br />
        الضمانات الكافية للالتزام  بشروط نقل البيانات الشخصية بين الدول وفقاً لقوانين حماية البيانات والخصوصية
        <br />
        واجبة التطبيق.
      </p>
      <p>
        <u>خارج مجموعة الخطوط الجوية القطرية</u>
        <br />
        قد نكون مطالبين بالكشف عن بعضٍ من بياناتك الشخصية في الحالات التالية:
      </p>

      <ol className="alpha">
        <li>
          إلى الغير، ومنهم مقدمو الخدمات الذين نتعامل معهم لتقديم خدمة إلينا أو آداء وظيفة لنا أو مقدمو
          <br />
          الخدمات الذين نستعين بهم على أي نحو آخر فيما يتعلق بالخدمات التي نقدمها إليك، ويشمل هؤلاء مقدمو
          <br />
          الخدمات الذين يتصرفون بوصفهم وكلاء لنا والذين يشملون على سبيل المثال لا الحصر مقدمي خدمات الدعم
          <br />
          ومقدمي الخدمات التكنولوجية والاستشاريين المتخصصين والمستشارين القانونيين والمدققين، و /أو
        </li>
        <li>
         إلى الغير، فيما يتصل بتمويل أعمالنا أو أصولنا كلها أو بعضها أو تسنيدها أو التأمين عليها أو إعادة هيكلتها أو
         <br />
         التنازل عنها أو التصرف فيها على أي نحو آخر، أو فيما يتصل بالدفاع في مواجهة أي مطالبة، و /أو
        </li>
        <li>
           إلى السلطات الرسمية، ويشمل ذلك مسؤولي  ﺗﻨﻔﻴﺬ القانون والمحاكم والسلطات الحكومية والتنظيمية وذلك
           <br />
           في أي من الحالتين التاليتين: (أ) إذا كنا نعتقد أن الكشف عن البيانات مطلوب بموجب القانون أو اللوائح واجبة
           <br />
           التطبيق أو الإجراءات القانونية (كالاستجابة إلى أمر استدعاء أو غير ذلك من الأوامر القضائية) أو (ب) لحماية
           <br />
           حقوقنا والدفاع عنها أو حقوق أو سلامة الغير، ويشمل ذلك الدفاع في مواجهة الدعاوى القانونية.
        </li>
      </ol>

      <br />
      <p>
        عند الكشف عن البيانات الشخصية إلى الغير، سنتخذ التدابير التعاقدية والتقنية والتنظيمية المطلوبة لتوفير
        <br />
        الحماية الواجبة لمثل تلك البيانات وفقاً للتشريعات واجبة التطبيق.
        <br />
        قد يتضمن النقل في الحالات المذكورة سابقاً نقل بياناتك الشخصية إلى بلدان غير بلد إقامتك، ويشمل ذلك النقل
        <br />
        إلى بلدان قد تكون قوانينها لحماية البيانات أقل شمولاً من قوانين حماية البيانات في المنطقة الاقتصادية
        <br />
        الأوروبية. وعند الضرورة، ستتحقق الخطوط الجوية القطرية من توافر الضمانات الكافية للالتزام  بشروط نقل
        <br />
        البيانات الشخصية بين الدول وفقاً لقوانين حماية البيانات والخصوصية واجبة التطبيق.
      </p>


      <li>كيف نحافظ على أمان بياناتك؟</li>
      <br/>

      <p>
        تُطبق الخطوط الجوية القطرية تدابير معينة لحماية البيانات الشخصية لأننا ملتزمون بضمان تأمين البيانات
        <br />
        الشخصية. ومن أجل منع الوصول غير المصرح به للبيانات الشخصية أو الكشف غير المصرح به عنها، نتبع تدابي
        <br />
        حماية مادية وتقنية وإدارية لحماية البيانات الشخصية من الإتلاف العرضي أو غير القانوني أو فقدانها عرضيا أو
        <br />
        التلف أو التحوير أو الكشف عنها أو الوصول إليها على نحو غير مصرح به، فضلاً عن جميع صور المعالجة غير
        <br />
        القانونية.
        <br />
        على الرغم من أننا نبذل جهوداً مناسبة في حدود المعقول لحماية البيانات الشخصية، فإننا لا نستطيع أن نضمن
        <br />
        تواصلك معنا لم يعد  آمنا ، فيُرجى التواصل معنا فوراً باستخدام الوسائل الموضحة في قسم "<b>كيفية الاتصال بنا</b>"
        <br />
        في نهاية هذا الإشعار.
      </p>

      <li> استخدام القاصرين للموقع</li>
      <br/>

      <p>
        هذا الموقع غير موجه لمن تقل أعمارهم عن ثمانية عشر (18) عاماً، ونحن لا نتعمد جمع بيانات شخصية من
        <br />
        أشخاص أصغر من 18 عاماً.
      </p>

      <li>الاحتفاظ بالبيانات</li>
      <br/>

      <p>
        سنحتفظ بالبيانات الشخصية للمدة اللازمة لتحقيق الغرض المحدد الذي جُمعت من أجله، أو الأغراض القانونية
        <br />
        أو التجارية للخطوط الجوية القطرية والموضحة في إشعار الخصوصية هذا، وبما يتفق مع سياساتنا للاحتفاظ
        <br />
        بالبيانات، أو وفقاً لما تشترطه القوانين ذات الصلة. وعند حذف البيانات الشخصية، سنتخذ جميع التدابي
        <br />
        المعقولة تجارياً والممكنة فنياً حتى يتعذر استرجاع البيانات أو نسخها، وذلك وفقاً للقوانين واجبة التطبيق.
      </p>

      <li>حقوقك</li>
      <br/>

      <p>
        يمكنك ممارسة حقوقك في الوصول إلى بياناتك وطلب تصحيحها أو محوها أو تقييد معالجتها أو طلب الحصول
        <br />
        على البيانات في صورة إلكترونية قابلة للنقل أو الاعتراض على معالجتها أو حقوقك فيما يتعلق بوسائل اتخاذ القرار
        <br />
        آلياً، بما في ذلك استقراء السمات المميزة، فيما يتعلق بالبيانات الشخصية التي تعالجها الخطوط الجوية القطرية
        <br />
        في أي وقت وذلك بإرسال طلب عن طريق بوابة مخصصة <a target="_blank" rel="noreferrer" href="https://www.qatarairways.com/en/subject-access-request-form.html">لطلبات أصحاب البيانات في الوصول إلى بياناتهم</a> وذلك
        <br />
        على صفحة "اتصل بنا" على موقع qatarairways.com.qa. وقد تكون لك حقوق خصوصية أخرى وذلك
        <br />
        استناداً إلى دولة إقامتك ، بما في ذلك الحقوق المقررة بموجب قانون كاليفورنيا لخصوصية المستهلكين
        <br />
        ("<b>CCPA</b>").
      </p>

      <p>
        وقبل الشروع في تنفيذ طلبك، سنتحقق من هويتك لنضمن أننا نقدم البيانات المطلوبة إلى صاحبها الذي تتعلق
        <br />
        به. وإذا فوضت وكيلاً لتقديم الطلب نيابة عنك، فسنطلب منك أو من الوكيل الذي فوضته تقديم نسخة لنا من
        <br />
        الإذن المكتوب لتقديم الطلب نيابة عنك.
      </p>

      <p>
        لن نطلب منك دفع أي رسوم مقابل الرد على طلبك إلا إﺫا جاز اعتبار ذلك الطلب لا أساس له أو مبالغا فيه.
        <br />
        وعند استلام طلبك، يمكن أن نقدم لك قائمة بالجهات التي تتعامل مع البيانات الشخصية من الغير، وذلك
        <br />
        بموجب قانون حماية المعلومات الشخصية (Personal Information Protection Law) في جمهورية الصي
        <br />
        الشعبية.
        <br />
        إذا كنت تعتقد إننا لم نلتزم بقواعد الخصوصية واجبة التطبيق، فإن لك الحق في تقديم شكوى إلى سلطة حماية
        <br />
        البيانات ﺫات الإختصاص.
      </p>

      <p>
        إذا لم تعد ترغب في تلقي نشراتنا البريدية، فيمكنك إلغاء اشتراكك في أي وقت بالضغط على رابط "unsubscribe"
        <br />
        (إلغاء الاشتراك) الموجود في أي رسالة بريد إلكتروني تحتوي على نشرتنا الإخبارية. كما يمكنك تقديم طلب إلغاء
        <br />
        اشتراك بمراسلة مركز خدمة العملاء بالبريد الإلكتروني أو الاتصال به هاتفياً أو بإرسال طلب إلينا بالبريد على العنوان
        <br />
        التالي:
      </p>

      <p>
        مجموعة الخطوط الجوية القطرية ش.م.ق.م
        <br />
        برج الخطوط الجوية القطرية رقم 1،
        <br />
        ص. ب. 22550،
        <br />
        الدوحة، دولة قطر.
        <br />
        عناية: فريق الخطوط القطرية للتسويق المباشر - التجارة الإلكترونية
      </p>

      <p>
        عند تقديم طلب لإلغاء الاشتراك بإرسال رسالة بريد إلكتروني أو الاتصال بنا أو إرسال خطاب، فقد يستغرق الأم
        <br />
        حوالي  30 يوم عمل لمعالجة طلبك وتحديث أنظمتنا لحذف عنوان بريدك الإلكتروني من قائمتنا البريدية.
      </p>

      <li>كيفية الاتصال بنا أو تقديم شكوى</li>
      <br/>

      <p>
        إذا كانت لديك أي أسئلة أو مخاوف بخصوص معالجة بياناتك الشخصية أو إشعار الخصوصية هذا، يُرجى
        <br />
        التواصل عبر:
      </p>

      <p>
        البريد الإلكتروني: <a target="_blank" rel="noreferrer" href="mailto:digitalbrand@qatarairways.com.qa">digitalbrand@qatarairways.com.qa</a>
        <br />
        مجموعة الخطوط الجوية القطرية ش.م.ق.م
        <br />
        برج الخطوط الجوية القطرية رقم 1،
        <br />
        ص. ب. 22550،
        <br />
        الدوحة، دولة قطر.
        <br />
        عناية: إدارة التسويق
      </p>

      <p>
        يمكنك أيضاً التواصل مع مسؤول حماية البيانات عبر عنوان البريد الإلكتروني هذا
        <br />
        <a target="_blank" rel="noreferrer" href="mailto:dpo@qatarairways.com.qa">dpo@qatarairways.com.qa</a>. ويُرجى العلم أنك قد يحق لك أيضاً تقديم شكوى إلى سلطة الإشراف
        <br />
        المختصة.
      </p>
    </ol>
  )
}
