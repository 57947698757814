export default function EnText() {
    return (
        <div>
            <p>
                Si prega di leggere con attenzione i presenti Termini d'uso
                prima di accedere ed utilizzare l' app Web, uno qualsiasi dei
                suoi servizi, funzioni, materiali o contenuti (“<b>Microsite</b>
                ”). Questo Microsito è di proprietà e gestito da Qatar Airways
                Group Q.C.S.C, una società per azioni del Qatar con sede in
                Qatar Airways Tower 1, Airport Road, Stato del Qatar.
            </p>
            <p>
                OGNI VOLTA CHE L'UTENTE ACCEDE, UTILIZZA, VISUALIZZA, TRASMETTE,
                MEMORIZZA O ARCHIVIA IL PRESENTE MICROSITO (ai.flyqatar.com),
                ACCETTA ESPRESSAMENTE DI ADERIRE AI SUOI TERMINI E CONDIZIONI (I
                "TERMINI D'USO) IN RELAZIONE ALL'ACCESSO AL MICROSITO, COME DI
                SEGUITO DESCRITTO. L'UTENTE GARANTISCE DI ESSERE MAGGIORENNE ED
                IN POSSESSO DELLA CAPACITÀ GIURIDICA DI SOTTOSCRIVERE IL
                PRESENTE ACCORDO E PER UTILIZZARE IL PRESENTE MICROSITO NEL
                RISPETTO DEI PRESENTI TERMINI D'USO. L'UTENTE SI ASSUME ALTRESÌ
                LA PIENA RESPONSABILITÀ ECONOMICA PER QUALUNQUE USO FACCIA DEL
                PRESENTE SITO WEB (NONCHÉ PER L'USO DEL SUO ACCOUNT DA PARTE DI
                TERZI).
            </p>
            <p>
                I termini "utente", "suo", "suoi" e forme analoghe indicano la
                persona che accede al presente Microsito (comprese le persone
                che vi accedono per conto di altri). I termini "noi", "nostro",
                "nostri" e forme analoghe indicano Qatar Airways. Qualora
                l'utente non accetti i presenti Termini d'uso, uno qualsiasi dei
                documenti correlati riportati di seguito o altre restrizioni di
                cui l'utente viene informato durante l'uso del presente
                Microsito, l'utente non è autorizzato ad accedervi e
                utilizzarlo, e accetta di non farlo.
            </p>
            <p>
                Le presenti Condizioni d'uso regolano l'utilizzo da parte
                dell'utente del Microsito e di tutte le applicazioni, software e
                servizi (collettivamente, "Servizi") disponibili sul Microsito,
                fatta eccezione per i casi in cui tali Servizi siano oggetto di
                un accordo separato. Condizioni specifiche possono applicarsi
                all'utilizzo di determinati Servizi e altri elementi forniti
                all'utente sul Microsito ("Contratto/i di servizio"). I termini
                in maiuscolo che non sono altrimenti definiti nelle presenti
                Condizioni d'uso avranno il significato loro attribuito nella
                sezione Definizioni allegata alla fine delle presenti Condizioni
                d'uso.
            </p>
            <h3>Termini d'uso</h3>
            <h3>Utilizzo del Microsito da parte dell'utente </h3>
            <p>
                Sempre nel rispetto da parte dell'utente delle presenti
                Condizioni d'uso, l'utente può scegliere di inviare la sua
                somiglianza ed immagine tramite fotografie (“
                <b>I tuoi contenuti</b>”) al Microsito per l'inclusione nel
                video del film disponibile sul Microsito (“<b>Film</b>”) (lo “
                <b>Scopo</b>”). Il film che incorpora i contenuti dell'utente
                sarà indicato nelle presenti Condizioni d'uso come Film
                modificato.
            </p>
            <p>
                Inviando i propri contenuti al Microsito per l'inclusione nel
                film, l'utente:
            </p>
            <ol className="alpha">
                <li>
                    ci concede il diritto globale, non esclusivo, gratuito,
                    trasferibile, perpetuo, non revocabile e cedibile in
                    sotto-licenza di utilizzare, riprodurre, modificare e creare
                    lavori derivati dai Suoi Contenuti per la finalità
                    descritta;
                </li>
                <br />
                <li>
                    dichiara, garantisce e assicura:
                    <ol className="roman">
                        <li>
                            di avere piena facoltà di concederci diritti sui
                            suoi Contenuti per la finalità descritta;
                        </li>
                        <li>
                            di possedere tutti i diritti, i titoli e gli
                            interessi sui suoi Contenuti necessari per
                            l'utilizzo da parte nostra dei suoi contenuti per la
                            finalità descritta;
                        </li>
                        <li>
                            che l'utilizzo da parte nostra dei suoi contenuti in
                            conformità e nelle modalità previste o consentite
                            dalle presenti Condizioni d'uso non costituisce
                            violazione dei Diritti di proprietà intellettuale di
                            terzi o di qualsiasi altra legge applicabile; e
                        </li>
                        <li>
                            né Qatar Airways, né qualsiasi altra persona che
                            agisca sotto la direzione o con il permesso di Qatar
                            Airways (inclusi i membri del Qatar Airways Group)
                            sarà tenuta a pagare a terzi alcuna licenza o altra
                            commissione in relazione all'utilizzo dei Suoi
                            Contenuti in conformità e nel modo previsto o
                            consentito dai presenti Termini d'uso.
                        </li>
                    </ol>
                </li>
            </ol>
            <br />
            <p>
                L'utente è tenuto ad informarci il prima possibile di qualsiasi
                violazione, sospetta o presunta, dei Diritti di proprietà
                intellettuale di terzi da parte dei propri Contenuti ed a
                collaborare pienamente con noi in qualsiasi azione legale da noi
                intrapresa per far rispettare i nostri Diritti di proprietà
                intellettuale.
            </p>
            <p>
                Nel rispetto delle presenti condizioni d'uso, concediamo
                all'utente un diritto globale, limitato, personale, revocabile,
                gratuito, non trasferibile e non cedibile in sublicenza di
                utilizzare, scaricare, riprodurre, caricare, trasmettere,
                tradurre e modificare il Film modificato esclusivamente ad uso
                personale (e non per scopi commerciali di alcun tipo).
            </p>
            <p>
                Tutti i diritti non espressamente concessi all'utente nelle
                presenti Condizioni d'uso sono riservati a noi.
            </p>
            <h3>
                Qatar Airways - Diritto di proprietà e di concessione di licenze
            </h3>
            <p>
                Tutti i Diritti di proprietà intellettuale associati al
                Microsito ed ai suoi contenuti, inclusi il Film e il Film
                modificato (il "<b>Contenuto</b>") sono di esclusiva proprietà
                di Qatar Airways Group Q.C.S.C o di terze parti. Il Contenuto
                del nostro Microsito è protetto dalle leggi applicabili sul
                copyright e da altre leggi.
            </p>
            <p>
                I Contenuti del Microsito sono, inoltre, protetti dalle
                normative in materia di segreto commerciale, concorrenza sleale
                e da altre leggi e non possono essere copiati o imitati, né
                integralmente né in modo parziale. Tutti gli elementi grafici
                personalizzati, le icone ed altri elementi che compaiono sul
                Sito web sono marchi registrati o marchi di servizio ("Marchi")
                di proprietà di Qatar Airways, delle sue consociate o di altre
                entità che hanno concesso a Qatar Airways il diritto e la
                licenza d'uso dei suddetti Marchi e non potranno essere
                utilizzati né interferire in alcun modo con gli stessi
                Contenuti, in assenza dell'esplicito consenso scritto di Qatar
                Airways.
            </p>
            <p>
                Salvo quanto espressamente autorizzato dalle presenti Condizioni
                d'uso, l'utente non può copiare, riprodurre, modificare,
                noleggiare, prestare, vendere, creare opere derivate, caricare,
                trasmettere o distribuire, in alcun modo, la Proprietà
                intellettuale del Microsito senza il previo consenso scritto di
                Qatar Airways o dei rispettivi proprietari. Salvo quanto
                altrimenti disposto nelle presenti Condizioni d'uso, Qatar
                Airways non conferisce all'utente alcun diritto, implicito o
                esplicito, in relazione alla Proprietà intellettuale di Qatar
                Airways o di terze parti.
            </p>
            <p>
                Fatti salvi gli altri termini delle presenti Condizioni d'uso,
                Qatar Airways concede all'utente una licenza limitata,
                personale, non trasferibile, non cedibile in sublicenza e
                revocabile per (a) accedere ed utilizzare esclusivamente il
                Microsito, i Contenuti e i Servizi solo nelle modalità
                presentate da Qatar Airways ed (b) accedere e utilizzare i
                servizi informatici e di rete di Qatar Airways offerti
                all'interno del Microsito (i "Sistemi di Qatar Airways") solo
                nelle modalità espressamente consentite da Qatar Airways.
            </p>
            <p>
                Fatta salva la licenza limitata concessa nelle presenti
                Condizioni d'uso, consentendo all'utente di accedere al
                Microsito, Qatar Airways non conferisce alcun diritto su o in
                relazione ai Sistemi di Qatar Airways, alle informazioni o ai
                dati accessibili tramite i medesimi Sistemi di Qatar Airways (le
                "Informazioni"), i Contenuti, i Servizi, il Microsito o
                qualsiasi altra risorsa proprietà di Qatar Airways .
            </p>
            <p>
                Salvo che nella misura richiesta dalla legge o espressamente
                prevista nelle presenti Condizioni d'uso, nessuno dei Contenuti
                e/o nessuna Informazione potrà essere decodificata, modificata,
                riprodotta, ripubblicata, tradotta in un'altra lingua o in
                linguaggio informatico, ritrasmessa, in qualsiasi forma e con
                qualsiasi mezzo, rivenduta o ridistribuita, in assenza di previo
                consenso scritto di Qatar Airways. Fatte salve le disposizioni
                precedenti, l'utente accetta di non: (a) copiare, esibire,
                modificare, riprodurre o trasferire altrimenti i Materiali a
                terze parti, senza il previo consenso scritto di Qatar Airways;
                (b) interferire o alterare le reti collegate al Sito web; (c)
                utilizzare o cercare di utilizzare qualsivoglia dispositivo,
                software o metodo che interferisca con il corretto funzionamento
                del Sito web o di qualsiasi transazione accessibile tramite lo
                stesso; (d) trasmettere file, dati o altri materiali contenenti
                virus informatici, dati corrotti, virus ovvero altre istruzioni
                o schemi che potrebbero cancellare dati o programmi o che
                renderebbero il Sito web oppure qualsivoglia dispositivo o
                sistema inutilizzabile o tali da non poterne sfruttare appieno
                le caratteristiche; (e) trasmettere qualsiasi comunicazione a o
                tramite il Sito web in violazione alle leggi in vigore a livello
                locale, statale, federale o internazionale; (f) trasmettere a o
                mediante il Sito web qualsiasi comunicazione contenente
                materiale diffamatorio, calunnioso, offensivo od osceno; o (g)
                trasmettere qualsiasi comunicazione a o tramite il Sito web in
                violazione ai diritti di terze parti.
            </p>
            <p>
                Inoltre, per quanto riguarda il Contenuto, la visualizzazione,
                l'utilizzo, la trasmissione, la riproduzione o la condivisione
                del Film modificato e/o l'incorporazione del Film modificato in
                qualsiasi contenuto, non è consentito:
            </p>
            <ol className="alpha">
                <li>
                    fingere di agire per conto nostro, di una qualsiasi delle
                    nostre affiliate, di un membro del Gruppo Qatar Airways o di
                    un agente nostro o dei nostri affiliati, o altrimenti
                    travisare la propria identità o affiliazione;
                </li>
                <li>
                    falsificare intestazioni o manipolare in altro modo
                    identificatori (inclusi gli URL) in modo da trarre in
                    inganno o potenzialmente in inganno terze parti in merito
                    alla propria identità o alla propria affiliazione con noi;
                </li>
                <li>
                    intraprendere attività che possano violare qualsiasi
                    rapporto fiduciario, qualsiasi legge locale, statale,
                    nazionale o internazionale applicabile, o qualsiasi
                    regolamento avente forza di legge;
                </li>
                <li>
                    trasmettere, inviare spam o caricare, pubblicare o
                    altrimenti trasmettere il Film modificato in qualsiasi modo
                    che sia impreciso, dannoso, osceno, diffamatorio, razzista o
                    altrimenti offensivo per gli altri;
                </li>
                <li>
                    compiere od omettere atti che potrebbero danneggiare i
                    nostri interessi o quelli del Gruppo Qatar Airways, come da
                    noi determinato a nostra esclusiva e assoluta discrezione;
                </li>
                <li>
                    rimuovere qualsiasi avviso di proprietà dal Film modificato;
                </li>
                <li>
                    utilizzare il Film Modificato in qualsiasi modo o per
                    qualsiasi scopo che violi, si appropri indebitamente o in
                    altro modo violi qualsiasi Diritto di proprietà
                    intellettuale o altro diritto di qualsiasi persona, o che
                    violi qualsiasi legge applicabile;
                </li>
                <li>
                    accedere a tutto o a qualsiasi parte del Contenuto per
                    creare un prodotto e/o un servizio che compete con il
                    Contenuto o con qualsiasi aspetto dello stesso; o
                </li>
                <li>
                    sfruttare ai fini commerciali, vendere, concedere in licenza
                    o distribuire alcuno dei Contenuti.
                </li>
            </ol>
            <br />
            <p>
                Inoltre, l'utente dovrà attenersi a tutte le altre linee guida
                aggiuntive relative al Film modificato che potremmo pubblicare
                di volta in volta.
            </p>
            <h3>Informazioni sui diritti d'autore e sui marchi registrati</h3>
            <p>
                Tutti i materiali contenuti nel presente Microsito, nonché il
                codice informatico o i dati del software di base, sono protetti
                dal diritto d'autore, dai marchi registrati e/o da altri Diritti
                di proprietà intellettuale. Tali contenuti sono di proprietà di
                QATAR AIRWAYS GROUP Q.C.S.C o sono utilizzati con il permesso
                dei rispettivi proprietari o secondo qualsiasi altro termine
                autorizzato dalla legge. Tra i contenuti protetti rientrano, a
                mero titolo esemplificativo ma non esaustivo, testi, marchi
                registrati, codici sorgente, database, loghi, fotografie,
                immagini, videoclip, audioclip, disegni, veste grafica aziendale
                e altri contenuti del presente Microsito, del sito mobile e
                delle applicazioni mobili. Tutti i diritti sono riservati in
                tutto il mondo.
            </p>
            <p>
                Salvo quanto espressamente consentito dai presenti Termini
                d'uso, non è consentito scaricare, stampare o riprodurre parti
                del Contenuto per uso proprio e godimento personale, non
                commerciale, e qualsiasi altro utilizzo di qualsiasi Contenuto
                (inclusi, senza limitazioni, fotografie, articoli, comunicati
                stampa, software, commenti, tabelle o grafici, videoclip, loghi)
                su questo Microsito, sito mobile e applicazioni mobili è
                severamente vietato. A titolo esemplificativo, tale utilizzo
                proibito può includere l'estrazione, la ridistribuzione, la
                ripubblicazione, la modifica, la trasmissione, la vendita o la
                messa a disposizione in altro modo di qualsiasi informazione o
                materiale su questo Microsito (in qualsiasi formato) a terzi.
                Oltre alla predetta restrizione, l'utente accetta di rispettare
                tutti gli avvisi sui diritti d'autore, le informazioni o le
                limitazioni presenti in qualsiasi contenuto cui si accede
                tramite questo Microsito. L'uso non autorizzato di questo
                Microsito, sito mobile e applicazioni mobili o di qualsiasi
                informazione o materiale contenuto degli stessi è illecito e può
                dare luogo, inter alia, ad una richiesta di risarcimento danni e
                potrebbe anche configurare un reato penale.
            </p>
            <h3>Azioni o usi non autorizzati</h3>
            <p>
                L'utente riconosce che l'uso di questo Microsito è personale e
                che il Microsito viene messo a disposizione dell'utente
                esclusivamente per consentire la consultazione e l'acquisto dei
                nostri prodotti e servizi e per nessun altro motivo. In
                particolare, l'utente si impegna a non effettuare alcuna
                prenotazione non autorizzata, falsa o fraudolenta e di non
                cercare di interferire con il funzionamento di questo Microsito
                ovvero di alterare o corrompere qualsivoglia dato o software.
            </p>
            <p>
                È responsabilità dell'utente garantire la riservatezza delle
                proprie credenziali di accesso al Microsito (ad es. nome utente,
                password e codici di identificazione) e dei dati di prenotazione
                (ad es. numero di prenotazione e itinerario di viaggio).
            </p>
            <p>
                L'utente può decidere di consentire ai propri familiari, amici o
                colleghi di accedere al Microsito per suo conto, mettendo a loro
                disposizione le credenziali di accesso al Microsito e/o i dati
                di prenotazione, purché rispetti le seguenti condizioni:
            </p>
            <ol className="alpha">
                <li>
                    abbia ottenuto il consenso di qualsiasi altra persona i cui
                    dati personali o le cui informazioni di prenotazione siano
                    accessibili utilizzando i dati dell'utente (come nel caso di
                    una prenotazione di gruppo); e
                </li>
                <li>
                    si assuma la piena responsabilità di ogni atto od omissione
                    commesso da colui o da colei che accede al Microsito
                    utilizzando le credenziali di accesso al Microsito e/o le
                    informazioni di prenotazione fornite dall'utente,
                    indipendentemente dal fatto che l'accesso sia stato o meno
                    espressamente autorizzato dall'utente stesso.
                </li>
            </ol>
            <br />
            <p>
                L'utente non dove consentire l'accesso o l'uso del presente
                Microsito o di uno qualsiasi dei contenuti presenti o
                accessibili dallo stesso a un'entità commerciale oppure a
                un'organizzazione che fornisca servizi al pubblico.
            </p>
            <h3>Gli impegni dell'utente</h3>
            <p>
                In aggiunta alle altre restrizioni enunciate nei presenti
                Termini d'uso, l'utente accetta quanto segue:
            </p>
            <ol className="alpha">
                <li>
                    di non occultare l'origine delle informazioni trasmesse
                    tramite questo Microsito;
                </li>
                <li>
                    di non accedere o usufruire di qualsiasi servizio,
                    informazione, applicazione o software accessibile tramite il
                    Microsito secondo modalità che non siano espressamente
                    consentite da Qatar Airways;
                </li>
                <li>
                    di non trasmettere o caricare sul Microsito informazioni che
                    contengano virus, o altre procedure di programmazione volte
                    a danneggiare, interferire, intercettare o espropriare
                    sistemi, il Microsito o informazioni oppure che violino in
                    altro modo i Diritti di proprietà intellettuale di terzi;
                </li>
                <li>
                    riconoscere che alcune sezioni del Microsito sono soggette a
                    restrizioni per i clienti di Qatar Airways;
                </li>
                <li>
                    riconoscere che alcune sezioni del Microsito sono soggette a
                    restrizioni per i clienti di Qatar Airways;
                </li>
                <li>
                    riconoscere che alcune sezioni del Microsito sono soggette a
                    restrizioni per i clienti di Qatar Airways;
                </li>
            </ol>
            <br />
            <p>
                L'utente accetta di seguire e rispettare le presenti Condizioni
                d'uso e tutte le istruzioni e le procedure di cui al presente
                Microsito. L'utente è consapevole che il mancato rispetto di
                tutte le istruzioni e di tutte le procedure può determinare
                l'obbligo per l'utente stesso di acquistare beni o servizi non
                richiesti, la perdita di ogni diritto sui prodotti o i servizi,
                l'impossibilità di accedere al presente Microsito, ai prodotti o
                ai servizi e, in alcune circostanze, l'avvio di un'azione legale
                nei suoi confronti.
            </p>
            <p>
                L'utente accetta di farsi garante e assumersi la responsabilità
                di:
            </p>
            <ol className="alpha">
                <li>
                    valutare e assumersi tutti i rischi associati all'utilizzo
                    del Microsito e del suo Contenuto;
                </li>
                <li>
                    garantire un'adeguata protezione e backup del Suo Contenuto
                    e/o dei sistemi utilizzati in relazione all'accesso e
                    all'utilizzo del Microsito;
                </li>
                <li>
                    <b>
                        l'utilizzo del Microsito e dei Contenuti,
                        indipendentemente dal fatto che tale accesso o utilizzo
                        sia consentito o violi le presenti Termini d'uso,
                        incluso l'utilizzo con applicazioni mobili o software di
                        terze parti.
                    </b>
                </li>
            </ol>
            <br />
            <h3>Esoneri e limitazioni di responsabilità</h3>
            <ol className="alpha">
                <li>
                    Anche se le Informazioni sui prodotti e tutti gli altri
                    Contenuti del presente Microsito, dei Contenuti, del sito
                    mobile e delle applicazioni mobili sono forniti in buona
                    fede, utilizzando il Microsito, i Contenuti il sito mobile e
                    le applicazioni mobili, l'utente riconosce e accetta che
                    Qatar Airways non dichiara, non afferma e non garantisce la
                    correttezza di tali Informazioni o contenuti su questo
                    Microsito, sui suoi Contenuti, sul sito mobile e sulle
                    applicazioni mobili. Non dichiariamo né garantiamo che
                    l'utilizzo del Microsito sarà ininterrotto o privo di
                    errori, o che il Microsito o il suo Contenuto saranno privi
                    di Codice maligno. L'utente accetta, altresì, che le
                    Informazioni sui prodotti e i Contenuti del Microsito, del
                    sito mobile e delle applicazioni mobili non costituiscono in
                    alcun modo un consiglio o una raccomandazione per l'utente.
                </li>
                <li>
                    Qatar Airways declina ogni garanzia tacita relativa all'uso
                    da parte dell'utente del presente Microsito, dei suoi
                    contenuti e e delle applicazioni mobili e delle
                    informazioni, dei prodotti e dei servizi in essi contenuti.
                    Nella misura massima consentita dalla legge, tutte le
                    informazioni incluse nel presente Microsito, nei suoi
                    contenuti e nelle applicazioni mobili sono fornite senza
                    alcuna garanzia (espressa o tacita per legge o in altro
                    modo) o condizione implicita di qualsiasi natura, tra cui, a
                    mero titolo esemplificativo ma non esaustivo, le garanzie
                    tacite o le condizioni implicite di qualità soddisfacente,
                    idoneità per un uso particolare o di non violazione di
                    diritti di terzi.
                </li>
                <li>
                    Utilizzando il presente Microsito, i suoi contenuti e le
                    applicazioni mobili, l'utente accetta che Qatar Airways non
                    risponderà delle perdite dirette, indirette, consequenziali
                    o di altra natura derivanti dall'uso (o non uso) delle
                    informazioni, dei prodotti e dei servizi contenuti nel
                    presente Microsito, nei suoi contenuti e nelle applicazioni
                    mobili o dall'accesso dell'utente ad altri contenuti tramite
                    i link presenti in questo Microsito, nei suoi contenuti e
                    nelle applicazioni mobili. Le esclusioni e le limitazioni
                    contenute nei presenti Termini d'uso si applicano nella
                    misura massima consentita dalla legge.
                </li>
                <li>
                    Qatar Airways non garantisce che l'uso del presente
                    Microsito, dei suoi contenuti, del sito mobile e delle
                    applicazioni mobili siano compatibili con gli hardware e i
                    software utilizzati dai visitatori.
                </li>
                <li>
                    Fatto salvo quanto stabilito alle lettere f) e g), Qatar
                    Airways non si assume nei confronti dell'utente alcuna
                    responsabilità contrattuale, extracontrattuale (inclusa
                    quella per colpa), per violazione di un obbligo di legge,
                    per risarcimento o di altra natura per lesioni personali,
                    decesso, danni a cose, perdite dirette, indirette o
                    consequenziali (tutti e tre i termini comprendono, senza
                    limitazione alcuna, la mera perdita economica, la perdita di
                    profitto, la perdita di opportunità commerciali, la
                    svalutazione dell'avviamento e danni simili) in qualsiasi
                    modo causati e derivanti dall'uso del presente Microsito,
                    dei suoi contenuti, del sito mobile e delle applicazioni
                    mobili o dall'utilizzo, dall'accesso, dallo scaricamento o
                    dall'affidamento su una delle informazioni o degli altri
                    contenuti del presente Microsito, dei suoi contenuti, del
                    sito mobile e delle applicazioni mobili, tra cui, a mero
                    titolo esemplificativo ma non esaustivo, a causa di un virus
                    informatico.
                </li>
                <li>
                    I presenti Termini d'uso non escludono la (eventuale)
                    responsabilità di Qatar Airways nei confronti dell'utente
                    per lesioni personali o decesso derivanti da colpa di Qatar
                    Airways per frode o altre fattispecie per le quali sarebbe
                    illegale per la Compagnia aerea escludere o tentare di
                    escludere la propria responsabilità.
                </li>
                <li>
                    Fatto salvo quanto stabilito alla lettera f), la
                    responsabilità totale di Qatar Airways riguardo alle
                    fattispecie o serie di fattispecie derivanti dalla fornitura
                    da parte sua del presente Microsito, dei suoi contenuti, del
                    sito mobile e delle applicazioni mobili e dei servizi o di
                    qualsiasi cosa resa disponibile in virtù di essi con
                    qualsiasi mezzo - o a ciò relative - sarà limitata a USD
                    1.000 per ogni evento o serie di eventi collegati.
                </li>
                <li>
                    L'utente è tenuto a manlevare Qatar Airways e i suoi
                    licenzianti e a tenerli indenni da tutte le perdite, i
                    danni, le richieste di risarcimento e le spese (ivi comprese
                    le spese legali) a loro arrecati o da loro subiti a causa di
                    una violazione da parte dell'utente dei presenti Termini
                    d'uso.
                </li>
            </ol>
            <br />
            <h3>Privacy e cookie</h3>
            <p>
                Qatar Airways è impegnata a tutelare la privacy degli utenti, a
                rispettare le leggi sulla privacy e sulla protezione dei dati
                personali applicabili, nonché a tutelare i dati personali che
                gli utenti trasmettono. Con "Dati personali" intendiamo
                qualsiasi informazione relativa a una persona fisica
                identificata o identificabile. I dati personali saranno
                elaborati in conformità con la nostra Informativa sulla privacy
                disponibile
                <a
                    target="_blank"
                    rel="noreferrer"
                    href="/privacypolicy?selLang=it"
                >
                    qui
                </a>
                .
            </p>
            <p>
                I cookie sono piccoli file di dati che salvano e recuperano
                informazioni sulle tue visite al Microsito, ad esempio le
                modalità di accesso e navigazione e quali informazioni l'utente
                ha trovato di maggior interesse. Invitiamo l'utente a scoprire
                come utilizziamo i cookie consultando la nostra Politica sui
                cookie disponibile sul nostro Microsito,
                <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.qatarairways.com/it-it/legal/cookie-policy.html"
                >
                    qui
                </a>
                .
            </p>
            <h3>Link</h3>
            <p>
                È severamente vietato inserire un link al presente Microsito
                senza l'esplicito consenso di Qatar Airways, consenso che potrà
                essere revocato in qualsiasi momento. Qualunque Microsito o
                dispositivo che reindirizzi a http://www.qatarairways.com o a
                una pagina qualsiasi del nostro Sito web, non può:
            </p>
            <ol className="alpha">
                <li>replicarne i Contenuti;</li>
                <li>
                    utilizzare un browser o delimitare i Contenuti con dei
                    bordi;
                </li>
                <li>
                    dare a intendere in qualunque modo che Qatar Airways o le
                    sue consociate approvano il suddetto Sito web o i suoi
                    prodotti;
                </li>
                <li>
                    falsificare qualsiasi stato dei fatti, compreso il rapporto
                    con Qatar Airways o le sue consociate;
                </li>
                <li>
                    dare informazioni false riguardo ai prodotti o ai servizi di
                    Qatar Airways;
                </li>
                <li>
                    utilizzare il logo o il marchio di Qatar Airways o delle
                    consociate senza il previo consenso scritto ed esplicito di
                    Qatar Airways.
                </li>
            </ol>
            <br />
            <p>
                Il Microsito può contenere link a micrositi o a risorse di terze
                parti (congiuntamente, "Siti collegati"). Questi Siti collegati
                sono riportati esclusivamente a titolo di riferimento e per
                comodità degli utenti e non implicano alcuna approvazione dei
                rispettivi Contenuti da parte di Qatar Airways. Qatar Airways
                non rilascia alcuna dichiarazione o garanzia in merito alla
                correttezza, all'accuratezza, alle caratteristiche o alla
                qualità di qualsivoglia Contenuto, software, servizio o
                applicazione disponibile sui Siti collegati. Qatar Airways
                declina ogni responsabilità circa la disponibilità dei Siti
                collegati ovvero del loro Contenuto o delle attività che
                descrivono. Se l'utente decide di accedere ai Siti collegati, è
                consapevole di farlo a suo rischio e pericolo. Inoltre, è bene
                ricordare che l'uso dei Siti collegati è soggetto alle politiche
                e ai termini e condizioni d'uso applicabili, tra cui, a titolo
                esemplificativo ma non esaustivo, la politica sui cookie e
                l'informativa sulla privacy dei Siti collegati.
            </p>
            <h3>Modifiche dei termini</h3>
            <p>
                Qatar Airways si riserva la facoltà di aggiornare, rettificare,
                modificare o rimuovere, in qualsiasi momento, i presenti Termini
                d'uso e/o altre informazioni, servizi, prodotti e Contenuti
                disponibili sul presente Microsito, senza alcun obbligo di
                preavviso. Utilizzando il presente Microsito, si riterrà che
                l'utente abbia accettato di essere obbligato a rispettare la
                versione più recente degli stessi Termini d'uso.
            </p>
            <h3>Varie</h3>
            <p>
                Fatto salvo quanto espressamente disposto in un accordo a parte
                stipulato tra l'Utente e Qatar Airways, i presenti Termini d'uso
                prevalgono su tutti i precedenti contratti, accordi,
                dichiarazioni (ad esclusione delle dichiarazioni fraudolente),
                termini tacitamente previsti dalle disposizioni di legge e
                impegni assunti tra le parti circa l'oggetto dei Termini d'uso
                enunciati in questa sede, siano essi scritti, derivanti da
                consuetudini, verbali o in altra forma. I presenti Termini d'uso
                sono redatti in inglese. Le parti riconoscono che i presenti
                Termini d'uso e tutti i documenti ad essi correlati, oggi
                vigenti o che lo saranno in futuro, dovranno essere redatti
                esclusivamente in lingua inglese e italiana.
            </p>
            <h3>Nullità parziale ed esclusione di rinuncia</h3>
            <p>
                Se una qualsiasi disposizione dei presenti Termini d'uso dovesse
                essere ritenuta illecita, nulla o inapplicabile da una qualsiasi
                corte, un qualsiasi tribunale, un qualsiasi organo
                amministrativo o altra autorità di un foro competente, tale
                disposizione sarà espunta, nella misura richiesta, dai presenti
                Termini d'uso e sarà inefficace senza modificare, per quanto
                possibile, le altre disposizioni degli stessi e ciò non inciderà
                sulle altre disposizioni dei Termini d'uso modificati, che
                rimarranno pienamente valide ed efficaci.
            </p>
            <p>
                Nessuna rinuncia a qualsiasi termine, disposizione o condizione
                dei presenti Termini o Condizioni potrà essere considerata
                valida, a meno che non venga confermata per iscritto e
                sottoscritta dalla parte che riconosce tale rinuncia.
            </p>
            <h3>Foro competente e giurisdizione applicabile</h3>
            <p>
                I presenti Termini d'uso di questo Microsito saranno
                disciplinati e interpretati in conformità alle leggi dello Stato
                del Qatar. I tribunali del Qatar avranno la competenza non
                esclusiva in relazione alla risoluzione di qualsiasi
                controversia che dovesse insorgere. L'utente riconosce, nella
                misura massima consentita dalla legge, che Qatar Airways si
                riserva il diritto di intentare un'eventuale azione legale nei
                suoi confronti nel Paese che reputi opportuno.
            </p>
            <h3>Risoluzione</h3>
            <p>
                L'utente riconosce che Qatar Airways avrà facoltà, a sua
                esclusiva discrezione, di cessare o sospendere l'utilizzo del
                presente Microsito da parte dell'utente stesso, nonché dei
                Sistemi, delle Informazioni, dei Servizi e dei Contenuti di
                Qatar Airways in qualsiasi momento, per un determinato motivo o
                anche senza alcun motivo, anche se tale accesso e uso
                continueranno a essere riconosciuti ad altri utenti. In seguito
                alla suddetta sospensione o cessazione, l'utente deve, senza
                indugio alcuno: (a) smettere di utilizzare il Microsito; e (b)
                distruggere ogni copia che abbia creato di una parte qualunque
                dei Contenuti. L'eventuale accesso al Microsito e ai Sistemi,
                alle Informazioni e ai Servizi di Qatar Airways in seguito alla
                data della suddetta cessazione, sospensione o interruzione
                costituisce un atto di intrusione. Inoltre, l'utente riconosce
                che Qatar Airways non sarà considerata responsabile nei
                confronti dell'utente stesso o di terze parti per qualsivoglia
                sospensione o cessazione dell'accesso al Microsito, nonché ai
                Sistemi, alle Informazioni e/o ai Servizi di Qatar Airways da
                parte dell'utente.
            </p>
            <h3>Contattaci</h3>
            <p>
                In caso di domande sul nostro Microsito o sui nostri Termini
                d'uso, si prega di contattare:
            </p>
            <p>
                Qatar Airways Group Q.C.S.C
                <br />
                Qatar Airways Tower
                <br />
                P.O. Box: 22550
                <br />
                Doha, Stato del Qatar
                <br />
                Email:{' '}
                <a
                    target="_blank"
                    rel="noreferrer"
                    href="mailto:qatarairways.com/contactus"
                >
                    qatarairways.com/contactus
                </a>
            </p>
            <br />
            <table>
                <tbody>
                    <tr>
                        <td colSpan={2}>Definizioni</td>
                    </tr>
                    <tr>
                        <td>Affiliati</td>
                        <td>
                            indica qualsiasi soggetto che direttamente o
                            indirettamente controlla, è controllato da, o è
                            sottoposto a comune controllo, una parte;
                        </td>
                    </tr>
                    <tr>
                        <td>Codice non valido</td>
                        <td>
                            indica: (a) qualsiasi virus, trojan horse, worm,
                            spyware, backdoor, trapdoor, fork bomb, logic bomb,
                            malware o drop dead service e (b) qualsiasi altra
                            routine software intesa o progettata per essere
                            dannosa, distruttiva o dirompente, per causare
                            malfunzionamenti, errori, difetti, negazione o
                            degradazione del servizio o perdita, accesso non
                            autorizzato, modifica, divulgazione o corruzione dei
                            dati.
                        </td>
                    </tr>
                    <tr>
                        <td>Diritti di proprietà intellettuale</td>
                        <td>
                            indica tutti i diritti di proprietà industriale e
                            intellettuale di qualsiasi tipo, inclusi, a titolo
                            esemplificativo ma non esaustivo, i diritti d'autore
                            (inclusi i diritti sui software per computer), i
                            marchi commerciali, i marchi di servizio, i design,
                            i brevetti, i segreti commerciali, i diritti sui
                            semiconduttori o sui layout dei circuiti, i nomi
                            commerciali, aziendali, di dominio o aziendali, i
                            diritti sulle informazioni riservate, il know-how o
                            altri diritti proprietari (indipendentemente dal
                            fatto che uno qualsiasi di questi sia registrato o
                            meno e inclusa qualsiasi domanda di registrazione) e
                            tutti i diritti o forme di protezione di natura
                            simile o aventi effetto equivalente o simile a uno
                            qualsiasi di questi che possono sussistere in
                            qualsiasi parte del mondo.
                        </td>
                    </tr>
                    <tr>
                        <td>Qatar Airways Group</td>
                        <td>
                            indica Qatar Airways Group Q.C.S.C. e ciascuna delle
                            sue sussidiarie, affiliate, entità gestite,
                            cessionari autorizzati e successori.
                        </td>
                    </tr>
                </tbody>
            </table>
            <br />
        </div>
    );
}
