export default function ptText() {
  return (
    <div>
      <p>Verifique estes Termos de Uso cuidadosamente antes de acessar e utilizar este aplicativo da web, quaisquer de seus serviços, funções, materiais ou conteúdos (“<b>Microsite</b>”). Este Microsite é controlado e operado pelo Qatar Airways Group Q.C.S.C, uma empresa qatari com participação acionária com sede na Qatar Airways Tower 1, Airport Road, Estado do Qatar.</p>
      <p>AO ACESSAR, USAR, VISUALIZAR, TRANSMITIR, ARMAZENAR EM CACHE OU ARMAZENAR ESTE MICROSITE (ai.flyqatar.com), VOCÊ INDICA SUA CONCORDÂNCIA EM OBEDECER A ESTES TERMOS E CONDIÇÕES (OS "TERMOS DE USO") EM CONSIDERAÇÃO AO ACESSO FORNECIDO AO MICROSITE CONFORME ESTABELECIDO ABAIXO, E VOCÊ GARANTE QUE É MAIOR DE IDADE E POSSUI CAPACIDADE LEGAL PARA CELEBRAR ESTE CONTRATO E USAR ESTE MICROSITE DE ACORDO COM TODOS OS TERMOS DE USO AQUI CONTIDOS. VOCÊ CONCORDA EM SER FINANCEIRAMENTE RESPONSÁVEL POR TODO O SEU USO DESTE SITE (BEM COMO PELO USO DE SUA CONTA POR TERCEIROS).</p>
      <p>“Você” e “Seu/Sua” referem-se as pessoas acessando este Microsite (inclusive pessoas que acessem este site em nome de outras pessoas). “Nos”, “Nosso” e termos similares referem-se à Qatar Airways. Caso você não concorde com estes Termos de Uso, quaisquer das documentações relacionadas abaixo ou outras restrições aqui notificadas a Você durante o curso da Sua utilização deste Microsite, Você não tem permissão de, e concorda em não, usar e acessar este Microsite.</p>
      <p>Estes Termos de Uso regem Seu uso deste Microsite, e todos os aplicativos, software e serviços (coletivamente denominados "Serviços") disponíveis no Microsite, exceto na medida em que tais Serviços estejam sujeitos a um acordo em separado. Condições específicas podem ser aplicadas ao uso de determinados Serviços e de outros itens disponibilizados a Você neste Microsite ("Acordo(s) de Serviço(s)"). Os termos em letra maiúscula que não estejam definidos de outra forma nestes Termos de Uso terão os significados que lhes são dados na seção Definições anexada ao final destes Termos de Uso.</p>
      <h3>Termos de uso</h3>
      <h3>Seu uso do Microsite</h3>
      <p>Sempre sujeito ao Seu cumprimento com estes Termos de Uso, Você pode escolher enviar sua imagem e semelhança através de fotografias (“<b>Seu Conteúdo</b>”) ao Microsite para inclusão em um filme de video disponível no Microsite (“<b>Filme</b>”) (a “<b>Finalidade</b>”). O Filme incorporando Seu Conteúdo deve ser referido nestes Termos de Uso como Filme Modificado.</p>
      <p>Ao enviar Seu Conteúdo ao Microsite para inclusão no Filme, Você:</p>
      <ol className="alpha">
        <li>concede-nos um direito mundial, não exclusivo, livre de royalties, transferível, perpétuo, irrevogável e sublicenciável para usar, reproduzir, modificar e criar trabalhos derivados do Seu Conteúdo para a Finalidade;</li>
        <br/>
        <li>
          representa, garante e se compromete conosco que:
          <ol className="roman">
            <li>Você pode nos conceder os direitos sobre o Seu Conteúdo para a Finalidade;</li>
            <li>Você tem e continuará tendo todos os direitos, títulos e interesses em e ao Seu Conteúdo necessário para nosso uso do Seu Conteúdo para a Finalidade;</li>
            <li>nosso uso do Seu Conteúdo de acordo com e na forma pretendida ou permitida por estes Termos de Uso não violará o Direito de Propriedade Intelectual de qualquer terceiro ou violação de qualquer lei aplicável; e</li>
            <li>nem a Qatar Airways nem qualquer outra pessoa atuando na direção ou com permissão da Qatar Airways (incluindo membros do Qatar Airways Group) serão responsáveis pelo pagamento a qualquer terceiro por qualquer licença ou outras taxas com relação ao uso do Seu Conteúdo de acordo com e na forma pretendida ou permitida por estes Termos de Uso.</li>
          </ol>
        </li>
      </ol>
      <br/>
      <p>Você deve nos notificar o mais breve possível assim que tomar conhecimento de qualquer infração ou suspeita ou alegação de violação por Seu Conteúdo dos Direitos de Propriedade Intelectual de qualquer terceiro e cooperará integralmente conosco em qualquer ação legal adotada por nós para impor nossos Direitos de Propriedade Intelectual.</p>
      <p>Sempre sujeito ao Seu cumprimento destes Termos de Uso, garantimos a Você um direito mundial, limitado, pessoal, revogável, sem royalties, intransferível, não sublicenciado para utilizar, baixar, reproduzir, carregar, transmitir, traduzir e modificar o Filme Modificado para Suas finalidades pessoais apenas (e não para qualquer propósito comercial de forma alguma).</p>
      <p>Quaisquer direitos nao expressamente garantidos a Você nestes Termos de Uso são reservados a nós.</p>
      <h3>Licença e propriedade - Qatar Airways</h3>
      <p>Todo e quaisquer Direitos de Propriedade Intelectual associados a este Microsite e seus conteúdos, inclusive o Filme e o Filme Modificado (o "<b>Conteúdo</b>") são de inteira propriedade do Qatar Airways Group Q.C.S.C ou de terceiros. Nosso Conteúdo do Microsite é protegido pelas leis aplicáveis de direito autoral e outras leis.</p>
      <p>Os elementos do Microsite são também protegidos por segredo comercial, concorrência desleal e outras leis, e não podem ser copiados or imitados em seu todo ou em partes. Todos os gráficos customizados, ícones e outros itens que apareçam em nosso Microsite são marcas registradas, ou marcas de serviços ("<b>Marcas</b>") da Qatar Airways, de suas afiliadas ou de outras entidades que tenham garantido à Qatar Airways o direito e a licença para utilização de tais Marcas e não podem ser utilizadas ou modificadas de maneira alguma sem o expresso consentimento por escrito da Qatar Airways.</p>
      <p>A não ser que expressamente autorizado por estes Termos de Uso, Você não pode copiar, reproduzir, modificar, alugar, emprestar, vender, criar formas derivadas, carregar, transmitir ou distribuir a Propriedade Intelectual deste Microsite de forma alguma sem prévia autorização apropriada por escrito da Qatar Airways ou de terceiros. Exceto se expressamente consentido no presente, a Qatar Airways não garante a Você qualquer direito expresso ou implícito de Propriedade Intelectual da Qatar Airways ou de terceiros.</p>
      <p>Sem prejuízo a outros termos destes Termos de Uso, a Qatar Airways garante a Você uma licença limitada, pessoal, intransferível, não sublicenciada, revogável para (a) acesso e uso apenas do Microsite, Conteúdos e Serviços somente na forma apresentada pela Qatar Airways, e (b) acesso e uso do serviços de computação e rede da Qatar Airways oferecidos dentro do Microsite (os "Sistemas da Qatar Airways") somente na forma expressamente permitida pela Qatar Airways.</p>
      <p>Exceto pela licença limitada concedida nestes Termos de Uso, a Qatar Airways não transmite qualquer interesse no ou para os Sistemas da Qatar Airways, das informações ou dados disponíveis através dos Sistemas da Qatar Airways (a "Informação"), Conteúdo, Serviços, Microsite ou qualquer outra propriedade da Qatar Airways ao permitir a Você o acesso ao Microsite.</p>
      <p>Exceto na medida exigida por lei ou expressamente contido aqui, nenhum Conteúdo e/ou Informação pode ser aqui revertida mecanicamente, modificada, reproduzida, republicada, traduzida para qualquer idioma ou para linguagens de computação, retransmitida de qualquer forma ou por quaisquer meios, revendida ou redistribuída sem prévio consentimento por escrito da Qatar Airways. Sem prejuízo ao que segue, Você concorda em não: (a) copiar, disponibilizar, modificar, reproduzir ou mesmo transferir quaisquer Materiais para terceiros sem prévia permissão por escrito da Qatar Airways; (b) interferir com ou interromper redes conectadas a este Site; (c) usar ou tentar usar qualquer dispositivo, software ou rotina que interfira com o funcionamento normal do Site ou de qualquer transação sendo oferecida pelo Site; (d) transmitir arquivos, dados ou outros materiais contendo vírus de computador, dados corrompidos, programas maliciosos, “cavalo de troia” ou outras instruções ou arquiteturas que possam apagar dados ou programação ou fazer com que o Site ou qualquer equipamento ou sistema se torne inoperante ou incapaz de ser usado da maneira completa para a qual foi projetado; (e) fornecer qualquer comunicação ao ou através do Site que viole leis locais, estaduais, federais ou internacionais; (f) fornecer qualquer comunicação ao ou através do Site que contenha material difamatório, libertinoso, abusivo ou obsceno; ou (g) fornecer qualquer comunicação ao ou através do Site que possa infringir o direito de terceiros.</p>
      <p>Além disso, com relação ao Conteúdo, Sua visualização, uso, transmissão, reprodução ou compartilhamento do Filme Modificado e/ou Sua incorporação do Filme Modificado em qualquer conteúdo, Você não deverá:</p>
      <ol className="alpha">
        <li>passar-se por nós, por quaisquer das nossas Afiliadas, qualquer membro do Qatar Airways Group ou um agente nosso ou de nossas Afiliadas, de outra forma deturpar Sua identidade ou afiliação;</li>
        <li>falsificar cabeçalhos ou manipular identificadores (incluindo URLs) de forma a enganar ou potencialmente enganar terceiros quanto à Sua identidade ou afiliação conosco;</li>
        <li>envolver-se em atividades que violariam qualquer relacionamento fiduciário, qualquer lei local, estadual, nacional ou internacional aplicável ou qualquer regulamento com força de lei;</li>
        <li>transmitir, enviar spam ou carregar, publicar ou então transmitir o Filme Modificado de qualquer forma que seja incorreto, prejudicial, obsceno, difamatório, racista ou então ofensivo a outros;</li>
        <li>fazer qualquer coisa ou omitir-se de fazer qualquer coisa que seja prejudicial aos nossos interesses ou do Qatar Airways Group, conforme possa ser determinado por nós de acordo com nosso inteiro e absoluto critério;</li>
        <li>remover qualquer nota de propriedade do Filme Modificado;</li>
        <li>usar o Filme Modificado de qualquer maneira ou com qualquer objetivo que infrinja, desvie ou então infrinja qualquer direito de Propriedade Intelectual ou outro direito de qualquer pessoa, ou que viole qualquer lei aplicável;</li>
        <li>acessar todo ou qualquer parte do Conteúdo para criar um produto e/ou serviço que venha a competir com o Conteúdo ou qualquer aspecto deste; ou</li>
        <li>explorar comercialmente, vender, licenciar ou distribuir algo do Conteúdo.</li>
      </ol>
      <br/>
      <p>Além disso, Você deve cumprir com mais outras normas com relação ao Filme Modificado conforme podemos acrescentar de tempos em tempos.</p>
      <h3>Informações de direito autoral e marca registrada</h3>
      <p>Todos os materiais neste Microsite, códigos de programas subscritos, ou software de dados estão protegidos por direitos autorais, marca registrada e/ou outros Direitos de Propriedade Intelectual. Estes materiais são de propriedade da QATAR AIRWAYS GROUP Q.C.S.C ou são utilizados com permissão de seus proprietários ou então devidamente autorizados pela lei. Os materiais protegidos incluem, entre outros, textos, marcas registradas, códigos-fonte, bases de dados, logotipos, fotografias, imagens, videoclipe, clipes de áudio, designs, trade dress e outros materiais dispostos neste Microsite, site móvel ou aplicativos móveis. Todos os direitos são reservados, mundialmente.</p>
      <p>Salvo conforme expressamente permitido sob estes Termos de Uso, Você não pode baixar, imprimir ou reproduzir partes do Conteúdo para Seu uso pessoal, não comercial e divertimento ou qualquer outra utilização de qualquer Conteúdo (incluindo, mas não limitado a fotografias, artigos, comunicados de imprensa, software, comentários, tabelas ou gráficos, clipes de vídeo, logotipos) deste Microsite, site móvel ou aplicativos móveis é estritamente proibida. A título de exemplo, tal proibição de uso pode incluir a extração, redistribuição, nova publicação, modificação, veiculação, venda, ou mesmo, tornar disponível qualquer informação ou conteúdo deste Microsite (em qualquer formato) a terceiros. Além dessa restrição, Você concorda em cumprir com todos as notificações de diretos autorais, informações ou restrições contidas em quaisquer dos materiais acessados através deste Microsite. O uso não autorizado deste Microsite, site móvel e aplicativos móveis ou de qualquer informação ou material contido neste Microsite é ilegal e, assim sendo, pode originar, dentre outras coisas, alegação por danos e pode ser também considerado ofensa criminal.</p>
      <h3>Uso e ações não autorizadas</h3>
      <p>Você concorda que seu uso deste Microsite é pessoal para você e que este Microsite é disponibilizado para você inteiramente para permitir que você veja e compre nossos produtos e serviços e para nenhum outro propósito. Em particular, você concorda que não fará qualquer reserva não autorizada, falsa ou fraudulenta; que você não tentará interferir com a operação deste Microsite, ou alterar ou corromper qualquer dado ou software.</p>
      <p>Você é responsável pela manutenção da confidencialidade das Suas informações de acesso a este Microsite (como nomes de usuário, senhas, números de identificação pessoal) e dados da reserva (como códigos da reserva e Seu itinerário de viagem).</p>
      <p>Você pode permitir que familiares, amigos ou colegas acessem o Microsite em Seu nome ao fornecer informações de acesso a este Microsite e/ou dados da reserva para tais pessoas, desde que:</p>
      <ol className="alpha">
        <li>Você tenha obitido permissão de acesso de quaisquer outras pessoas de quem os dados da reserva ou dados pessoais sejam acessíveis com Seus dados (como o caso de uma reserva de grupo); e</li>
        <li>Você aceita total responsabilidade pelos atos e omissões daquela pessoa ao acessar este Microsite usando informações de acesso ao Microsite e/ou dados da reserva fornecidos por você independente do acesso ter sido autorizado ou não por Você.</li>
      </ol>
      <br/>
      <p>Você não pode permitir o acesso ou uso deste Microsite ou de qualquer Material obtido deste Microsite a qualquer entidade comercial ou outra organização que ofereça serviços ao público.</p>
      <h3>Seus compromissos</h3>
      <p>Além de outras restrições apresentadas nestes Termos de Uso, Você concorda que:</p>
      <ol className="alpha">
        <li>Você não pode disfarçar a origem das informações transmitidas através do Microsite.</li>
        <li>Você não usará ou acessará qualquer serviço, informação, aplicativo ou software disponível através deste Microsite de qualquer outra forma que não aqui expressamente permitida pela Qatar Airways.</li>
        <li>Você não inserirá ou carregará no Microsite qualquer informação que contenha vírus, cavalos de troia, vírus maliciosos, bombas-relógio ou qualquer outra rotina de programação de computador com intenção de danificar, interferir com, interceptar ou expropriar qualquer sistema, o Microsite ou Informações que infrinjam os Direitos de Propriedade Intelectual de outros.</li>
        <li>Certas áreas do Microsite são restritas aos clientes da Qatar Airways.</li>
        <li>Você não pode usar ou acessar o Microsite ou os Sistemas da Qatar Airways ou Serviços de nenhuma maneira que, a julgar pela Qatar Airways, afete adversamente o desempenho ou função dos Sistemas da Qatar Airways, Serviços ou o Microsite ou interfira com a habilidade das partes autorizadas de acessar os Sistemas da Qatar Airways, Serviços ou o Microsite.</li>
        <li>Você não pode retratar ou utilizar técnicas de congelamento de imagem para destacar qualquer parte ou aspecto do Conteúdo ou de Informações, sem um consentimento expresso por escrito da Qatar Airways.</li>
      </ol>
      <br/>
      <p>Você concorda em seguir e cumprir estes Termos de Uso e todas as instruções e procedimentos estabelecidos neste Microsite. Você entende que o não cumprimento de todas as instruções e procedimentos pode levar à sua obrigação de comprar bens ou serviços não intencionais; perda de seus produtos ou serviços; negação de acesso aos produtos ou serviços deste Microsite; e possivelmente ação legal sendo movida contra você.</p>
      <p>Você reconhece e concorda que deve permanecer responsável e encarregado de:</p>
      <ol className="alpha">
        <li>avaliar e arcar com riscos associados ao seu uso do Microsite e seu Conteúdo;</li>
        <li>garantir proteção e backup adequados do Seu Conteúdo e/ou sistemas usados ​​por você em conexão com o acesso e uso do Microsite;</li>
        <li><b>seu uso do Microsite e Conteúdo, mesmo que tal acesso ou uso seja permitido por ou quebra destes Termos de Uso, incluindo de quaisquer aplicativos móveis ou software de terceiros.</b></li>
      </ol>
      <br/>
      <h3>Isenções e limitações de responsabilidade</h3>
      <ol className="alpha">
        <li>Na medida em que a Informação do Produto e todos os outros materiais no Microsite, Conteúdo, site móvel ou aplicativos móveis são fornecidos de boa fé, ao utilizar este Microsite, Conteúdo, site móvel ou aplicativos móveis, Você concorda e reconhece que a Qatar Airways não faz representações, garantias ou reivindicações com relação à exatidão da Informação do Produto ou de qualquer outro material neste Microsite, seu Conteúdo, site móvel ou aplicativos móveis. Nós não representamos ou garantimos que a sua utilização deste Microsite seja sem interrupções ou sem erros, ou que o Microsite e seu Conteúdo seja livre de Códigos Nocivos. Além disso, você concorda que a Informação do Produto e os materiais do Microsite, seu Conteúdo, site móvel e aplicativos móveis não constitui qualquer forma de sugestão ou recomendação para Você.</li>
        <li>A Qatar Airways isenta-se de todas as garantias implícitas relacionadas à Sua utilização deste Microsite, seu Conteúdo, site móvel ou aplicativos móveis e das informações, produtos e serviços contidos neste Microsite, seu Conteúdo, site móvel ou aplicativos móveis. Até o limite máximo permitido pela lei, toda informação neste Microsite, seu Conteúdo, site móvel ou aplicativos móveis é fornecida sem qualquer garantia (tanto expressa quanto implícita na lei ou não) ou termos implícitos de qualquer natureza, incluindo, mas não limitado a quaisquer garantias implícitas ou termos implícitos de qualidade satisfatória, adequação a um propósito específico ou não violação de direito de terceiros.</li>
        <li>Ao utilizar este Microsite, seu Conteúdo, site móvel ou aplicativos móveis, Você concorda que a Qatar Airways não se responsabilizará por você por qualquer perda direta, indireta, consequente ou de qualquer outra perda decorrente de uso (ou não uso) das informações, produtos e serviços contidos neste Microsite, seu Conteúdo, site móvel ou aplicativos móveis e de seu acesso a outros materiais através de links deste Microsite, seu Conteúdo, site móvel ou aplicativos móveis. As exclusões e limitações contidas nestes Termos e Condições se aplicam na medida do possível ao permitido pela lei.</li>
        <li>A Qatar Airways não garante que o uso deste Microsite, seu Conteúdo, site móvel ou aplicativos móveis será compatível com todos os equipamentos e programas que possam estar sendo utilizados pelos visitantes deste site.</li>
        <li>Exceto conforme definido nos subparágrafos f e g, a Qatar Airways não será responsabilizada por você em absolutamente quaisquer circunstâncias, seja contratual, por ato ilícito (incluindo negligência), quebra de acordo estatutário, restituição, ou então, por qualquer acidente, morte, dano ou perda direta, indireta ou decorrente (nos três termos, incluídos aqui, entre outros, perda econômica pura, perda de lucros, perda de negócios, danos à reputação e perdas afins) independente de causa decorrente de ou em conexão ao uso deste Microsite, seu Conteúdo, site móvel ou aplicativos móveis ou por usar, acessar, baixar ou confiar em qualquer informação ou outros conteúdos contidos neste Microsite, seu Conteúdo, site móvel ou aplicativos móveis, incluindo, entre outros, como qualquer resultado de vírus no computador.</li>
        <li>Estes Termos de Uso não excluem a responsabilidade da Qatar Airways (caso haja) com você por danos pessoais ou morte resultante de negligência da Qatar Airways, por fraude ou quaisquer outras circunstâncias que possam ser consideradas ilegais para a Qatar Airways se eximir ou tentar se eximir de sua responsabilidade.</li>
        <li>Sujeito ao subparágrafo f, a responsabilidade total da Qatar Airways em relação a quaisquer assuntos ou série de assuntos decorrentes de ou em conexão ao aqui disposto pela Qatar Airways neste Microsite, seu Conteúdo, site móvel ou aplicativos móveis e com quaisquer serviços da Qatar Airways ou qualquer outro assunto nesse âmbito por qualquer meio deve ser limitado a um total de USD 1.000 por cada ocorrência ou série de ocorrências relacionadas.</li>
        <li>Você deverá indenizar a Qatar Airways e seus licenciadores e manter a Qatar Airways e seus licenciadores indenizados contra todas as perdas, danos, alegações e custos (inclusive custos legais) causados ou sofridos pela Qatar Airways e/ou seus licenciadores como resultado de quebra de acordo seu de quaisquer destes Termos de Uso.</li>
      </ol>
      <br/>
      <h3>Privacidade e Cookies</h3>
      <p>A Qatar Airways está comprometida em proteger sua privacidade e cumprir com as leis de proteção de dados e privacidade aplicáveis ​​e a proteção de seus Dados Pessoais. Por "Dados Pessoais" queremos dizer qualquer informação relacionada a uma pessoa física identificada ou identificável. Os Dados Pessoais serão processados ​​de acordo com nosso Aviso de Privacidade disponível <a target="_blank" rel="noreferrer" href="/privacypolicy?selLang=pt-br">aqui</a>.</p>
      <p>Os cookies são pequenos arquivos de informação que salvam e retêm informações sobre a sua visita ao nosso Microsite, por exemplo, como você acessou o site, como você navegou pelo site e quais informações foram de interesse para você. Saiba mais sobre como nós utilizamos os cookies em nossa Política de Cookies, disponível em nosso Microsite <a target="_blank" rel="noreferrer" href="https://www.qatarairways.com/pt-br/legal/cookie-policy.html">aqui</a>.</p>
      <h3>Links</h3>
      <p>Criar vínculos ao Microsite é estritamente proibido sem o nosso consentimento por escrito, que pode ser suspenso a qualquer momento. Qualquer Microsite ou equipamento que esteja vinculado ao http://www.qatarairways.com ou qualquer página disponível está proibido de:</p>
      <ol className="alpha">
        <li>Replicar o Conteúdo,</li>
        <li>Utilizar a ferramenta de busca ou áreas limítrofes em volta do Conteudo,</li>
        <li>Insinuar de qualquer maneira que a Qatar Airways ou qualquer de suas afiliadas estejam endossando essa prática ou seus produtos,</li>
        <li>Deturpar o estado dos fatos, inclusive seu relacionamento com a Qatar Airways ou suas afiliadas,</li>
        <li>Apresentar informações falsas sobre os produtos ou serviços da Qatar Airways, e</li>
        <li>Usar qualquer logotipo ou marca da Qatar Airways ou de suas afiliadas sem expressa permissão escrita da Qatar Airways.</li>
      </ol>
      <br/>
      <p>O Microsite pode conter links para Microsites e recursos de terceiros (coletivamente , "Sites Vinculados"). Esses Sites Vinculados estão disponibilizados estritamente por conveniência para Você e não como endosso da Qatar Airways ao Conteúdo de tais Sites Vinculados. A Qatar Airways não faz qualquer representação ou garantia com relação à veracidade, exatidão, desempenho ou qualidade de qualquer Conteúdo, software, serviço ou aplicação encontrada em qualquer Site Vinculado. A Qatar Airways não se responsabiliza pelos Sites Vinculados ou seus Conteúdos e atividades. Caso Você decida acessar Sites Vinculados, Você o fará por Seu próprio risco. Além disso, o seu uso desses Sites Vinculados está sujeito às políticas aplicáveis e termos e condições de uso, inclusive, mas não limitado à política de cookies e de privacidade dos Sites Vinculados.</p>
      <h3>Modificações dos termos</h3>
      <p>Estes Termos de Uso e/ou outras informações de serviços, produtos e Conteúdos fornecidos neste Microsite podem ser atualizados, aditados, modificados ou removidos pela Qatar Airways a qualquer momento e sem aviso prévio. Ao utilizar este Microsite, você se torna responsável por estar legalmente de acordo com a última versão deste.</p>
      <h3>Disposições gerais</h3>
      <p>A não ser que expressamente providenciado em um acordo separado entre você e a Qatar Airways, estes Termos de Uso revogam todos os contratos prévios, acordos, representações (que não declarações falsas), termos implícitos na lei e entendimento entre as partes, seja por escrito, resultante de costumes, verbalmente ou de outra forma em relação ao assunto aqui tratado. Estes Temos de Uso estão escritos no idioma inglês. As partes declaram ter solicitado que estes Termos de Uso e todos os documentos relacionados aqui, tanto atualmente quanto no futuro, sejam expressos somente no idioma inglês.</p>
      <h3>Divisibilidade e isenções</h3>
      <p>No caso de alguma parte destes Termos de Uso ser considerada ilegal, inválida ou inaplicável por alguma corte, tribunal, órgão administrativo ou autoridade da jurisdição competente, então essa cláusula específica será, na medida do exigido, suprimida destes Termos de Uso e será considerada sem efeito, não alterando, na medida do possível, quaisquer outras partes destes Termos de Uso e não terá efeito sobre qualquer outra cláusula dos Termos de Uso aditados, que permanecerá em pleno vigor e efeito.</p>
      <p>Não deve haver renúncia de qualquer termo, cláusula ou condição destes Termos e Condições, a não ser que tal renúncia seja manifestada por escrito e assinada pela parte envolvida garantindo tal renúncia.</p>
      <h3>Leis governantes e jurisdição</h3>
      <p>Os Termos de Uso deste Microsite serão regidos e interpretados de acordo com as leis do Estado do Catar. Os tribunais do Catar terão jurisdição não exclusiva para julgar quaisquer disputas que possam surgir como resultado. Na extensão máxima permitida por lei, você concorda que a Qatar Airways reserva-se o direito de mover uma ação contra você em qualquer país que considere apropriado.</p>
      <h3>Rescisão</h3>
      <p>Você concorda que a Qatar Airways, a seu critério exclusivo, pode encerrar ou suspender o Seu uso deste Microsite e dos Sistemas da Qatar Airways, Informações, Serviços e Conteúdos a qualquer momento e por qualquer ou nenhum motivo a seu exclusivo critério, mesmo que acesso e uso continue a ser permitido a outros. Devido a tal suspensão ou encerramento, você deve imediatamente (a) descontinuar seu uso do Microsite, e (b) destruir quaisquer copias que Você tenha feito de qualquer parte do Conteúdo. Acessar o Microsite e os Sistemas da Qatar Airways, Informações ou Serviços após o encerramento, suspensão ou descontinuação poderá constituir ato de transgressão. Além disso, você concorda que a Qatar Airways não será responsável por Você ou por terceiros pelo encerramento ou suspensão de Seu acesso ao Microsite e aos Sistemas da Qatar Airways, Informações e/ou Serviços.</p>
      <h3>Entre em contato conosco</h3>
      <p>Caso tenha qualquer dúvida sobre nosso Microsite ou nossos Termos de Uso, entre em contato conosco:</p>
      <p>Qatar Airways Group Q.C.S.C<br/>
        Qatar Airways Tower<br/>
        P.O. Box: 22550<br/>
        Doha, State of Qatar<br/>
        Email: <a target="_blank" rel="noreferrer" href="mailto:qatarairways.com/contactus">qatarairways.com/contactus</a>
      </p>
      <br/>
      <table>
        <tbody>
        <tr>
          <td colSpan={2}>Definições</td>
        </tr>
        <tr>
          <td>Afiliada(s)</td>
          <td>refere-se a qualquer entidade ou entidades que diretamente ou indiretamente controle, seja controlada por, ou esteja sob um controle comum com, uma parte;</td>
        </tr>
        <tr>
          <td>Código Maligno</td>
          <td>significa: (a) qualquer vírus, cavalo de troia, worm, spyware, backdoor, trapdoor, fork bomb, logic bomb, malware ou serviços de drop dead; e (b) ou qualquer outra rotina de software com intenção de ou designada a ser nociva, destrutiva ou perturbadora, para causar erros, mal funcionamento, defeitos, negações ou degradação do serviço ou perdas, acesso não autorizado, modificações, divulgação ou corrupção de dados.</td>
        </tr>
        <tr>
          <td>Direitos de Propriedade Intelectual</td>
          <td>refere-se todos os direitos de propriedade intelectual e industrial de qualquer tipo incluindo, mas não limitado a direitos autorais (incluindo direito de software para computação), marca registrada, marca de serviço, desenho, patente, segredos da marca, direitos de arquitetura de circuitos e semicondutores, comércio, negócios, domínio ou nomes de empresas, direitos de Informação Confidencial, know-how ou outros direitos de propriedade (independente de qualquer um destes estar registrado e inclusive em fase de registro) e todos os direitos ou formas de proteção de natureza similar ou tendo efeito equivalente ou similar a qualquer um destes que possa existir em qualquer parte do mundo.</td>
        </tr>
        <tr>
          <td>Qatar Airways Group</td>
          <td>refere-se às empresas do Qatar Airways Group Q.C.S.C. e cada uma de suas subsidiárias, Afiliadas, entidades gerenciadas, cessionárias autorizadas e sucessoras.</td>
        </tr>
        </tbody>
      </table>
      <br/>
    </div>
  )
}
