export default function FrText() {
  return (
    <div>
      <p>Veuillez lire attentivement les présentes conditions d'utilisation avant d'accéder à cette application web et de l'utiliser, ainsi que ses services, fonctions, matériels ou contenus (ci-après désignée le "<b>microsite</b>"). Ce microsite est détenu et exploité par Qatar Airways Group Q.C.S.C, une société qatarie dont le siège se trouve à Qatar Airways Tower 1, Airport Road, État du Qatar.</p>
      <p>PAR L'ACCÈS, L'UTILISATION, LA CONSULTATION, LA TRANSMISSION, LA MISE EN CACHE OU LE STOCKAGE DE CE MICROSITE (ai.flyqatar. com), VOUS INDIQUEZ QUE VOUS ACCEPTEZ D'ÊTRE LIÉ(E) PAR LES PRÉSENTES CONDITIONS (LES "CONDITIONS D'UTILISATION") DANS LE CADRE DE L'ACCÈS FOURNI AU MICROSITE TEL QUE DÉCRIT CI-DESSOUS ET VOUS GARANTISSEZ QUE VOUS AVEZ L'ÂGE DE LA MAJORITÉ JURIDIQUE ET QUE VOUS AVEZ LA CAPACITÉ JURIDIQUE DE CONCLURE LE PRÉSENT CONTRAT ET D'UTILISER CE MICROSITE CONFORMÉMENT À TOUTES LES CONDITIONS D'UTILISATION PRÉVUES DANS LES PRÉSENTES. VOUS ACCEPTEZ D'ÊTRE FINANCIÈREMENT RESPONSABLE DE TOUTE VOTRE UTILISATION DE CE SITE (AINSI QUE DE L'UTILISATION DE VOTRE COMPTE PAR D'AUTRES PERSONNES).</p>
      <p>Les termes "vous", "votre" et "vos" se réfèrent aux personnes accédant à ce Microsite (y compris les personnes qui accèdent à ce site pour le compte d'autres personnes). Les termes "nous", "notre", "nos" et autres mots similaires font référence à Qatar Airways. Si vous n'acceptez ni ces conditions générales d'utilisation, ni les documentations associées figurant ci-dessous, ni les autres restrictions qui vous sont notifiées au cours de votre utilisation de ce microsite, vous n'êtes pas autorisé(e), et vous renoncez, à accéder à celui-ci et à l'utiliser.</p>
      <p>Les présentes conditions d'utilisation régissent votre utilisation du microsite et de l'ensemble des applications, logiciels et services (collectivement désignés les "services") disponibles sur le microsite, sauf dans la mesure où ces services font l'objet d'un accord distinct. Des conditions spécifiques peuvent s'appliquer à l'utilisation de certains services et d'autres éléments qui vous sont fournis sur le microsite ("accord(s) de service"). Les termes en majuscules qui ne sont pas autrement définis dans les présentes conditions d'utilisation ont la signification qui leur est donnée dans la section "Définitions" jointe à la fin des présentes conditions d'utilisation.</p>
      <h3>Conditions générales d'utilisation</h3>
      <h3>Votre utilisation du microsite</h3>
      <p>Sous réserve de votre respect des présentes conditions d'utilisation, vous pouvez choisir de soumettre votre image par le biais de photographies (ci-après désignée "<b>votre contenu</b>") au microsite afin de l'inclure dans le film vidéo (ci-après désigné "<b>l'objectif</b>") disponible sur le microsite (ci-après désigné "<b>le film</b>"). Le film incorporant votre contenu sera désigné dans les présentes conditions d'utilisation sous le nom de "film modifié". </p>
      <p>En soumettant votre contenu au microsite pour qu'il soit inclus dans le film :</p>
      <ol className="alpha">
        <li>vous nous accordez un droit mondial, non exclusif, libre de droits, transférable, perpétuel, non révocable et susceptible de faire l'objet d'une sous-licence, d'utiliser, de reproduire, de modifier et de créer des œuvres dérivées de votre contenu pour l'objectif poursuivi ;</li>
        <br/>
        <li>
          vous déclarez et nous garantissez que :
          <ol className="roman">
            <li>Vous êtes en mesure de nous accorder les droits sur votre contenu pour l'objectif visé ;</li>
            <li>Vous avez et conserverez tous les droits, titres et intérêts relatifs à votre contenu qui sont nécessaires afin que nous puissions utiliser votre contenu pour l'objectif visé ;</li>
            <li>notre utilisation de votre contenu conformément aux présentes conditions d'utilisation et de la manière prévue ou autorisée par celles-ci n'enfreindra pas les droits de propriété intellectuelle d'un tiers et ne violera aucune loi applicable ; et</li>
            <li>ni Qatar Airways, ni aucune autre personne agissant sur ordre ou avec l'autorisation de Qatar Airways (y compris les membres du groupe Qatar Airways), ne sera tenu de payer à un tiers une licence ou d'autres frais liés à l'utilisation de votre contenu conformément aux présentes conditions d'utilisation et de la manière prévue ou autorisée par celles-ci.</li>
          </ol>
        </li>
      </ol>
      <br/>
      <p>Vous devez nous informer dès que possible de toute atteinte ou infraction présumée ou soupçonnée par votre contenu aux droits de propriété intellectuelle d'un tiers et vous devez coopérer pleinement avec nous dans le cadre de toute action en justice que nous entreprendrons pour faire respecter nos droits de propriété intellectuelle.</p>
      <p>Sous réserve de votre respect des présentes conditions d'utilisation, nous vous accordons un droit mondial, limité, personnel, révocable, libre de droits, non transférable et non sous-licenciable d'utiliser, de télécharger, de reproduire, de téléverser, de transmettre, de traduire et d'altérer le film modifié à des fins personnelles uniquement (et non à des fins commerciales de quelque nature que ce soit).</p>
      <p>Tous les droits qui ne vous sont pas expressément accordés dans les présentes conditions d'utilisation nous sont réservés.</p>
      <h3>Licence et propriété - Qatar Airways</h3>
      <p>Tous les droits de propriété intellectuelle associés au microsite et à son contenu, y compris le film et le film modifié (ci-après désigné le "<b>contenu</b>"), sont la propriété exclusive de Qatar Airways Group Q.C.S.C ou de tiers. Le contenu de notre microsite est protégé par les lois applicables en matière de droits d'auteur ainsi que par d'autres lois.</p>
      <p>Les éléments du microsite sont également protégés par le secret commercial, la concurrence déloyale et d'autres lois et ne peuvent être copiés ou imités en tout ou en partie. Tous les graphiques, icônes et autres éléments personnalisés qui apparaissent sur le microsite sont des marques commerciales ou des marques de service (ci-après désignées les "<b>marques</b>") de Qatar Airways, de ses filiales ou d'autres entités qui ont accordé à Qatar Airways le droit et la licence d'utilisation de celles-ci et ne peuvent être utilisées ou altérées de quelque manière que ce soit sans le consentement écrit exprès de Qatar Airways.</p>
      <p>Sauf autorisation expresse des présentes conditions d'utilisation, vous ne pouvez pas copier, reproduire, modifier, louer, prêter, vendre, créer des œuvres dérivées, télécharger, transmettre ou distribuer la propriété intellectuelle du microsite de quelque manière que ce soit sans l'autorisation écrite préalable de Qatar Airways ou du tiers concerné. Sauf disposition expresse dans les présentes, Qatar Airways ne vous accorde aucun droit explicite ou implicite sur les droits de propriété intellectuelle de Qatar Airways ou d'un tiers.</p>
      <p>Nonobstant les autres dispositions des présentes conditions d'utilisation, Qatar Airways vous accorde une licence limitée, personnelle, non transférable, non sous-licenciable et révocable pour (a) accéder et utiliser uniquement le microsite, le contenu et les services de la manière présentée par Qatar Airways, et (b) accéder et utiliser les services informatiques et de réseau de Qatar Airways offerts dans le microsite (ci-après désignés les "systèmes de Qatar Airways") uniquement de la manière expressément permise par Qatar Airways.</p>
      <p>À l'exception de la licence limitée accordée dans les présentes conditions d'utilisation, Qatar Airways ne transfère aucun droit sur les systèmes de Qatar Airways, les informations ou les données disponibles via les systèmes de Qatar Airways (ci-après désignées les "informations"), le contenu, les services, le microsite ou toute autre propriété de Qatar Airways en vous permettant d'accéder au microsite.</p>
      <p>Sauf dans la mesure requise par la loi ou expressément prévue par les présentes, aucun élément du contenu et/ou des informations ne peut faire l'objet d'une ingénierie inverse, être modifié, reproduit, republié, traduit dans une langue ou un langage informatique quelconque, retransmis sous quelque forme ou par quelque moyen que ce soit, revendu ou redistribué sans l'accord écrit préalable de Qatar Airways. Nonobstant ce qui précède, vous vous engagez à ne pas : (a) copier, afficher, modifier, reproduire ou transférer de quelque manière que ce soit tout matériel à un tiers sans l'autorisation écrite préalable de Qatar Airways ; (b) interférer avec les réseaux connectés au site ou les perturber ; (c) utiliser ou tenter d'utiliser tout dispositif, logiciel ou routine qui interfère avec le bon fonctionnement du site ou de toute transaction proposée par l'intermédiaire du site ; (d) transmettre des fichiers, des données ou d'autres éléments contenant un virus informatique, des données corrompues, des vers, des « chevaux de Troie » ou d'autres instructions ou concepts susceptibles d'effacer des données ou des programmes ou de rendre le site ou tout équipement ou système inopérant ou incapable d'être utilisé de la manière pour laquelle il a été conçu ; (e) transmettre vers le site ou par son intermédiaire toute communication violant toute loi locale, nationale, fédérale ou internationale ; (f) transmettre vers le site ou par son intermédiaire toute communication contenant des propos diffamatoires, injurieux ou obscènes ; (g) transmettre vers le site ou par son intermédiaire toute communication portant atteinte aux droits d'un tiers.</p>
      <p>En outre, en ce qui concerne le contenu, l'affichage, l'utilisation, la transmission, la reproduction ou le partage du film modifié et/ou l'incorporation du film modifié dans tout contenu, vous vous engagez à ne pas :</p>
      <ol className="alpha">
        <li>vous faire passer pour nous, l'une de nos sociétés affiliées, l'un des membres du Qatar Airways Group ou un de nos agents ou de ceux de nos sociétés affiliées, ou présenter de manière trompeuse votre identité ou votre affiliation ;</li>
        <li>falsifier les en-têtes ou manipuler de toute autre manière les identifiants (y compris les URL) de manière à induire ou potentiellement induire en erreur des tiers quant à votre identité ou à votre affiliation avec nous ;</li>
        <li>vous livrer à des activités qui violeraient toute relation fiduciaire, toute loi locale, étatique, nationale ou internationale applicable, ou toute réglementation ayant force de loi ;</li>
        <li>transmettre, spammer, téléverser, publier ou transmettre le film modifié d'une manière inexacte, nuisible, obscène, diffamatoire, raciste ou autrement offensante pour autrui ;</li>
        <li>faire ou omettre de faire quoi que ce soit qui soit préjudiciable à nos intérêts ou à ceux de Qatar Airways Group, comme nous pouvons le déterminer à notre seule et entière discrétion ;</li>
        <li>supprimer tout avis de propriété du film modifié ;</li>
        <li>utiliser le film modifié d'une manière ou à des fins qui enfreignent, détournent ou violent d'une autre manière tout droit de propriété intellectuelle ou tout autre droit de toute personne, ou qui enfreignent toute loi applicable ;</li>
        <li>accéder à tout ou partie du contenu pour créer un produit et/ou un service concurrent du contenu ou de l'un de ses aspects ; ou</li>
        <li>exploiter commercialement, vendre, concéder des licences ou distribuer le contenu.</li>
      </ol>
      <br/>
      <p>En outre, vous devez vous conformer à toute autre directive supplémentaire concernant le film modifié que nous pourrions publier de temps à autre.</p>
      <h3>Informations sur les droits d'auteur et les marques déposées</h3>
      <p>Tous les éléments de ce microsite, le code logiciel sous-jacent ou les données logicielles sont protégés par des droits d'auteur, des marques commerciales et/ou d'autres droits de propriété intellectuelle. Ces éléments sont la propriété de QATAR AIRWAYS GROUP Q.C.S.C ou sont utilisés avec l'autorisation de leurs propriétaires ou avec l'autorisation de la loi. Les éléments protégés comprennent, sans s'y limiter, le texte, les marques, le code source, les bases de données, les logos, les photographies, les images, les clips vidéo, les clips audio, les dessins, la présentation et les autres éléments contenus dans le présent microsite, le site mobile et les applications mobiles. Tous les droits sont réservés, dans le monde entier.</p>
      <p>Sauf autorisation expresse en vertu des présentes conditions d'utilisation, vous ne devez pas télécharger, imprimer ou reproduire des parties du contenu pour votre usage personnel et non commercial, et toute autre utilisation du contenu (y compris, mais sans s'y limiter, les photographies, les articles, les communiqués de presse, les logiciels, les commentaires, les tableaux ou les graphiques, les clips vidéo, les logos) sur le microsite, le site mobile et les applications mobiles est strictement interdite. À titre d'exemple, l'utilisation interdite peut inclure l'extraction, la redistribution, la republication, la modification, la diffusion, la vente ou toute autre forme de mise à disposition de toute information ou matériel sur ce microsite (sous quelque format que ce soit) à des tiers. Outre cette restriction, vous acceptez de vous conformer à tous les avis, informations ou restrictions en matière de droits d'auteur contenus dans tout matériel auquel vous accédez par l'intermédiaire du microsite. L'utilisation non autorisée de ce microsite, du site mobile et des applications mobiles ou de toute information ou matériel contenu sur ce microsite, ou de toute information ou matériel contenu sur ce microsite est illégale et peut donner lieu, entre autres, à une demande de dommages-intérêts et peut également constituer une infraction pénale.</p>
      <h3>Actions ou utilisations non autorisées</h3>
      <p>Vous convenez que votre utilisation de ce microsite est à usage personnel et que celui-ci vous est fourni uniquement afin de consulter et d'acheter nos produits et nos services, et à nulle autre fin. Vous convenez notamment que vous ne procéderez à aucune réservation non autorisée, falsifiée ou frauduleuse, et que vous ne tenterez ni d'interférer dans les opérations de ce microsite, ni d'altérer ou de corrompre les données ou les programmes.</p>
      <p>Il relève de votre responsabilité de préserver la confidentialité de vos informations d'accès au microsite (telles que les identifiants, les mots de passe et les numéros d'identification personnelle) et de vos données de réservation (telles que les numéros de réservation et vos itinéraires de voyage).</p>
      <p>Vous pouvez choisir d'autoriser des membres de votre famille, des amis ou des collègues à accéder au microsite pour votre compte en leur fournissant vos informations d'accès et/ou vos données de réservation, à condition que :</p>
      <ol className="alpha">
        <li>vous ayez obtenu l'accord de toutes les personnes dont les données de réservation ou les informations personnelles sont accessibles avec les vôtres (dans le cas d'une réservation de groupe par exemple) ; et</li>
        <li>vous acceptiez l'entière responsabilité des actes et omissions de ces personnes lorsqu'elles se rendent sur le microsite, en font usage, et accèdent aux informations et/ou aux données de réservation fournies par vos soins, que vous ayez autorisé cet accès ou pas.</li>
      </ol>
      <br/>
      <p>Vous ne devez pas permettre l'accès à ce microsite, ni son utilisation, ni celle des contenus y figurant, ou pouvant être obtenus depuis celui-ci, à toute entité commerciale ou autre organisation prestataire de services au public.</p>
      <h3>Vos engagements</h3>
      <p>En plus des autres limitations prévues dans les présentes conditions générales d'utilisation, vous acceptez de :</p>
      <ol className="alpha">
        <li>Ne pas dissimuler l'origine des informations transmises à travers le microsite.</li>
        <li>Ne pas utiliser ni accéder à des services, des informations, des applications ou des programmes disponibles via le microsite d'une manière non expressément autorisée par Qatar Airways.</li>
        <li>Ne pas saisir ou téléverser sur le microsite toute information contenant un virus, un cheval de Troie, un ver, un script de bombe à retardement ou autre programme informatique destiné à endommager, interférer, intercepter ou exproprier un système, le microsite et ses informations, ou à violer les droits de propriété intellectuelle d'autrui.</li>
        <li>Reconnaître que certaines rubriques du microsite sont réservées aux clients de Qatar Airways.</li>
        <li>Ne pas accéder ni utiliser le microsite ou les systèmes et services de Qatar Airways d'une manière qui, selon le jugement de Qatar Airways, a une incidence négative sur la performance ou le fonctionnement de ses systèmes, de ses services ou de son microsite, ou qui interfère avec la capacité des tiers autorisés à y accéder.</li>
        <li>Ne pas utiliser de cadrage ni des techniques d'encadrement pour entourer une portion ou un aspect des contenus ou des informations, sans le consentement écrit exprès de Qatar Airways.</li>
      </ol>
      <br/>
      <p>Vous vous engagez à suivre et à respecter les présentes conditions générales d'utilisation et toutes instructions et procédures énoncées sur ce microsite. Vous comprenez que le fait de ne pas suivre toutes les instructions et procédures peut entraîner : l'obligation d'acheter des biens ou des services non souhaités ; la confiscation de vos produits ou services ; l'impossibilité d’accéder aux produits ou aux services de ce microsite ; et que des poursuites judiciaires peuvent être engagées contre vous.</p>
      <p>Vous reconnaissez et acceptez que vous restez responsable de :</p>
      <ol className="alpha">
        <li>l'évaluation et la prise en charge de tous les risques liés à l'utilisation du microsite et de son contenu ;</li>
        <li>la protection et la sauvegarde adéquates de votre contenu et/ou des systèmes que vous utilisez dans le cadre de l'accès au microsite et de son utilisation ;</li>
        <li><b>votre utilisation du microsite et du contenu, que cet accès ou cette utilisation soit autorisé par les présentes conditions d'utilisation ou en violation de celles-ci, y compris l'utilisation d'applications mobiles ou de logiciels de tiers.</b></li>
      </ol>
      <br/>
      <h3>Exonérations et limitations de responsabilité</h3>
      <ol className="alpha">
        <li>Bien que les informations sur les produits et tous les autres éléments du microsite, du contenu, du site mobile et des applications mobiles soient fournis de bonne foi, en utilisant ces derniers, vous acceptez et reconnaissez que Qatar Airways ne fait aucune déclaration, ne donne aucune garantie et ne fait aucune réclamation quant à l'exactitude des informations sur les produits ou de tout autre élément du microsite, de son contenu, de son site mobile et de ses applications mobiles. Nous ne déclarons ni ne garantissons que votre utilisation du microsite sera ininterrompue ou exempte d'erreurs, ou que le microsite ou son contenu seront exempts de code nuisible. En outre, vous acceptez que les informations sur les produits et le microsite, son contenu, le site mobile et les applications mobiles ne constituent pas une forme de conseil ou de recommandation à votre égard.</li>
        <li>Qatar Airways décline toutes les garanties tacites relatives à votre utilisation de ce microsite et son contenu, du site mobile, des applications mobiles, des informations, des produits et des services contenus sur ce microsite et son contenu, sur le site mobile et sur les applications mobiles. Dans les limites autorisées par la loi, toutes les informations sur ce microsite et son contenu, sur le site mobile et sur les applications mobiles sont fournies sans aucune garantie (explicite ou implicite découlant de la loi ou d'autres sources) ni aucune disposition tacite de quelque sorte que ce soit, notamment, mais sans s'y limiter, des garanties ou des dispositions tacites concernant la qualité satisfaisante, l'adéquation à un usage particulier ou la non-violation des droits des tiers.</li>
        <li>En utilisant ce microsite et son contenu, le site mobile ou les applications mobiles, vous convenez que Qatar Airways ne saurait être tenue responsable envers vous pour toute perte directe, indirecte, consécutive ou toute autre perte découlant de l'utilisation (ou de la non-utilisation) des informations, des produits et des services contenus sur ce microsite et son contenu, sur le site mobile et sur les applications mobiles ou découlant de votre accès à d'autres contenus via les liens qui figurent sur ces derniers. Les exclusions et limitations contenues dans les présentes conditions d'utilisation s'appliquent dans les limites autorisées par la loi.</li>
        <li>Qatar Airways ne garantit pas que l'utilisation de ce microsite et son contenu, du site mobile et des applications mobiles sera compatible avec tous les matériels informatiques et les logiciels qui peuvent être utilisés par les visiteurs du site.</li>
        <li>À l'exception des cas prévus aux paragraphes (f) et (g), Qatar Airways décline toute responsabilité envers vous, qu'elle soit contractuelle, délictuelle (y compris une négligence), découlant d'un manquement aux obligations statutaires, de la restitution ou autre, pour toute blessure, décès, dommage ou perte directe, indirecte ou consécutive (les trois termes incluent, sans s'y limiter, une perte économique pure, une perte de profits, une perte commerciale, une diminution de la clientèle et une perte similaire), quelle qu'en soit la cause, en raison de l'utilisation de ce microsite et de son contenu, du site mobile et des applications mobiles ou de l'utilisation, de l'accès, du téléchargement ou de l'utilisation de toute information ou tout autre contenu figurant sur ce microsite et son contenu, sur le site mobile et sur les applications mobiles, y compris, sans s'y limiter, à la suite d'un virus informatique.</li>
        <li>Les présentes conditions d'utilisation n'excluent pas la responsabilité de Qatar Airways (le cas échéant) envers vous pour les dommages corporels ou les décès résultant de la négligence de Qatar Airways, et de la fraude ou de toute autre cause qu'il serait illégal d'exclure ou de tenter d'exclure de la part de Qatar Airways.</li>
        <li>Sous réserve des dispositions du paragraphe (f), l'entière responsabilité de Qatar Airways concernant toute question ou série de questions découlant de, ou en relation avec, la fourniture par Qatar Airways de ce microsite et de son contenu, du site mobile ou des applications mobiles et de tout service pour quelque raison que ce soit sous quelque moyen que ce soit, sera limitée à 1 000 USD au total pour chaque événement ou série d'événements connexes.</li>
        <li>Vous devrez indemniser Qatar Airways et ses concédants de licence pour toutes pertes, dommages, réclamations et frais (y compris les frais juridiques) qui leur sont causés ou qu'ils subissent du fait de votre violation de n'importe laquelle des dispositions de ces conditions d'utilisation.</li>
      </ol>
      <br/>
      <h3>Confidentialité et cookies</h3>
      <p>Qatar Airways s'engage à protéger votre vie privée et à se conformer aux lois applicables en matière de protection des données et de la vie privée, ainsi qu'à la protection de vos données personnelles. Par "données personnelles", nous entendons toute information relative à une personne physique identifiée ou identifiable. Les données personnelles seront traitées conformément à notre avis de confidentialité disponible <a target="_blank" rel="noreferrer" href="/privacypolicy?selLang=fr">ici</a>.</p>
      <p>Les cookies sont de petits fichiers d'information qui enregistrent et récupèrent des informations lors de votre visite sur notre microsite - par exemple, comment vous êtes entré(e) sur notre site, comment vous avez navigué sur le site et quelles informations vous ont intéressé. Pour en savoir plus sur l'utilisation des cookies, consultez notre politique en matière de cookies, disponible sur notre microsite <a target="_blank" rel="noreferrer" href="https://www.qatarairways.com/fr-fr/legal/cookie-policy.html">ici</a>.</p>
      <h3>Liens</h3>
      <p>La création d'un lien vers ce microsite est strictement interdite sans notre consentement écrit, qui peut-être retiré à tout moment. Tout microsite ou autre dispositif lié à http://www.qatarairways.com ou à toute page disponible sur celui-ci n'est pas autorisé à :</p>
      <ol className="alpha">
        <li>Copier son contenu,</li>
        <li>Utiliser un environnement de navigateur ou de cadrage autour de son contenu,</li>
        <li>Insinuer d'une quelconque manière que Qatar Airways ou ses filiales cautionnent le site ou ses produits,</li>
        <li>Représenter faussement tout état de faits, y compris sa relation avec Qatar Airways ou ses filiales,</li>
        <li>Présenter des informations trompeuses sur les produits et les services de Qatar Airways, et</li>
        <li>Utiliser tout logo ou marque de Qatar Airways ou de ses filiales sans l'autorisation écrite expresse de Qatar Airways.</li>
      </ol>
      <br/>
      <p>Ce microsite peut contenir des liens vers d'autres microsites et des ressources de tiers (ci-après désignés collectivement les "sites liés"). Ces sites liés sont fournis uniquement afin de faciliter votre consultation et ne signifient pas que Qatar Airways en cautionne le contenu. Qatar Airways ne fait aucune déclaration ni n'offre aucune garantie relative à la justesse, l'exactitude, la performance ou la qualité de tout contenu, programme, service ou application figurant sur un site lié. Qatar Airways n'est pas responsable de la disponibilité des sites liés, ni du contenu ou des activités de ces derniers. Si vous décidez d'accéder à des sites liés, vous le faites à vos propres risques. En outre, votre utilisation des sites liés est soumise aux politiques et aux conditions générales d'utilisation applicables, y compris, mais sans s'y limiter, aux politiques en matière de confidentialité et de cookies des sites liés.</p>
      <h3>Modifications des conditions</h3>
      <p>Les présentes conditions d'utilisation et/ou les services, les produits et les contenus d'informations fournis sur ce microsite peuvent être mis à jour, révisés, modifiés ou retirés par Qatar Airways à tout moment et sans préavis. En utilisant ce microsite, vous êtes réputé(e) accepter sa dernière version.</p>
      <h3>Divers</h3>
      <p>Sauf si expressément prévu dans un accord séparé entre Qatar Airways et vous, les présentes conditions générales d'utilisation remplacent tous les contrats, les dispositions, les représentations (autres que les fausses déclarations), les termes de la loi, et les ententes entre les parties, à la fois écrites, orales, coutumières ou autres, concernant l'objet du présent document. Les présentes conditions générales d'utilisation sont rédigées en anglais. Les parties déclarent avoir demandé que les présentes conditions générales d'utilisation, ainsi que tous les documents qui s'y rattachent, présents ou futurs, soient rédigés uniquement en langue anglaise.</p>
      <h3>Divisibilité et renonciation</h3>
      <p>Si une disposition des présentes conditions générales d'utilisation est considérée, par une cour, un tribunal, un organisme administratif ou une autorité compétente, illégale, nulle ou inapplicable, elle sera, dans la mesure requise, retirée de ces dernières et sera sans effet, et ce, sans modifier ni affecter, autant que possible, les autres dispositions qui demeureront pleinement en vigueur.</p>
      <p>Aucune renonciation aux termes, dispositions ou clauses des présentes conditions générales ne sera acceptée, à moins que celle-ci ne soit attestée par écrit et signée par la partie accordant la dite renonciation. Aucune renonciation aux termes, dispositions ou clauses des présentes conditions générales d'utilisation ne sera acceptée, à moins que celle-ci ne soit attestée par écrit et signée par la partie accordant la dite renonciation.</p>
      <h3>Droit applicable et tribunaux compétents</h3>
      <p>Les conditions générales d'utilisation de ce microsite sont régies et interprétées conformément aux lois de l'État du Qatar. Les tribunaux qataris disposeront d'une compétence non exclusive pour statuer sur les litiges qui pourraient en découler. Dans toute la mesure permise par la loi, vous convenez que Qatar Airways se réserve le droit d'intenter une action contre vous dans le pays qu'elle jugera approprié.</p>
      <h3>Résiliation</h3>
      <p>Vous convenez que Qatar Airways peut, à son entière discrétion, résilier ou suspendre votre utilisation du microsite, des systèmes, des informations, des services et des contenus de Qatar Airways, à tout moment et quelle que soit la raison, même si l'accès et l'utilisation demeurent autorisés à autrui. Dans ce cas, vous devez immédiatement (a) cesser votre utilisation du microsite et (b) détruire les copies que vous auriez pu faire de toute portion de son contenu. Accéder au microsite et aux systèmes, informations ou services de Qatar Airways après une telle résiliation, suspension ou cessation sera considéré comme une infraction. En outre, vous convenez que Qatar Airways ne pourra être tenue responsable envers vous ou tout tiers en cas de résiliation ou suspension de votre accès au microsite et aux systèmes, informations et/ou services de Qatar Airways.</p>
      <h3>Contact</h3>
      <p>Si vous avez des questions sur notre microsite ou sur nos conditions d'utilisation, veuillez contacter :</p>
      <p>Qatar Airways Group Q.C.S.C<br/>
        Qatar Airways Tower<br/>
        P.O. Box: 22550<br/>
        Doha, State of Qatar<br/>
        Email: <a target="_blank" rel="noreferrer" href="mailto:qatarairways.com/contactus">qatarairways.com/contactus</a>
      </p>
      <br/>
      <table>
        <tbody>
        <tr>
          <td colSpan={2}>Définitions</td>
        </tr>
        <tr>
          <td>Affilié(s)</td>
          <td>Affilié(s) désigne toute entité (ou entités) qui, directement ou indirectement, contrôle, est contrôlée par, ou est sous contrôle commun avec, une partie ;</td>
        </tr>
        <tr>
          <td>Code nuisible</td>
          <td>désigne (a) tout virus, cheval de Troie, ver, logiciel espion, porte dérobée, trappe, bombe, logiciel malveillant ou service "drop dead" ; et (b) toute autre routine logicielle destinée ou conçue pour être nuisible, destructrice ou perturbatrice, pour provoquer des dysfonctionnements, des erreurs, des défauts, un déni ou une dégradation du service ou la perte, l'accès non autorisé, la modification, la divulgation ou la corruption de données.</td>
        </tr>
        <tr>
          <td>Droits de propriété intellectuelle</td>
          <td>désigne tous les droits de propriété industrielle et intellectuelle de quelque nature que ce soit, y compris, mais sans s'y limiter, les droits d'auteur (y compris les droits sur les logiciels), les marques commerciales, les marques de service, les dessins et modèles, les brevets, les secrets commerciaux, les droits sur les semi-conducteurs ou les schémas de circuits, les noms commerciaux, d'entreprise, de domaine ou de société, les droits sur les informations confidentielles, le savoir-faire ou d'autres droits de propriété (enregistrés ou non, voire en cours de dépôt), ainsi que tous les droits ou formes de protection de nature similaire ou ayant un effet équivalent ou similaire à l'un quelconque de ces droits, qui peuvent exister n'importe où dans le monde.</td>
        </tr>
        <tr>
          <td>Qatar Airways Group</td>
          <td>désigne Qatar Airways Group Q.C.S.C. et chacune de ses filiales, sociétés affiliées, entités gérées, cessionnaires autorisés et successeurs.</td>
        </tr>
        </tbody>
      </table>
      <br/>
    </div>
  )
}
