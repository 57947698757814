export default function FrText(){
  return (
    <ol className="decimal">
      <li>PRÉSENTATION</li>
      <br/>
      <p><b>Qatar Airways Group Q.C.S.C.</b>, (ci-après désigné "<b>Qatar Airways</b>", "<b>nous</b>", "notre" ou "nos") souhaite que vous vous sentiez en sécurité lorsque vous utilisez ce site web (ci-après désigné le "<b>microsite</b>") et s'engage donc à protéger vos données personnelles et votre droit à la vie privée lorsque vous le faites.</p>
      <p>Cet avis de confidentialité de Flyqatar.com (ci-après désigné "<b>l'avis de confidentialité</b>") (ainsi que nos <a target="_blank" rel="noreferrer" href="/termsandconditions?selLang=fr">conditions générales</a> et <a target="_blank" rel="noreferrer" href="https://www.qatarairways.com/fr-fr/legal/cookie-policy.html">notre politique en matière de cookies</a>) explique comment les données personnelles sont ou seront traitées par Qatar Airways et nos prestataires de services. Par "<b>données personnelles</b>", nous entendons toute information relative à une personne physique identifiée ou identifiable.</p>
      <p>Veuillez lire attentivement ce qui suit pour comprendre comment nous traitons vos données personnelles sur ce microsite.
Lorsque vous divulguez des données personnelles au nom d'une autre personne, vous vous engagez à vous assurer que la personne dont les données personnelles sont fournies à Qatar Airways a autorisé la divulgation et a reçu une copie de cet avis de confidentialité avant que vous ne divulguiez ces données personnelles.</p>
      <p>Si nous modifions le présent avis de confidentialité, nous vous en informerons en publiant la version mise à jour sur notre microsite. Sous réserve des lois applicables, la version anglaise du présent avis de confidentialité prévaudra sur toute version du présent avis de confidentialité traduite dans une autre langue.</p>
      <li>QUAND LE PRÉSENTE AVIS DE CONFIDENTIALITÉ S'APPLIQUE-T-IL ?</li>
      <br/>
      <p>Le présent avis de confidentialité s'applique au traitement des données personnelles sur ce microsite.</p>
      <li>QUI EST RESPONSABLE DU TRAITEMENT DES DONNÉES PERSONNELLES ?</li>
      <br/>
      <p>Qatar Airways, dont le siège social est situé à <b>Qatar Airways Tower 1, PO Box 22550, Doha, État du Qatar</b>, est responsable, en tant que contrôleur, du traitement des données personnelles décrit dans le présent avis de confidentialité.</p>
      <li>COMMENT NOUS RECUEILLONS ET RECEVONS LES DONNÉES PERSONNELLES</li>
      <br/>
      <p>Nous et nos prestataires de services pouvons recueillir et recevoir des données personnelles directement auprès de vous de différentes manières, notamment :</p>
      <p>
        <u>Via le microsite</u> : nous pouvons recueillir des données personnelles par l'intermédiaire du microsite. Par exemple, lorsque vous utilisez les services fournis sur le microsite.<br/>
        <u>Hors ligne</u> : dans des lieux spécifiques, tels que les centres commerciaux, nous pouvons recueillir vos données personnelles en fonction de votre intérêt et de votre volonté d'utiliser les services fournis sur le microsite. Par exemple, lorsque vous autorisez la prise de photos via nos appareils officiels et leur transfert sur le microsite afin de recevoir des vidéos personnalisées vous mettant en scène.
Notre microsite peut contenir des liens qui vous permettent de visiter des sites web de tiers. Veuillez noter que nous n'avons aucun contrôle sur la manière dont les contrôleurs responsables de ces sites web tiers traitent vos données personnelles et que nous ne sommes pas responsables de la manière dont ils opèrent ou traitent vos données personnelles. Il est important que vous compreniez cela et que vous vérifiiez leurs politiques de confidentialité respectives et les conditions d'utilisation de leurs sites web.</p>
      <li>QUELLES SONT LES DONNÉES PERSONNELLES RECUEILLIES ?</li>
      <br/>
      <p>
        Les "<b>données personnelles</b>" sont des informations qui vous identifient en tant qu'individu ou qui se rapportent à un individu identifiable, y compris :<br/>
        <ol className="roman">
          <li>Une image / photographie ; et</li>
          <li>Une adresse e-mail.</li>
        </ol>
      </p>
      <p>Nous pouvons être amenés à recueillir et à traiter ces types de données personnelles afin de vous permettre de créer un film conformément à nos conditions générales. Si vous ne fournissez pas les informations que nous demandons, il se peut que vous ne puissiez pas participer à la création d'un film sur notre microsite.</p>
      <li>À QUELLES FINS LES DONNÉES PERSONNELLES SONT-ELLES RECUEILLIES ?</li>
      <br/>
      <p>Nous et nos prestataires de services pouvons utiliser les données personnelles sur la base de votre consentement.</p>
      <p>Votre image ou votre photographie peut être utilisée pour créer un film ou une vidéo selon votre choix et vous permettre de participer et d'utiliser les services fournis dans le cadre de ce microsite. Dans les lieux hors ligne, si vous y consentez, votre image ou photographie et le film ou la vidéo qui s'y rapporte peuvent être temporairement affichés sur notre panneau d'affichage numérique qui s'y trouve.</p>
      <p>Nous utilisons votre adresse e-mail pour vous envoyer le lien vers le film / la vidéo généré(e) après avoir utilisé notre microsite. Nous n'utilisons pas vos données à d'autres fins. Le partage de votre adresse e-mail est facultatif si vous souhaitez recevoir le film / la vidéo personnalisé(e) dans votre boîte de réception.</p>
      <p>En vous abonnant, nous utilisons votre adresse e-mail pour vous envoyer des actualités par le biais de newsletters. Vous pouvez toujours refuser de les recevoir en utilisant le lien de désabonnement fourni dans chaque newsletter.</p>
      <li>PRISE DE DÉCISION AUTOMATISÉE</li>
      <br/>
      <p>Nous faisons appel à l'intelligence artificielle (ci-après désignée "<b>IA</b>") pour générer votre film / vidéo personnalisé(e).</p>
      <p>La technologie d'échange de visages utilise des algorithmes avancés d'apprentissage automatique et d'intelligence artificielle pour identifier et remplacer le visage d'une personne par celui d'une autre dans des images ou des vidéos. Le processus comprend généralement la détection des visages, l'extraction des caractéristiques et la synthèse des visages. L'algorithme détecte d'abord les visages dans le contenu, puis analyse les principales caractéristiques faciales et enfin intègre le visage de remplacement dans l'image ou la vidéo d'origine en faisant correspondre le teint de la peau, l'éclairage et les expressions afin de garantir une transition harmonieuse.</p>
      <li>COOKIES</li>
      <br/>
      <p>Les cookies sont de petits fichiers d'information qui enregistrent et récupèrent des informations lors de votre visite sur notre microsite - par exemple, comment vous êtes entré(e) sur notre site, comment vous avez navigué sur le site et quelles informations vous ont intéressé. Pour en savoir plus sur l'utilisation des cookies, consultez notre  <a target="_blank" rel="noreferrer" href="https://www.qatarairways.com/fr-fr/legal/cookie-policy.html">politique en matière de cookies</a>.</p>
      <li>TRANSFERTS DE DONNÉES</li>
      <br/>
      <p><u>AU SEIN DE QATAR AIRWAYS GROUP</u></p>
      <p>Le personnel de Qatar Airways n'est autorisé à accéder aux données personnelles que si et dans la mesure où cela est nécessaire pour servir l'objectif applicable et pour effectuer son travail.</p>
      <p>En tant que membre d'une organisation mondiale dont le siège social se trouve à Doha, dans l'État du Qatar, les données personnelles que nous recueillons peuvent être transférées à l'échelle internationale au sein de Qatar Airways Group à des fins commerciales ou administratives vers des pays où Qatar Airways est établie et dont les lois sur la protection des données peuvent ne pas être aussi étendues que celles de l'Espace économique européen (ci-après désigné "<b>l'EEE</b>"). Le cas échéant, Qatar Airways veillera à ce que des garanties adéquates soient mises en place pour se conformer aux exigences relatives au transfert international de données personnelles en vertu des lois applicables en matière de protection des données et de la vie privée.</p>
      <p><u>EN DEHORS DE QATAR AIRWAYS GROUP</u><br/>Nous pouvons être amenés à divulguer certaines de vos données personnelles dans les cas suivants :</p>
      <ol className="alpha">
        <li>à des tiers, y compris des prestataires de services qui nous fournissent un service ou exercent une fonction pour nous, ou qui sont autrement désignés par nous dans le cadre des services que nous vous offrons, y compris ceux qui agissent en tant qu'agents, notamment les prestataires de services d'assistance, les fournisseurs de technologie, les consultants spécialisés, les conseillers juridiques et les auditeurs ;</li>
        <li>à des tiers dans le cadre d'un financement, d'une titrisation, d'une assurance, d'une restructuration, d'une cession ou de toute autre forme de transfert de tout ou partie de nos activités ou de nos actifs, ou dans le cadre de la défense de réclamations ; et</li>
        <li>aux autorités officielles, y compris les représentants des forces de l'ordre, les tribunaux et les autorités gouvernementales et réglementaires : (a) si nous estimons que la divulgation est requise par une loi, un règlement ou une procédure judiciaire applicable (par exemple pour répondre à des citations à comparaître ou à des ordonnances judiciaires) ; ou (b) pour protéger et défendre nos droits, ou les droits ou la sécurité de tiers, y compris pour se défendre contre des actions en justice.</li>
      </ol>
      <p>Lorsque des données personnelles sont divulguées à des tiers, nous prenons les mesures contractuelles, techniques et organisationnelles requises pour protéger dûment ces données personnelles conformément à la législation applicable.
Les divulgations susmentionnées peuvent impliquer le transfert de données personnelles vers et depuis des pays situés en dehors de votre pays de résidence, y compris vers et depuis des pays dont les lois sur la protection des données peuvent ne pas être aussi étendues que celles de l'EEE. Le cas échéant, Qatar Airways veillera à ce que des garanties adéquates soient mises en place pour se conformer aux exigences relatives au transfert international de données personnelles en vertu des lois applicables en matière de protection des données et de la vie privée.</p>
      <li>COMMENT ASSURONS-NOUS LA SÉCURITÉ DE VOS DONNÉES ?</li>
      <br/>
      <p>À Qatar Airways, nous avons mis en place des mesures de protection des données personnelles, car nous nous engageons à garantir leur sécurité. Afin d'empêcher l'accès non autorisé aux données personnelles ou leur divulgation, nous avons mis en place des mesures de protection physiques, techniques et administratives pour protéger les données personnelles contre la destruction accidentelle ou illégale, la perte accidentelle, les dommages, l'altération, la divulgation ou l'accès non autorisé, ainsi que contre toute autre forme de traitement illégal.
Bien que nous déployions des efforts raisonnables et appropriés pour protéger les données personnelles, nous ne pouvons pas garantir la sécurité des données personnelles transmises via Internet ou une connexion similaire. Si vous avez des raisons de penser que votre interaction avec nous n'est plus sécurisée, veuillez nous en informer immédiatement conformément à la section "<b>Comment nous contacter</b>" ci-dessous.</p>
      <li>UTILISATION DU MICROSITE PAR LES MINEURS</li>
      <br/>
      <p>Ce microsite ne s'adresse pas aux personnes âgées de moins de dix-huit (18) ans et nous ne recueillons pas sciemment de données personnelles auprès de personnes âgées de moins de 18 ans.</p>
      <li>CONSERVATION DES DONNÉES</li>
      <br/>
      <p>Nous conserverons les données personnelles aussi longtemps qu'il est nécessaire pour remplir l'objectif spécifié pour lequel elles ont été recueillies, les fins légales ou commerciales de Qatar Airways telles que décrites dans la présente charte de confidentialité et conformément à nos politiques de conservation des données, ou dans la mesure exigée par les lois en vigueur. Lors de la suppression des données personnelles, nous prendrons des mesures commercialement raisonnables et techniquement possibles pour rendre celles-ci irrécupérables ou impossibles à reproduire, conformément aux lois applicables.</p>
      <li>UTILISATION DU MICROSITE PAR LES MINEURS</li>
      <br/>
      <p>Ce microsite ne s'adresse pas aux personnes âgées de moins de dix-huit (18) ans et nous ne recueillons pas sciemment de données personnelles auprès de personnes âgées de moins de 18 ans.</p>
      <li>VOS DROITS</li>
      <br/>
      <p>Vous pouvez exercer vos droits à l'accès, la rectification, la suppression, le traitement restreint, la portabilité des données, l'opposition, ou vos droits relatifs à l'utilisation de système automatisé de prise de décision individuelle, y compris le profilage, en ce qui concerne les données personnelles que Qatar Airways traite, à tout moment, en envoyant une demande via un portail dédié de "<a target="_blank" rel="noreferrer" href="https://www.qatarairways.com/fr-fr/subject-access-request-form.html">demande d'accès individuelle</a>" ("subject access request" en anglais) sur la page Contact de qatarairways.com.qa. Vous pouvez également disposer d'autres droits relatifs à la vie privée, notamment les droits en vertu de la Loi californienne sur la vie privée des consommateurs (California Consumer Privacy Act ou "<b>CCPA</b>" en anglais), selon votre pays de résidence.</p>
      <p>Avant de donner suite à votre demande, nous vérifierons votre identité pour nous assurer que nous fournissons bien les informations demandées à la personne qu'elles concernent. Si vous avez autorisé un agent à faire une demande en votre nom, nous demanderons à ce que vous ou votre agent autorisé nous fournissiez une copie de l'autorisation écrite de soumettre une telle demande en votre nom.</p>
      <p>Nous ne facturerons pas de frais pour répondre à votre demande, à moins que celle-ci ne soit considérée comme manifestement infondée ou excessive. Sur demande, nous pouvons vous fournir une liste des tiers chargés du traitement des informations personnelles en vertu de la loi sur la protection des informations personnelles de la République populaire de Chine.
Si vous estimez que nous ne respectons pas les règles applicables en matière de respect de la vie privée, vous avez le droit de déposer une plainte auprès d'une autorité compétente de protection des données.</p>
      <p>Si vous ne souhaitez plus recevoir nos newsletters, vous pouvez vous désabonner à tout moment en cliquant sur le lien "Me désabonner" figurant dans chaque e-mail de notre newsletter. En outre, Vous pouvez formuler une demande de désabonnement par e-mail, en appelant notre centre d'assistance client ou en nous adressant un courrier à l'adresse suivante :</p>
      <p>
        Qatar Airways Group Q.C.S.C.<br/>
        Qatar Airways Tower 1,<br/>
        PO Box 22550,<br/>
        Doha, State of Qatar.<br/>
        Attention: Qatar Airways Direct Marketing Team - Ecommerce
      </p>
      <p>Dans le cas d'une demande de désabonnement formulée par e-mail, par téléphone ou par courrier, le traitement de votre demande et la mise à jour de nos systèmes pour supprimer votre adresse e-mail de notre liste de diffusion peut prendre jusqu'à 30 jours ouvrables.</p>
      <li>COMMENT NOUS CONTACTER OU DÉPOSER UNE PLAINTE</li>
      <br/>
      <p>Si vous avez des questions ou des préoccupations concernant le traitement de vos données personnelles ou sur le présent avis de confidentialité, veuillez contacter :</p>
      <p>E-mail : <a target="_blank" rel="noreferrer" href="mailto:digitalbrand@qatarairways.com.qa">digitalbrand@qatarairways.com.qa</a></p>
      <p>
        Qatar Airways Group Q.C.S.C.<br/>
        Qatar Airways Tower 1,<br/>
        PO Box 22550,<br/>
        Doha, State of Qatar.<br/>
        Attention: Marketing Department
      </p>
      <p>Vous pouvez également contacter notre délégué à la protection des données en envoyant un e-mail <a target="_blank" rel="noreferrer" href="mailto:dpo@qatarairways.com.qa">ici</a>. Veuillez noter que vous avez également le droit de déposer une plainte auprès d'une autorité de contrôle compétente.</p>
      <br/>
    </ol>
  )
}
