import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import {
    Link as ReactLink,
    useLocation,
    useSearchParams,
} from 'react-router-dom';
import clsx from 'clsx';
import { useCommon } from '../../../state/common';
import React from 'react';
import setCookieValue from '../../../helpers/setCookieValue';

const TermsLinks = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { isCameraOn } = useCommon();
    const [currentQueryParams, setSearchParams] = useSearchParams();
    const isGerman = localStorage.getItem('user-selected-language') === 'de';
    const isSpanish = localStorage.getItem('user-selected-language') === 'es';
    const isItalian = localStorage.getItem('user-selected-language') === 'it';
    const isFrench = localStorage.getItem('user-selected-language') === 'fr';

    const handleClickCookieConsent = (
        e: React.MouseEvent<HTMLAnchorElement>
    ) => {
        e.preventDefault();
        e.stopPropagation();
        setCookieValue('cookie_visited', 'false');
        const newQueryParams = new URLSearchParams(currentQueryParams);
        newQueryParams.set(
            'selLang',
            localStorage.getItem('user-selected-language')?.split('-')?.[0] || 'en'
        );
        setSearchParams(newQueryParams);
        window.dispatchEvent(new CustomEvent('load'));
    };

    if (
        ['termsandconditions', 'privacypolicy'].includes(
            location.pathname.split('/')[1]
        )
    ) {
        return null;
    }

    const isResultPage = location.pathname.split('/')?.[2] === 'result';

    return (
        <div className={clsx(styles.wrap, 'wrap', isCameraOn && styles.hide)}>
            <p
                aria-label={t('aria_label_t_c_p_p')}
                className={clsx(styles.links, {
                    [styles.verticalAlignment]:
                       (isGerman || isSpanish || isItalian || isFrench),
                })}
            >
                <ReactLink
                    to={'termsandconditions'}
                    title={t('terms_conditions')}
                    id="open-terms-conditions-button"
                    state={{
                        from: location.pathname,
                        prevState: location.state,
                    }}
                    target="_blank"
                >
                    {t('terms_conditions')}
                </ReactLink>
                <ReactLink
                    id="open-privacy-policy-button"
                    to={'/privacypolicy'}
                    title={t('privacy_policy')}
                    state={{
                        from: location.pathname,
                        prevState: location.state,
                    }}
                    target="_blank"
                >
                    {t('privacy_policy')}
                </ReactLink>
                <a
                    href="#"
                    id="open-privacy-policy-button"
                    onClick={handleClickCookieConsent}
                    title={t('cookie_consent')}
                >
                    {t('cookie_consent')}
                </a>
            </p>
        </div>
    );
};

export default TermsLinks;
