export default function EsText() {
    return (
        <ol className="decimal">
            <li>RESUMEN </li>
            <br />
            <p>
                En
                <b>Qatar Airways Group Q.C.S.C.</b>, (“<b>Qatar Airways</b>”, “
                <b>nosotros</b>”, “<b>nuestro</b>”, “<b>nosotros</b>”) ”)
                queremos que usted se sienta seguro al usar este sitio web
                (“Microsite”), por eso nos comprometemos a proteger sus Datos
                personales y su derecho a la privacidad cuando lo haga.
            </p>
            <p>
                El presente Aviso sobre protección de datos de Flyqatar.com (“
                <b>Aviso de protección de datos</b>”) (junto con nuestras{' '}
                <a
                    target="_blank"
                    rel="noreferrer"
                    href="/termsandconditions?selLang=es"
                >
                    Condiciones
                </a>{' '}
                y nuestra{' '}
                <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.qatarairways.com/es-es/legal/cookie-policy.html"
                >
                    Política sobre cookies
                </a>{' '}
                explican la forma en la que Qatar Airways y nuestros proveedores
                de servicios tratan o tratarán los Datos personales. Por “
                <b>Datos personales</b>” nos referimos a cualquier información
                relativa a una persona física identificada o identificable.
            </p>
            <p>
                Le rogamos que lea atentamente lo siguiente para comprender cómo
                tratamos sus Datos personales en este Microsite. Cuando revele
                Datos personales en nombre de otra persona, se compromete y se
                asegurará de que la persona cuyos Datos personales se facilitan
                a Qatar Airways ha autorizado la revelación y ha recibido una
                copia de este Aviso de privacidad antes de revelar dichos Datos
                personales.
            </p>
            <p>
                Si modificamos este Aviso de privacidad, le informaremos de los
                cambios publicando la versión actualizada en nuestro Microsite.
                Sujeto a las leyes aplicables, la versión en inglés de este
                Aviso de privacidad prevalecerá sobre cualquier versión de este
                Aviso de privacidad traducida a otro idioma.
            </p>
            <li>¿CUÁNDO SE APLICA ESTE AVISO DE PRIVACIDAD?</li>
            <br />
            <p>
                Este Aviso de privacidad se aplica al tratamiento de Datos
                personales en este Microsite.
            </p>
            <li>
                ¿QUIÉN ES EL RESPONSABLE DEL TRATAMIENTO DE LOS DATOS
                PERSONALES?{' '}
            </li>
            <br />
            <p>
                Qatar Airways, con domicilio social en “
                <b>
                    Qatar Airways Tower 1, PO Box 22550, Doha, Estado de Qatar
                </b>
                ”, es responsable en calidad de controlador del tratamiento de
                los Datos personales descritos en el presente Aviso de
                protección de datos.
            </p>
            <li>CÓMO RECOPILAMOS Y TRATAMOS LOS DATOS PERSONALES </li>
            <br />
            <p>
                Nosotros y nuestros proveedores de servicios podemos recopilar y
                recibir Datos personales directamente de usted de diversas
                formas, entre las que se incluyen:
            </p>
            <p>
                <u>A través del Microsite</u>: podemos recopilar Datos
                personales a través del Microsite. Por ejemplo, cuando usted
                utiliza los servicios proporcionados en el Microsite.
                <br />
                <u>Fuera de línea</u>: en lugares específicos, como centros
                comerciales, podemos recopilar sus Datos personales en función
                de su interés y voluntad de utilizar los servicios prestados en
                el Microsite. Por ejemplo, si permite que le hagamos fotos a
                través de nuestros dispositivos oficiales y que las carguemos en
                el Microsite para recibir vídeos personalizados en los que
                aparezca usted.
                <br />
                Nuestro Microsite puede contener enlaces que le permitan visitar
                sitios web de terceros. Tenga en cuenta que no tenemos ningún
                control sobre la forma en que los controladores responsables de
                estos sitios web de terceros procesan sus Datos personales y no
                somos responsables de la forma en que operan o de la forma en
                que procesan sus Datos personales. Es importante que lo entienda
                y compruebe sus respectivas políticas de privacidad y
                condiciones de uso de los sitios web.
            </p>
            <li>QUÉ DATOS PERSONALES SE RECOPILAN</li>
            <br />
            <p>
                “<b>Datos personales</b>” son informaciones que le identifican
                como individuo o se refieren a un individuo identificable,
                incluyendo:
                <br />
                <ol className="roman">
                    <li>Imágenes y fotografías, y</li>
                    <li>Dirección de correo electrónico.</li>
                </ol>
            </p>
            <p>
                Es posible que necesitemos recopilar y procesar estos tipos de
                Datos personales para permitirle crear una película de acuerdo
                con nuestras Condiciones. Si no facilita la información que le
                solicitamos, es posible que no pueda participar en la creación
                de una película en nuestro Microsite.
            </p>
            <li>
                ¿CUÁL ES LA FINALIDAD DE LA RECOPILACIÓN DE DATOS PERSONALES?
            </li>
            <br />
            <p>
                Nosotros y nuestros proveedores de servicios podemos utilizar
                los Datos personales basándonos en su consentimiento.
            </p>
            <p>
                Su imagen o fotografía puede ser utilizada para crear una
                película/vídeo según su elección y permitirle participar y
                utilizar los servicios proporcionados en este Microsite. En
                lugares fuera de línea, si usted da su consentimiento, su imagen
                o fotografía y la película/vídeo relacionados podrán mostrarse
                temporalmente en nuestra cartelera digital situada en el mismo
                lugar.
            </p>
            <p>
                Su imagen o fotografía puede ser utilizada para crear una
                película/vídeo según su elección y permitirle participar y
                utilizar los servicios proporcionados en este Microsite. En
                lugares fuera de línea, si usted da su consentimiento, su imagen
                o fotografía y la película/vídeo relacionados podrán mostrarse
                temporalmente en nuestra cartelera digital situada en el mismo
                lugar.
            </p>
            <p>
                Cuando se suscribe a nuestro boletín de noticias, utilizamos su
                dirección de correo electrónico para enviarle actualizaciones a
                través de boletines. Siempre puede optar por dejar de recibirlos
                mediante el enlace para darse de baja incluido en cada boletín.
            </p>
            <li>TOMA DE DECISIONES AUTOMATIZADA </li>
            <br />
            <p>
                Empleamos inteligencia artificial (“<b>IA</b>”) para poder
                generar sus películas y vídeos personalizados.
            </p>
            <p>
                La tecnología de intercambio de caras utiliza algoritmos
                avanzados de aprendizaje automático e inteligencia artificial
                para identificar y sustituir la cara de una persona por otra en
                imágenes o vídeos. El proceso suele consistir en la detección,
                extracción y síntesis de rostros. En primer lugar, el algoritmo
                detecta los rostros en el contenido, después analiza los rasgos
                faciales clave y, por último, mezcla el rostro sustituido en la
                imagen o el vídeo original adaptando el tono de la piel, la
                iluminación y las expresiones para garantizar una transición
                perfecta.
            </p>
            <li>COOKIES</li>
            <br />
            <p>
                Las cookies son pequeños archivos de información que guardan y
                recuperan información sobre su visita a nuestro Microsite, por
                ejemplo, cómo entró en nuestro sitio, cómo navegó por el sitio y
                qué información le resultó de interés. Lea más sobre cómo
                utilizamos las cookies en nuestra{' '}
                <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.qatarairways.com/es-es/legal/cookie-policy.html"
                >
                    política de cookies
                </a>
                .
            </p>
            <li>TRANSFERENCIAS DE DATOS </li>
            <br />
            <p>
                <u>DENTRO DE QATAR AIRWAYS GROUP </u>
            </p>
            <p>
                El personal de Qatar Airways está autorizado a acceder a los
                Datos personales sólo si y en la medida en que sea necesario
                para servir al propósito aplicable y para realizar su trabajo.
            </p>
            <p>
                Como parte de una organización global con sede en Doha, Estado
                de Qatar, los Datos personales que recopilamos pueden ser
                transferidos internacionalmente a través de Qatar Airways Group
                con fines comerciales o administrativos a países en los que
                Qatar Airways está establecida y cuyas leyes de protección de
                datos pueden no ser tan amplias como las del Espacio Económico
                Europeo ("EEE"). Cuando sea necesario, Qatar Airways se
                asegurará de que existan las garantías adecuadas para cumplir
                con los requisitos para la transferencia internacional de datos
                personales en virtud de las leyes de protección de datos y
                privacidad aplicables.
            </p>
            <p>
                <u>FUERA DE QATAR AIRWAYS GROUP</u>
                <br />
                Podemos vernos obligados a divulgar determinados Datos
                personales en los siguientes casos:
            </p>
            <ol className="alpha">
                <li>
                    a terceros, incluidos proveedores de servicios que nos
                    presten un servicio o realicen una función para nosotros o
                    que sean designados por nosotros en relación con los
                    servicios que le ofrecemos, incluidos aquellos que actúen
                    como nuestros agentes, que incluyen, entre otros,
                    proveedores de servicios de apoyo, proveedores de
                    tecnología, consultores especializados, asesores jurídicos,
                    auditores;
                </li>
                <li>
                    a terceros en relación con la financiación, la titulización,
                    el aseguramiento, la reestructuración, la cesión u otro tipo
                    de enajenación de todo o parte de nuestro negocio o activos
                    o en relación con la defensa de reclamaciones; y
                </li>
                <li>
                    a las autoridades oficiales, incluidos los funcionarios
                    encargados de hacer cumplir la ley, los tribunales y las
                    autoridades gubernamentales y reguladoras: (a) si creemos
                    que la divulgación es requerida por cualquier ley,
                    reglamento o proceso legal aplicable (como para responder a
                    citaciones u órdenes judiciales); o (b) para proteger y
                    defender nuestros derechos o los derechos o la seguridad de
                    terceros, incluida la defensa contra reclamaciones legales.
                </li>
            </ol>
            <p>
                Cuando se comuniquen Datos personales a terceros, adoptaremos
                las medidas contractuales, técnicas y organizativas necesarias
                para proteger debidamente dichos Datos personales de conformidad
                con la legislación aplicable. Las divulgaciones antes
                mencionadas pueden implicar la transferencia de Datos personales
                a y desde países fuera de su país de residencia, incluyendo a y
                desde países cuyas leyes de protección de datos pueden no ser
                tan extensas como las del EEE. Cuando sea necesario, Qatar
                Airways se asegurará de que se establezcan las salvaguardas
                adecuadas para cumplir con los requisitos para la transferencia
                internacional de datos personales en virtud de la legislación
                aplicable en materia de protección de datos y privacidad.
            </p>
            <li>¿CÓMO PROTEGEMOS SU INFORMACIÓN PERSONAL?</li>
            <br />
            <p>
                Qatar Airways ha puesto en marcha medidas para salvaguardar los
                Datos personales, ya que nos comprometemos a garantizar la
                seguridad de los mismos. En un esfuerzo por evitar el acceso no
                autorizado a los Datos Personales o su divulgación, hemos
                establecido salvaguardas físicas, técnicas y administrativas
                para proteger los Datos personales contra la destrucción
                accidental o ilícita o la pérdida accidental, el daño, la
                alteración, la divulgación o el acceso no autorizados, así como
                cualquier otra forma de tratamiento ilícito.
                <br />
                Aunque realizamos esfuerzos razonables y adecuados para proteger
                los Datos personales, no podemos garantizar la seguridad de los
                Datos personales transmitidos a través de Internet o de una
                conexión similar. Si tiene motivos para creer que su interacción
                con nosotros ya no es segura, notifíquenoslo inmediatamente de
                acuerdo con la sección “<b>Comuníquese con nosotros</b>” a
                continuación.
            </p>
            <li>USO DEL Microsite POR PARTE DE MENORES DE EDAD</li>
            <br />
            <p>
                Este Microsite no está dirigido a personas menores de dieciocho
                (18) años y no recopilamos a sabiendas Datos personales de
                personas menores de 18 años.
            </p>
            <li>CONSERVACIÓN DE DATOS</li>
            <br />
            <p>
                Conservaremos los Datos personales durante el tiempo que sea
                necesario para cumplir con la finalidad especificada para la que
                fueron recogidos, los fines legales o comerciales de Qatar
                Airways, tal y como se describe en este Aviso de privacidad y en
                línea con nuestras políticas de retención de datos o según lo
                exija la legislación pertinente. Al borrar los Datos personales,
                tomaremos las medidas comercialmente razonables y técnicamente
                posibles para que los Datos personales sean irrecuperables o
                irreproducibles de acuerdo con las leyes aplicables.
            </p>
            <li>USO DEL Microsite POR PARTE DE MENORES DE EDAD</li>
            <br />
            <p>
                Este Microsite no está dirigido a personas menores de dieciocho
                (18) años y no recopilamos a sabiendas Datos personales de
                personas menores de 18 años.
            </p>
            <li>SUS DERECHOS </li>
            <br />
            <p>
                Usted puede ejercer sus derechos de acceso, rectificación,
                supresión, restricción del tratamiento, portabilidad de datos,
                objeción o derechos en relación con la toma de decisiones
                individuales automatizadas, incluida la elaboración de perfiles,
                en relación con los Datos personales que Qatar Airways procesa
                en cualquier momento, enviando una solicitud a través de el
                portal para ‘
                <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.qatarairways.com/es-es/subject-access-request-form.html"
                >
                    solicitudes de acceso de los interesados
                </a>
                ’ en la página de Contacto en qatarairways.com.qa. Usted puede
                tener otros derechos de privacidad, incluidos los derechos en
                virtud de la Ley de privacidad del consumidor de California (“
                <b>CCPA</b>”), dependiendo de su país de residencia
            </p>
            <p>
                Antes de proceder a su solicitud, verificaremos su identidad
                para asegurarnos de que proporcionamos la información solicitada
                al sujeto de datos al que corresponde. Si ha autorizado a un
                agente a realizar una solicitud en su nombre, exigimos que usted
                o su agente autorizado nos proporcionen una copia del permiso
                escrito para presentar dicha solicitud en su nombre.
            </p>
            <p>
                No cargaremos ningún gasto por responder a su solicitud, a menos
                que ésta pueda considerarse manifiestamente infundada o
                excesiva. Si lo solicita, podemos facilitarle una lista de
                terceros encargados del tratamiento de datos personales con
                arreglo a la Ley de protección de datos personales de la
                República Popular China. Si considera que no cumplimos las
                normas de privacidad aplicables, tiene derecho a presentar una
                reclamación ante una autoridad de protección de datos
                competente.
            </p>
            <p>
                Si no desea seguir recibiendo nuestros boletines, puede darse de
                baja en cualquier momento haciendo clic en el enlace "cancelar
                suscripción" de cualquier correo electrónico que contenga
                nuestro boletín. Además, puede presentar una solicitud de
                cancelación de la suscripción enviando un correo electrónico o
                llamando a nuestro Centro de Atención al Cliente o enviándonos
                una solicitud por correo postal a la siguiente dirección:
            </p>
            <p>
                Qatar Airways Group Q.C.S.C.
                <br />
                Qatar Airways Tower 1,
                <br />
                PO Box 22550,
                <br />
                Doha, Estado de Qatar.
                <br />
                Att/ Equipo de marketing directo de Qatar Airways - Comercio
                electrónico
            </p>
            <p>
                Si solicita la baja por correo electrónico, por teléfono o por
                correo postal, puede tardar hasta 30 días laborables en procesar
                su solicitud y actualizar nuestros sistemas para eliminar su
                dirección de correo electrónico de nuestra lista de
                distribución.
            </p>
            <li>CÓMO COMUNICARSE CON NOSOTROS O PRESENTAR UNA RECLAMACIÓN</li>
            <br />
            <p>
                Si tiene alguna pregunta o duda sobre el tratamiento de sus
                Datos personales o sobre este Aviso de privacidad, póngase en
                contacto con:
            </p>
            <p>
                Correo electrónico:{' '}
                <a
                    target="_blank"
                    rel="noreferrer"
                    href="mailto:digitalbrand@qatarairways.com.qa"
                >
                    digitalbrand@qatarairways.com.qa
                </a>
            </p>
            <p>
                Qatar Airways Group Q.C.S.C.
                <br />
                Qatar Airways Tower 1,
                <br />
                PO Box 22550,
                <br />
                Doha, Estado de Qatar.
                <br />
                Att/ Departamento de Marketing.
            </p>
            <p>
                También puede ponerse en contacto con nuestro Responsable de
                protección de datos enviando un correo electrónico{' '}
                <a
                    target="_blank"
                    rel="noreferrer"
                    href="mailto:dpo@qatarairways.com.qa"
                >
                    aquí
                </a>
                . Tenga en cuenta que también tiene derecho a presentar una
                reclamación ante una autoridad de control competente.
            </p>
            <br />
        </ol>
    );
}
