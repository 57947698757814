export default function EsText() {
    return (
        <div>
            <p>
                Le rogamos que lea atentamente estos Términos y Condiciones de
                uso antes de acceder y utilizar esta aplicación web, cualquiera
                de sus servicios, funciones, materiales o contenidos (“ el
                <b>Microsite</b>”). Este Microsite es propiedad y está
                gestionado por Qatar Airways Group Q.C.S.C, una sociedad anónima
                catarí con sede en Qatar Airways Tower 1, Airport Road, Estado
                de Qatar.
            </p>
            <p>
                AL ACCEDER, UTILIZAR, VER, TRANSMITIR, ALMACENAR O GUARDAR ESTE
                MICROSITE (ai.flyqatar. com) MANIFIESTA SU ACEPTACIÓN DE QUEDAR
                OBLIGADO POR ESTAS CONDICIONES (LAS «CONDICIONES DE USO») EN
                CONSIDERACIÓN DEL ACCESO PROPORCIONADO AL MICROSITE SEGÚN SE
                ESTABLECE A CONTINUACIÓN Y GARANTIZA QUE ES MAYOR DE EDAD Y
                POSEE LA CAPACIDAD LEGAL PARA SUSCRIBIR ESTE ACUERDO Y UTILIZAR
                ESTE MICROSITE DE CONFORMIDAD CON TODAS LAS CONDICIONES DE USO
                AQUÍ EXPUESTAS. USTED ACEPTA SER FINANCIERAMENTE RESPONSABLE DE
                TODO EL USO QUE HAGA DE ESTE SITIO (ASÍ COMO DEL USO QUE OTROS
                HAGAN DE SU CUENTA).
            </p>
            <p>
                «Usted» y «Su» se refieren a las personas que acceden a este
                Microsite (incluidas las personas que acceden a este sitio en
                nombre de otras personas. «Nosotros», «Nos», «Nuestro» y
                términos similares se refieren a Qatar Airways). Si no está de
                acuerdo con estas Condiciones de uso, con cualquiera de los
                documentos relacionados enlazados a continuación o con otras
                restricciones que se le notifiquen en el transcurso de su uso de
                este Microsite, no le está permitido y se compromete a no
                utilizar ni acceder al mismo.
            </p>
            <p>
                Las presentes Condiciones de uso rigen el uso que Usted haga del
                Microsite y de todas las aplicaciones, software y servicios
                (denominados colectivamente «Servicios») disponibles en el
                mismo, salvo en la medida en que dichos Servicios sean objeto de
                un acuerdo independiente. Pueden aplicarse condiciones
                específicas al uso de determinados Servicios y otros elementos
                que se le proporcionan en el Microsite («Contratos de
                servicio»). Los términos en mayúsculas que no se definan de otro
                modo en estas Condiciones de uso tendrán el significado que se
                les atribuye en la sección Definiciones adjunta al final de
                estas Condiciones de uso.
            </p>
            <h3>Condiciones de uso</h3>
            <h3>Su uso del Microsite</h3>
            <p>
                Siempre que Usted cumpla los presentes Términos y Condiciones de
                uso, podrá enviar su imagen y semejanza mediante fotografías (“
                <b>su Contenido</b>”) al Microsite para su inclusión en la
                película de vídeo disponible en el Microsite (“<b>Película</b>”)
                (el “<b>Propósito</b>”). La Película que incorpore su Contenido
                se denominará en las presentes Condiciones de uso Película
                modificada.
            </p>
            <p>
                Al enviar su Contenido al microsit para su inclusión en la
                Película, Usted:
            </p>
            <ol className="alpha">
                <li>
                    nos concede el derecho mundial, no exclusivo, exento de
                    derechos de autor, transferible, perpetuo, irrevocable y
                    sublicenciable a utilizar, reproducir, modificar y crear
                    obras derivadas de su Contenido con el Propósito;
                </li>
                <br />
                <li>
                    declara, garantiza y se compromete ante nosotros:
                    <ol className="roman">
                        <li>
                            Usted está en condiciones de concedernos los
                            derechos sobre su Contenido a los efectos;
                        </li>
                        <li>
                            Usted tiene y seguirá teniendo todos los derechos,
                            títulos e intereses sobre su Contenido necesarios
                            para que nosotros utilicemos su Contenido para los
                            Fines;
                        </li>
                        <li>
                            el uso que hagamos de su Contenido de conformidad
                            con estas Condiciones de uso y en la forma prevista
                            o permitida por las mismas no infringirá los
                            derechos de propiedad intelectual de terceros ni
                            incumplirá la legislación aplicable; y
                        </li>
                        <li>
                            ni Qatar Airways, ni ninguna otra persona que actúe
                            bajo la dirección o con el permiso de Qatar Airways
                            (incluidos los miembros del Grupo Qatar Airways),
                            estará obligada a pagar a terceros ninguna licencia
                            ni ningún otro derecho en relación con el uso de su
                            Contenido de conformidad con y en la forma prevista
                            o permitida por las presentes Condiciones de uso.
                        </li>
                    </ol>
                </li>
            </ol>
            <br />
            <p>
                Deberá notificarnos tan pronto como sea posible cualquier
                infracción, sospecha o presunta infracción por parte de su
                Contenido de los derechos de propiedad intelectual de terceros y
                cooperará plenamente con nosotros en cualquier acción legal que
                emprendamos para hacer valer nuestros derechos de propiedad
                intelectual.
            </p>
            <p>
                Sin perjuicio del cumplimiento por su parte de las presentes
                Condiciones de uso, le concedemos un derecho mundial, limitado,
                personal, revocable, libre de regalías, intransferible y no
                sublicenciable a utilizar, descargar, reproducir, cargar,
                transmitir, traducir y modificar la Película modificada
                únicamente para sus fines personales (y no para fines
                comerciales de ningún tipo).
            </p>
            <p>
                Nos reservamos todos los derechos que no se le concedan
                expresamente en las presentes Condiciones de uso.
            </p>
            <h3>Licencia y titularidad: Qatar Airways</h3>
            <p>
                Todos y cada uno de los Derechos de propiedad intelectual
                asociados con el Microsite y su contenido, incluyendo la
                Película y la Película modificada (el "<b>Contenido</b>") son
                propiedad exclusiva de Qatar Airways Group Q.C.S.C o de
                terceros. El Contenido de nuestro Microsite está protegido por
                la legislación aplicable en materia de derechos de autor y otras
                leyes.
            </p>
            <p>
                Los elementos del Microsite también están protegidos por las
                leyes de secreto comercial, competencia desleal y otras y no
                pueden ser copiados ni imitados total o parcialmente. Todos los
                gráficos personalizados, iconos y otros elementos que aparecen
                en el Microsite son marcas comerciales o marcas de servicio
                («Marcas») de Qatar Airways, sus filiales u otras entidades que
                han concedido a Qatar Airways el derecho y la licencia para
                utilizar dichas Marcas y no pueden ser utilizadas ni
                interferidas de ninguna manera sin el consentimiento expreso y
                por escrito de Qatar Airways.
            </p>
            <p>
                Salvo que las presentes Condiciones de uso autoricen
                expresamente lo contrario, Usted no podrá copiar, reproducir,
                modificar, arrendar, prestar, vender, crear trabajos derivados,
                cargar, transmitir ni distribuir la Propiedad intelectual del
                Microsite en modo alguno sin la previa autorización por escrito
                de Qatar Airways o del tercero correspondiente. Salvo en los
                casos expresamente previstos en el presente documento, Qatar
                Airways no le concede ningún derecho expreso ni implícito sobre
                los Derechos de propiedad intelectual de Qatar Airways o de
                terceros.
            </p>
            <p>
                Sin perjuicio del resto de condiciones incluidas en las
                presentes Condiciones de uso, Qatar Airways le concede una
                licencia limitada, personal, intransferible, no sublicenciable y
                revocable para (a) acceder y utilizar únicamente el Microsite,
                el Contenido y los Servicios sólo en la forma presentada por
                Qatar Airways y (b) acceder y utilizar los servicios
                informáticos y de red de Qatar Airways ofrecidos dentro del
                Microsite (los "Sistemas de Qatar Airways") sólo en la forma
                expresamente permitida por Qatar Airways.
            </p>
            <p>
                A excepción de la licencia limitada concedida en estas
                Condiciones de uso, Qatar Airways no transmite ningún interés en
                o a los Sistemas de Qatar Airways, información ni datos
                disponibles a través de los Sistemas de Qatar Airways (la
                «Información»), Contenido, Servicios, Microsite o cualquier otra
                propiedad de Qatar Airways al permitirle acceder al Microsite.
            </p>
            <p>
                Salvo en la medida en que lo exija la ley o se establezca
                expresamente en el presente documento, ninguno de los Contenidos
                y/o Información podrá ser objeto de ingeniería inversa,
                modificación, reproducción, nueva publicación, traducción a
                cualquier idioma o lenguaje informático, retransmisión en
                cualquier forma o por cualquier medio, reventa ni redistribución
                sin el consentimiento previo por escrito de Qatar Airways. Sin
                perjuicio de lo anterior, Usted se compromete a no: (a) copiar,
                mostrar, modificar, reproducir ni transferir de cualquier otro
                modo cualquiera de los Materiales a terceros sin el
                consentimiento previo por escrito de Qatar Airways; (b)
                interferir ni interrumpir las redes conectadas al Sitio; (c)
                utilizar ni intentar utilizar cualquier dispositivo, software o
                rutina que interfiera en el correcto funcionamiento del Sitio o
                de cualquier transacción que se ofrezca a través del Sitio; (d)
                transmitir archivos, datos u otros materiales que contengan
                virus informáticos, datos corruptos, gusanos, «caballos de
                Troya» u otras instrucciones o diseños que puedan borrar datos o
                programación o hacer que el Sitio o cualquier equipo o sistema
                quede inoperativo o incapaz de ser utilizado en toda la
                extensión para la que fue diseñado; (e) enviar cualquier
                comunicación al Sitio o a través de él que infrinja cualquier
                ley local, estatal, federal o internacional; (f) enviar
                cualquier comunicación al Sitio o a través de él que contenga
                material difamatorio, calumnioso, abusivo u obsceno; (g) enviar
                cualquier comunicación al Sitio o a través de él que infrinja
                los derechos de terceros.
            </p>
            <p>
                Además, con respecto al Contenido, Su exhibición, uso,
                transmisión, reproducción o compartición de la Película
                modificada y/o Su incorporación de la Película modificada en
                cualquier contenido, Usted no deberá:
            </p>
            <ol className="alpha">
                <li>
                    hacerse pasar por nosotros, por cualquiera de nuestros
                    Afiliados, por cualquier miembro del Grupo Qatar Airways o
                    por un agente nuestro o de nuestros Afiliados o falsificar
                    de cualquier otro modo Su identidad o afiliación;
                </li>
                <li>
                    falsificar encabezados ni manipular de otro modo
                    identificadores (incluidas las URL) con el fin de engañar o
                    potencialmente engañar a terceros en cuanto a Su identidad o
                    afiliación con nosotros;
                </li>
                <li>
                    participar en actividades que violen cualquier relación
                    fiduciaria, cualquier ley local, estatal, nacional o
                    internacional aplicable o cualquier normativa con fuerza de
                    ley;
                </li>
                <li>
                    transmitir, enviar spam o cargar, publicar ni transmitir de
                    cualquier otro modo la Película modificada de forma
                    inexacta, dañina, obscena, difamatoria, racista u ofensiva
                    para otros;
                </li>
                <li>
                    hacer cualquier cosa ni omitir hacer cualquier cosa que
                    pudiera ser perjudicial para nuestros intereses o los del
                    Grupo Qatar Airways, según determinemos a nuestra única y
                    absoluta discreción;
                </li>
                <li>
                    eliminar cualquier aviso de propiedad de la Película
                    modificada;{' '}
                </li>
                <li>
                    utilizar la Película modificada de cualquier manera o para
                    cualquier fin que infrinja, se apropie indebidamente o
                    infrinja de cualquier otro modo cualquier Derecho de
                    propiedad intelectual u otro derecho de cualquier persona, o
                    que infrinja cualquier ley aplicable;
                </li>
                <li>
                    acceder a todo o parte del Contenido para construir un
                    producto y/o servicio que compita con el Contenido o con
                    cualquier aspecto del mismo; o
                </li>
                <li>
                    explotar comercialmente, vender, conceder licencias ni
                    distribuir cualquiera de los Contenidos.
                </li>
            </ol>
            <br />
            <p>
                Además, Usted deberá cumplir las demás directrices adicionales
                con respecto a la Película modificada que podamos publicar
                periódicamente.
            </p>
            <h3>Información sobre derechos de autor y marcas comerciales</h3>
            <p>
                Todo el material contenido en este Microsite, así como el código
                y los datos del software, está protegido por derechos de autor,
                marcas comerciales y otros derechos de propiedad intelectual.
                Dichos materiales son propiedad de QATAR AIRWAYS GROUP Q.C.S.C o
                se utilizan con permiso de sus propietarios o de cualquier otra
                forma autorizada por la ley. Los materiales protegidos incluyen,
                entre otros, texto, marcas registradas, código fuente, bases de
                datos, logotipos, fotografías, imágenes, clips de audio y de
                vídeo, diseños, imagen comercial y otros materiales contenidos
                en este Microsite. Todos los derechos reservados en todo el
                mundo.
            </p>
            <p>
                Salvo en los casos expresamente permitidos en las presentes
                Condiciones de uso, Usted no podrá descargar, imprimir ni
                reproducir partes del Contenido para su uso y disfrute personal
                y no comercial y queda estrictamente prohibido cualquier otro
                uso de cualquier Contenido (incluyendo, sin limitación,
                fotografías, artículos, comunicados de prensa, software,
                comentarios, tablas o gráficos, videoclips, logotipos) en este
                Microsite, sitio para móviles y aplicaciones para móviles. A
                título ilustrativo, dicho uso prohibido puede incluir la
                extracción, redistribución, republicación, modificación,
                difusión, venta o puesta a disposición de terceros de cualquier
                información o material de este Microsite (en cualquier formato).
                Además de esta restricción, Usted se compromete a respetar todos
                los avisos de derechos de autor, información o restricciones
                contenidas en cualquier material al que se acceda a través de
                este Microsite. El uso no autorizado de este Microsite, del
                sitio móvil y de las aplicaciones móviles o de cualquier
                información o material contenido en este Microsite, o de
                cualquier información o material contenido en este Microsite es
                ilegal y, como tal, puede dar lugar, entre otras cosas, a una
                reclamación por daños y perjuicios y también puede constituir un
                delito.
            </p>
            <h3>Acciones o usos no autorizados</h3>
            <p>
                Usted acepta que el uso de este Microsite es personal para usted
                y que este Microsite se proporciona únicamente para permitirle
                ver y comprar nuestros productos y servicios y para ningún otro
                propósito. En particular, usted acepta que no hará ninguna
                reserva no autorizada, falsa o fraudulenta y que tampoco
                intentará interferir con el funcionamiento de este Microsite, ni
                alterar o corromper ningún dato o software.
            </p>
            <p>
                Usted es responsable de mantener la confidencialidad de la
                información de acceso al Microsite, como nombre de usuario,
                contraseña y número de identificación personal, así como los
                datos de reserva, como el número de referencia o el itinerario
                del viaje.
            </p>
            <p>
                Podrá optar por permitir que familiares, amigos o colegas
                accedan en Su nombre al Microsite, proporcionando información de
                acceso al mismo, así como a los datos de la reserva a dichas
                personas, siempre y cuando:
            </p>
            <ol className="alpha">
                <li>
                    usted obtuviese el consentimiento de cualquier otra persona
                    cuyos datos de reserva o información personal sean
                    accesibles con los suyos, como una reserva de grupo; y
                </li>
                <li>
                    usted acepta la plena responsabilidad por los actos y
                    omisiones de dicha persona al acceder al Microsite
                    utilizando la información de acceso al mismo y/o los datos
                    de reserva que usted le proporcione, independientemente de
                    que usted haya autorizado o no tal acceso.
                </li>
            </ol>
            <br />
            <p>
                No debe permitir el acceso ni el uso de este Microsite ni de
                cualquiera de sus contenidos o los que obtenga del mismo, a
                ninguna entidad comercial ni ninguna otra organización que
                preste un servicio al público.
            </p>
            <h3>Sus compromisos</h3>
            <p>
                Además de otras restricciones establecidas en las presentes
                Condiciones de uso, Usted acepta que:
            </p>
            <ol className="alpha">
                <li>
                    no ocultará el origen de la información transmitida a través
                    del Microsite.
                </li>
                <li>
                    no utilizará ni accederá a ningún servicio, información,
                    aplicación ni software disponible a través del Microsite, en
                    ninguna forma que no esté permitida expresamente por Qatar
                    Airways.
                </li>
                <li>
                    No introducirá ni cargará en el Microsite información alguna
                    que contenga virus, troyanos, gusanos o bombas de tiempo, ni
                    ninguna otra rutina de programación informática que tuviera
                    por objeto perjudicar, interferir, interceptar ni enajenar
                    ningún sistema, el Microsite ni la Información o que
                    infrinja cualquier Derecho de propiedad intelectual de
                    otros.
                </li>
                <li>
                    Algunas áreas del Microsite están restringidas a los
                    clientes de Qatar Airways.
                </li>
                <li>
                    usted no podrá utilizar ni acceder al Microsite ni a los
                    sistemas o servicios de Qatar Airways de ninguna manera que,
                    a juicio de Qatar Airways, afecte negativamente al
                    rendimiento o el funcionamiento de los sistemas, de los
                    servicios o del Microsite de Qatar Airways o que interfiera
                    con la capacidad de las partes autorizadas para acceder a
                    los sistemas, a los servicio o al Microsite de Qatar
                    Airways.
                </li>
                <li>
                    usted no podrá integrar mediante marcos, ni utilizar
                    técnicas de integración con marcos, para incorporar
                    cualquier parte o aspecto del contenido o de la información,
                    sin el consentimiento expreso por escrito de Qatar Airways.
                </li>
            </ol>
            <br />
            <p>
                Usted acepta seguir y respetar las presentes Condiciones, así
                como toda instrucción y procedimiento establecido en este
                Microsite, entendiendo usted que el incumplimiento de todos
                ellos podrá derivar en la obligación de contratar bienes y
                servicios no deseado, la pérdida de sus productos o servicios,
                la denegación de acceso a los productos y servicios de este
                Microsite, así como posibles acciones legales en su contra.
            </p>
            <p>Usted reconoce y acepta que sigue siendo responsable de:</p>
            <ol className="alpha">
                <li>
                    evaluar y asumir todos los riesgos asociados al uso del
                    Microsite y su Contenido;
                </li>
                <li>
                    garantizar una protección y una copia de seguridad adecuadas
                    de Su contenido y/o de los sistemas utilizados por usted en
                    relación con el acceso y el uso del Microsite;
                </li>
                <li>
                    <b>
                        su uso del Microsite y del Contenido, tanto si dicho
                        acceso o uso está permitido por las presentes
                        Condiciones de uso como si las incumple, incluido el uso
                        con cualquier aplicación para móviles o software de
                        terceros.
                    </b>
                </li>
            </ol>
            <br />
            <h3>Exclusiones y limitaciones de responsabilidad</h3>
            <ol className="alpha">
                <li>
                    Si bien la Información del producto y el resto del material
                    del Microsite, Contenido, sitio para móviles y aplicaciones
                    para móviles se proporcionan de buena fe, al utilizar este
                    Microsite, Contenido, sitio para móviles y aplicaciones para
                    móviles, Usted acepta y reconoce que Qatar Airways no hace
                    ninguna declaración, garantía ni reclamación en cuanto a la
                    exactitud de la Información del producto o cualquier otro
                    material de este Microsite, su Contenido, sitio para móviles
                    y aplicaciones para móviles. No declaramos ni garantizamos
                    que el uso que Usted haga del Microsite sea ininterrumpido
                    ni esté libre de errores, ni que el Microsite o su Contenido
                    estén libres de Código dañino. Además, Usted acepta que la
                    Información del producto y el Microsite, su Contenido, el
                    sitio para móviles y el material de las aplicaciones para
                    móviles no constituyen ninguna forma de asesoramiento ni
                    recomendación para Usted.
                </li>
                <li>
                    Qatar Airways descarta plenamente cualquier garantía
                    implícita relacionada con el uso de este Microsite y su
                    contenido, del sitio para móviles y de las aplicaciones para
                    móviles, así como de la información, productos y servicios
                    contenidos en este Microsite y su Contenido, en el sitio
                    para móviles y en las aplicaciones para móviles. En la
                    medida de lo permitido por la ley, toda la información en
                    este Microsite y su Contenido, sitio para móviles y en las
                    aplicaciones para móviles se ofrece sin ninguna garantía
                    expresa ni implícita por ley ni en ningún otro modo y sin
                    ningún término implícito de ningún tipo, incluyendo entre
                    otros garantías y términos tácitos de calidad o idoneidad
                    para un propósito particular satisfactorios o de no
                    infracción de los derechos de terceros.
                </li>
                <li>
                    Al utilizar este Microsite y su Contenido, el sitio para
                    móviles y las aplicaciones para móviles, Usted acepta que
                    Qatar Airways no será responsable por ninguna pérdida
                    directa, indirecta ni consecuente, ni por ninguna otra
                    pérdida derivada del uso (o no uso) de la información,
                    productos y servicios incluidos en este Microsite y su
                    Contenido, en el sitio para móviles y en las aplicaciones
                    para móviles o del acceso a otro material a través de
                    enlaces desde este Microsite y su Contenido o desde el sitio
                    para móviles o las aplicaciones para móviles. En la máxima
                    medida permitida legalmente, se aplicará toda exclusión y
                    limitación contenida en las presentes Condiciones.
                </li>
                <li>
                    Qatar Airways no garantiza que el uso de este Microsite y su
                    Contenido, del sitio para móviles y de las aplicaciones para
                    móviles sea compatible con todo el hardware y software que
                    los visitantes del sitio puedan utilizar.
                </li>
                <li>
                    Salvo lo dispuesto en los incisos f) y g), Qatar Airways no
                    tendrá responsabilidad alguna ante usted, ya sea por
                    contrato, agravio (incluyendo negligencia), incumplimiento
                    de obligaciones legales, restitución ni cualquier otra forma
                    de lesión, muerte, daño o pérdida directa, indirecta o
                    consecuente (estos tres términos incluyen, entre otros,
                    pérdidas puramente económicas, lucro cesante, pérdidas de
                    negocio, reducción de plusvalía y pérdidas similares),
                    independientemente de los motivos que la origine o en
                    relación con el uso de este Microsite y su Contenido, del
                    sitio para móviles y de las aplicaciones para móviles o el
                    uso de, acceso a, descarga de o confianza en cualquier
                    información u otro material incluido en este Microsite y su
                    Contenido, en el sitio para móviles y en las aplicaciones
                    para móviles, incluyendo entre otros aquellos derivados de
                    cualquier virus informático.
                </li>
                <li>
                    Las presentes Condiciones no excluyen la responsabilidad de
                    Qatar Airways, en su caso, por lesiones personales o
                    fallecimiento derivados de la negligencia de Qatar Airways,
                    por fraude o por cualquier otra cuestión para la cual fuere
                    ilegal que Qatar Airways eludiese o tratase de eludir su
                    responsabilidad.
                </li>
                <li>
                    En virtud de lo dispuesto en el inciso f), la plena
                    responsabilidad de Qatar Airways respecto de cualquier
                    cuestión o cuestiones derivadas de o relacionadas con la
                    provisión de este Microsite y su Contenido, del sitio para
                    móviles y de las aplicaciones para móviles por parte de
                    Qatar Airways, así como de cualquier servicio de Qatar
                    Airways u otros por cualquier medio, quedará limitada a 1000
                    USD en total, para cada evento o serie de eventos
                    conectados.
                </li>
                <li>
                    Usted eximirá de cualquier responsabilidad, tanto a Qatar
                    Airways como a sus licenciatarios, y los mantendrá indemnes
                    frente a cualquier pérdida, daño o perjuicio, reclamación y
                    coste, incluyendo los legales, causado o soportado por parte
                    de Qatar Airways o de sus licenciatarios, derivados del
                    incumplimiento de cualquiera de las presentes Condiciones
                    por parte de usted.
                </li>
            </ol>
            <br />
            <h3>Protección de datos y cookies</h3>
            <p>
                Qatar Airways se compromete a proteger su privacidad y a cumplir
                la legislación aplicable en materia de protección de datos y
                privacidad, así como la protección de sus Datos personales. Por
                «Datos personales» entendemos cualquier información relativa a
                una persona física identificada o identificable. Los Datos
                personales serán tratados de acuerdo con nuestro Aviso de
                privacidad disponible
                <a
                    target="_blank"
                    rel="noreferrer"
                    href="/privacypolicy?selLang=es"
                >
                    aquí
                </a>
                .
            </p>
            <p>
                Las cookies son pequeños archivos de información que guardan y
                recuperan información sobre su visita a nuestro Microsite, por
                ejemplo, cómo entró en nuestro sitio, cómo navegó por él y qué
                información le interesó. Puede obtener más información sobre
                cómo utilizamos las cookies en nuestra Política de cookies, que
                está disponible en nuestro Microsite
                <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.qatarairways.com/es-es/legal/cookie-policy.html"
                >
                    aquí
                </a>
                .
            </p>
            <h3>Enlaces</h3>
            <p>
                Queda terminantemente prohibido cualquier enlace a este
                Microsite, sin nuestro consentimiento por escrito, el cual podrá
                revocarse en cualquier momento. A cualquier Microsite u otro
                dispositivo que enlace con http://www.qatarairways.com o
                cualquier página disponible en él, se le prohibirá:
            </p>
            <ol className="alpha">
                <li>reproducir su contenido,</li>
                <li>
                    emplear un navegador o un entorno fronterizo alrededor del
                    contenido,
                </li>
                <li>
                    insinuar o dar a entender en modo alguno que Qatar Airways o
                    cualquiera de sus filiales respaldan a dicho sitio o a sus
                    productos,
                </li>
                <li>
                    falsear cualquier hecho o situación, incluyendo su relación
                    con Qatar Airways o con cualquiera de sus filiales,
                </li>
                <li>
                    ofrecer información engañosa acerca de los productos y
                    servicios de Qatar Airways, y
                </li>
                <li>
                    usar cualquier logotipo o marca de Qatar Airways o de
                    cualquiera de sus filiales sin el permiso expreso por
                    escrito de Qatar Airways.
                </li>
            </ol>
            <br />
            <p>
                El Microsite podrá contener enlaces a Microsites y recursos de
                terceros (en adelante y de forma colectiva, los «Sitios
                enlazados»). Tales Sitios enlazados se proporcionan únicamente
                para Su conveniencia y no como una aprobación por parte de Qatar
                Airways del Contenido de los mismos. Qatar Airways no asume ni
                ofrece en ningún modo garantía alguna con respecto a la
                exactitud, precisión, rendimiento o calidad de cualquier
                contenido, software, servicio o aplicación que se encuentre en
                cualquier Sitio enlazado. Qatar Airways no es responsable de la
                disponibilidad de los Sitios enlazados, ni del Contenido o
                actividades de los mismos. En caso de que Usted decida acceder a
                los mismos, lo hará bajo su propio riesgo. Además, el uso de los
                Sitios enlazados queda sujeto a cualquier política aplicable,
                así como a las condiciones de uso, incluyendo entre otras las
                políticas de privacidad y de cookies del Sitio enlazado.
            </p>
            <h3>Modificación de las condiciones</h3>
            <p>
                Las presentes Condiciones de uso, así como otros servicios de
                información, productos y contenidos ofrecidos en este Microsite
                podrán ser objeto de actualización, modificación, cambio o
                eliminación por parte de Qatar Airways, en cualquier momento y
                sin previo aviso. Al utilizar este Microsite, se considera que
                usted ha aceptado estar obligado por la versión más reciente de
                las mismas.
            </p>
            <h3>Varios</h3>
            <p>
                Salvo que se disponga expresamente en un acuerdo aparte entre
                usted y Qatar Airways, las presentes condiciones de uso
                sustituirán a todo contrato, acuerdo, representación (no
                declarada fraudulenta), término implícito legalmente y
                entendimiento entre las partes, ya sean por escrito o de forma
                verbal, derivados de los usos y costumbres, o de otro tipo, y
                que guarden relación con el objeto del presente documento. Las
                presentes condiciones de uso están redactadas en inglés. Las
                partes declaran haber exigido que las presentes condiciones de
                uso, así como cualquier documento relacionado con las mismas, ya
                sean presentes o futuros, se redacten exclusivamente en inglés.
            </p>
            <h3>Divisibilidad y renuncia</h3>
            <p>
                En caso de que cualquier sección de las presentes condiciones de
                uso se declarase ilegal, no válida o inaplicable por parte de un
                tribunal, órgano administrativo o autoridad jurisdiccionalmente
                competente, dicha disposición se separará, en la medida en que
                sea necesario, de las presentes condiciones de uso y será
                ineficaz, sin que ello modifique, en la medida de lo posible,
                ninguna otra parte de las presentes condiciones de uso, no
                afectando ello a ninguna otra disposición de las condiciones de
                uso modificadas, que seguirán en pleno vigor y efecto.
            </p>
            <p>
                No habrá renuncia a ningún término, disposición o requisito de
                las presentes condiciones, salvo que dicha renuncia se presente
                por escrito y sea firmada por la parte que la concede. No habrá
                renuncia a ningún término, disposición o requisito de las
                presentes condiciones de uso, salvo que dicha renuncia se
                presente por escrito y sea firmada por la parte que la concede.
            </p>
            <h3>Legislación aplicable y jurisdicción</h3>
            <p>
                Las Condiciones de uso de este Microsite se regirán e
                interpretarán de conformidad con las leyes del Estado de Qatar.
                Los tribunales de Qatar tendrán jurisdicción no exclusiva para
                resolver cualquier disputa que se pudiera derivar como
                resultado. En la medida en que la ley lo permita, usted acepta
                que Qatar Airways se reserve el derecho de emprender acciones
                legales contra usted en cualquier país que considere oportuno.
            </p>
            <h3>Termination</h3>
            <p>
                Usted acepta que Qatar Airways, a su entera discreción, podrá
                terminar o suspender su uso del Microsite y de los Sistemas,
                Información, Servicios y Contenidos de Qatar Airways en
                cualquier momento y por cualquier motivo o sin él, aun cuando se
                siga permitiendo el acceso y uso a otros. Tras dicha suspensión
                o terminación, deberá: (a) interrumpir inmediatamente el uso del
                Microsite y (b) destruir cualquier copia realizada de cualquier
                parte del Contenido. El acceso al Microsite y a los Sistemas,
                Información o Servicios de Qatar Airways después de dicha
                terminación, suspensión o interrupción constituirá un acto de
                intrusión. Además, usted acepta que Qatar Airways no será
                responsable frente a Usted ni frente a ningún tercero por la
                terminación o suspensión de Su acceso al Microsite y a los
                Sistemas, Información y Servicios de Qatar Airways.
            </p>
            <h3>Comuníquese con nosotros</h3>
            <p>
                En caso de tener cualquier duda o pregunta acerca de nuestro
                Microsite o de nuestras Condiciones de uso, comuníquese con
                nosotros:
            </p>
            <p>
                Qatar Airways Group Q.C.S.C
                <br />
                Qatar Airways Tower
                <br />
                P.O. Box: 22550
                <br />
                Doha, Estado de Qatar
                <br />
                Email:{' '}
                <a
                    target="_blank"
                    rel="noreferrer"
                    href="mailto:qatarairways.com/contactus"
                >
                    qatarairways.com/contactus
                </a>
            </p>
            <br />
            <table>
                <tbody>
                    <tr>
                        <td colSpan={2}>Definiciones</td>
                    </tr>
                    <tr>
                        <td>Afiliados</td>
                        <td>
                            significa cualquier entidad o entidades que directa
                            o indirectamente controlen, estén controladas por o
                            estén bajo control común con, una parte;
                        </td>
                    </tr>
                    <tr>
                        <td>Código malicioso</td>
                        <td>
                            significa: (a) cualquier virus, troyano, gusano,
                            programa espía, puerta trasera, trampilla, bomba de
                            bifurcación, bomba lógica, programa malicioso o
                            servicio «drop dead»; y (b) cualquier otra rutina de
                            software destinada o diseñada para ser dañina,
                            destructiva o perturbadora, para causar fallos de
                            funcionamiento, errores, defectos, denegación o
                            degradación del servicio o pérdida, acceso no
                            autorizado, modificación, divulgación o corrupción
                            de datos.
                        </td>
                    </tr>
                    <tr>
                        <td>Derechos de propiedad intelectual</td>
                        <td>
                            se refiere a todos los derechos de propiedad
                            industrial e intelectual de cualquier tipo,
                            incluidos, entre otros, los derechos de autor
                            (incluidos los derechos sobre programas
                            informáticos), marcas comerciales, marcas de
                            servicio, diseños, patentes, secretos comerciales,
                            derechos sobre semiconductores o esquemas de trazado
                            de circuitos, nombres comerciales, empresariales, de
                            dominio o de empresa, derechos sobre Información
                            confidencial, know how u otros derechos de propiedad
                            (estén o no registrados e incluida cualquier
                            solicitud de registro) y todos los derechos o formas
                            de protección de naturaleza similar o que tengan
                            efectos equivalentes o similares a cualquiera de
                            ellos que puedan subsistir en cualquier parte del
                            mundo.
                        </td>
                    </tr>
                    <tr>
                        <td>Qatar Airways Group</td>
                        <td>
                            significa Qatar Airways Group Q.C.S.C. y cada una de
                            sus filiales, afiliados, entidades gestionadas,
                            cesionarios autorizados y sucesores.
                        </td>
                    </tr>
                </tbody>
            </table>
            <br />
        </div>
    );
}
