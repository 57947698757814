export default function EnText() {
    return (
        <ol className="decimal">
            <li>PANORAMICA</li>
            <br />
            <p>
                <b>Qatar Airways Group Q.C.S.C.</b>, (“<b>Qatar Airways</b>”, “
                <b>noi</b>”, “<b>nostro</b>” or “<b>ci</b>”)desidera che ti
                senta al sicuro quando utilizzi il presente sito Web
                (“Microsito”) e si impegna pertanto a proteggere i tuoi dati
                personali e il tuo diritto alla privacy.
            </p>
            <p>
                La presente Informativa sulla privacy di Flyqatar.com (“
                <b>Informativa sulla privacy</b>”) (insieme ai nostri{' '}
                <a
                    target="_blank"
                    rel="noreferrer"
                    href="/termsandconditions?selLang=it"
                >
                    Termini e condizioni
                </a>{' '}
                e alla nostra{' '}
                <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.qatarairways.com/it-it/legal/cookie-policy.html"
                >
                    politica sui cookie
                </a>{' '}
                spiega come i Dati personali sono o saranno trattati da Qatar
                Airways e dai nostri fornitori di servizi. Per “
                <b>Dati personali</b>” intendiamo qualsiasi informazione
                relativa a una persona fisica identificata o identificabile.
            </p>
            <p>
                Si prega di leggere attentamente quanto segue per comprendere
                come trattiamo i tuoi dati personali sul presente Microsito.
                Ogni volta che comunichi i Dati personali di un'altra persona,
                ti impegni a garantire che la persona della quale fornisci i
                Dati personali a Qatar Airways ti abbia autorizzato a farlo e
                abbia ricevuto una copia della presente Informativa sulla
                privacy prima della divulgazione da parte tua dei suddetti Dati
                personali.
            </p>
            <p>
                Ti informeremo di ogni modifica alla presente Informativa sulla
                privacy, pubblicando la versione aggiornata sul nostro Sito web.
                Fatte salve le leggi applicabili, la versione inglese della
                presente Informativa sulla privacy prevarrà su qualsiasi altra
                versione tradotta in un'altra lingua.
            </p>
            <li>QUANDO SI APPLICA QUESTA INFORMATIVA SULLA PRIVACY? </li>
            <br />
            <p>
                La presente Informativa sulla privacy si applica al trattamento
                dei dati personali sul presente Microsito.
            </p>
            <li>CHI È RESPONSABILE DEL TRATTAMENTO DEI DATI PERSONALI? </li>
            <br />
            <p>
                Qatar Airways, con sede legale in “
                <b>
                    Qatar Airways Tower 1, PO Box 22550, Doha, Stato del Qatar
                </b>
                ”, è responsabile del trattamento dei Dati personali descritti
                nella presente Informativa sulla privacy.
            </p>
            <li>COME VENGONO RACCOLTI E RICEVUTI I DATI PERSONALI </li>
            <br />
            <p>
                Noi e i nostri fornitori di servizi possiamo raccogliere e
                ricevere Dati personali direttamente da te in vari modi, tra
                cui:
            </p>
            <p>
                <u>Tramite il Microsito</u>:possiamo raccogliere Dati personali
                tramite il Microsito, ad esempio quando utilizzi i servizi
                forniti sul predetto.
                <br />
                <u>Offline</u>: in luoghi specifici, come i centri commerciali,
                potremmo raccogliere i tuoi Dati personali in base al tuo
                interesse e alla tua volontà di utilizzare i servizi forniti sul
                Microsito. Ad esempio, quando consenti che le tue foto vengano
                scattate tramite i nostri dispositivi ufficiali e caricate sul
                Microsito per ricevere video personalizzati che ti ritraggono
                <br />
                Il nostro Microsito può contenere link per consentirti di
                visitare siti Web di terze parti. Ti ricordiamo che non
                esercitiamo alcun controllo sulle modalità di trattamento dei
                tuoi Dati personali da parte dei responsabili dei suddetti siti
                Web. È importante che tu comprenda questo aspetto consultando le
                rispettive informative sulla privacy e i rispettivi termini di
                utilizzo dei siti Web.
            </p>
            <li>QUALI SONO I DATI PERSONALI RACCOLTI</li>
            <br />
            <p>
                “<b>Dati personali</b>” sono informazioni che ti identificano
                come individuo o si riferiscono a un individuo identificabile,
                tra cui:
                <br />
                <ol className="roman">
                    <li>Immagine/fotografia; e </li>
                    <li>indirizzo e-mail.</li>
                </ol>
            </p>
            <p>
                Potremmo dover raccogliere e trattare questi tipi di Dati
                personali per consentirti di creare un film secondo i nostri
                Termini e condizioni. La mancata condivisione da parte tua delle
                informazioni da noi richieste può compromettere la tua
                partecipazione alla creazione di un film sul nostro Microsito.
            </p>
            <li>QUAL È LA FINALITÀ DEL TRATTAMENTO DEI DATI PERSONALI?</li>
            <br />
            <p>
                Noi e i nostri fornitori di servizi possiamo utilizzare i Dati
                personali in base al tuo consenso.
            </p>
            <p>
                La tua immagine o fotografia può essere utilizzata per creare un
                film/video a tua scelta e consentirti di partecipare e
                utilizzare i servizi forniti nel presente Microsito. Nelle sedi
                offline, previo consenso, la tua immagine/fotografia e il
                film/video correlato possono essere temporaneamente visualizzati
                sul nostro tabellone pubblicitario digitale situato nella stessa
                posizione.
            </p>
            <p>
                Utilizziamo la tua e-mail per inviarti il link al film/video
                generato dopo aver utilizzato il nostro Microsito. Non
                utilizziamo i tuoi dati per nessun altro scopo. La condivisione
                del tuo indirizzo e-mail è facoltativa se desideri ricevere il
                film/video personalizzato nella tua casella di posta
                elettronica.
            </p>
            <p>
                Quando ti iscrivi alla nostra newsletter, utilizziamo il tuo
                indirizzo e-mail per inviarti gli aggiornamenti tramite
                newsletter. Puoi negare il consenso utilizzando il link di
                annullamento dell'iscrizione fornito in ogni newsletter.
            </p>
            <li>PROCESSO DECISIONALE AUTOMATIZZATO </li>
            <br />
            <p>
                Utilizziamo l'intelligenza artificiale (“<b>IA</b>”) per
                generare il tuo film/video personalizzato
            </p>
            <p>
                La tecnologia di face swap utilizza algoritmi avanzati di
                apprendimento automatico e intelligenza artificiale per
                identificare e sostituire il volto di una persona con un altro
                in immagini o video. Il processo in genere prevede il
                rilevamento del volto, l'estrazione delle caratteristiche e la
                sintesi del volto. L'algoritmo rileva prima i volti nel
                contenuto, quindi analizza le caratteristiche facciali chiave e
                infine fonde il volto sostitutivo nell'immagine o nel video
                originale, abbinando tonalità della pelle, illuminazione ed
                espressioni per garantire una transizione senza soluzione di
                continuità.
            </p>
            <li>COOKIE </li>
            <br />
            <p>
                I cookie sono piccoli file di dati che salvano e recuperano
                informazioni sulle tue visite al nostro Microsito, ad es. come
                sei entrato, come hai navigato e quali informazioni sono state
                di tuo interesse. Leggi di più su come utilizziamo i cookie
                nella nostra{' '}
                <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.qatarairways.com/it-it/legal/cookie-policy.html"
                >
                    Politica sui cookie
                </a>
                .
            </p>
            <li>TRASFERIMENTO DI DATI </li>
            <br />
            <p>
                <u>ALL'INTERNO DEL GRUPPO QATAR AIRWAYS </u>
            </p>
            <p>
                Il personale di Qatar Airways è autorizzato ad accedere ai Dati
                personali solo ed esclusivamente nella misura in cui sia
                necessario per la finalità pertinente e per svolgere le proprie
                mansioni.
            </p>
            <p>
                Come parte di un'organizzazione globale con sede centrale a
                Doha, nello Stato del Qatar, i Dati personali che raccogliamo
                possono essere trasferiti a livello internazionale in tutto il
                Gruppo Qatar Airways per scopi aziendali o amministrativi in
                Paesi in cui Qatar Airways ha sede e le cui leggi in materia di
                protezione dei dati personali potrebbero non essere così
                esaurienti come quelle vigenti nello Spazio economico europeo (“
                <b>SEE</b>”). Qatar Airways verificherà, se necessario, che
                vengano adottate opportune misure di salvaguardia per adempiere
                ai requisiti previsti in materia di trasferimento di dati
                personali a livello internazionale ai sensi delle leggi
                applicabili in materia di protezione dei dati personali e sulla
                privacy.
            </p>
            <p>
                <u>ALL'ESTERNO DEL GRUPPO QATAR AIRWAYS </u>
                <br />
                Potremmo essere tenuti a divulgare alcuni dei tuoi Dati
                personali nei seguenti casi:
            </p>
            <ol className="alpha">
                <li>
                    a terze parti, compresi i fornitori di servizi per procurare
                    un servizio o svolgere una funzione per noi o da noi
                    designati in relazione ai servizi che ti offriamo, compresi
                    coloro che agiscono come nostri agenti, inclusi, senza
                    limitazioni, fornitori di servizi di supporto, fornitori di
                    tecnologia, consulenti specializzati, consulenti legali,
                    revisori;
                </li>
                <li>
                    a terze parti in relazione a un finanziamento,
                    cartolarizzazione, assicurazione, ristrutturazione, cessione
                    o altra forma di alienazione, della totalità o di parte
                    della nostra società o delle nostre attività oppure in
                    relazione ad azioni di difesa in risposta a eventuali
                    ricorsi; e
                </li>
                <li>
                    alle autorità ufficiali, tra cui funzionari delle forze
                    dell'ordine, autorità deputate all'applicazione della legge
                    e autorità di regolamentazione: (a) se riteniamo che la
                    comunicazione sia richiesta ai sensi di una legge, norma o
                    procedimento legale applicabile (ad esempio per rispondere a
                    citazioni in giudizio o ordini giudiziari); o (b) per
                    tutelare e difendere i nostri diritti o i diritti o la
                    sicurezza di terzi, anche per difenderci da azioni legali
                    intentate nei nostri confronti.
                </li>
            </ol>
            <p>
                Ogni volta che i Dati personali vengono divulgati a terzi,
                adotteremo le misure contrattuali, tecniche e organizzative
                necessarie per garantire che tali Dati personali siano trattati
                in conformità alla legislazione applicabile. Le divulgazioni di
                cui sopra possono comportare il trasferimento di Dati personali
                da/verso Paesi al di fuori del tuo Paese di residenza, compreso
                da/verso Paesi le cui leggi in materia di protezione dei dati
                potrebbero non essere così esaurienti come quelle vigenti nello
                SEE. Qatar Airways verificherà, se necessario, che vengano
                adottate opportune misure di salvaguardia per adempiere ai
                requisiti previsti in materia di trasferimento di dati personali
                a livello internazionale ai sensi delle leggi applicabili in
                materia di protezione dei dati personali e sulla privacy.
            </p>
            <li>COME SALVAGUARDIAMO I TUOI DATI PERSONALI?</li>
            <br />
            <p>
                Qatar Airways ha implementato le misure di salvaguardia per
                garantire la riservatezza e la sicurezza dei Dati personali. Nel
                tentativo di impedire l'accesso non autorizzato ai Dati
                personali o la loro divulgazione, abbiamo posto in essere misure
                di salvaguardia fisiche, tecniche e amministrative per
                proteggere i Dati personali dalla distruzione accidentale o
                illecita, perdita accidentale, danneggiamento, alterazione,
                divulgazione o accesso non autorizzato, nonché da ogni altra
                forma di trattamento illecito.
                <br />
                Benché compiamo ogni sforzo ragionevole per proteggere i Dati
                personali, non possiamo garantire la sicurezza dei suddetti Dati
                trasmessi via Internet o attraverso connessioni simili. Se hai
                motivo di credere che la tua interazione con noi non sia più
                sicura, ti preghiamo di segnalarcelo immediatamente in
                conformità alla sezione “<b>Come contattarci</b>” qui di
                seguito.
            </p>
            <li>UTILIZZO DEL MICROSITO DA PARTE DI MINORI</li>
            <br />
            <p>
                Il presente Microsito non è rivolto a persone di età inferiore a
                diciotto (18) anni e non raccogliamo consapevolmente dati
                personali di minori.
            </p>
            <li>CONSERVAZIONE DEI DATI PERSONALI </li>
            <br />
            <p>
                Conserveremo i Dati personali per il tempo necessario a
                conseguire la finalità per cui sono stati raccolti, le finalità
                legali o commerciali di Qatar Airways, nei termini enunciati
                nell'Informativa sulla privacy o coerentemente con le nostre
                politiche interne in materia di conservazione dei dati personali
                e nella misura richiesta dalle leggi pertinenti. Ai fini
                dell'eliminazione dei Dati personali, adotteremo misure
                commercialmente ragionevoli e tecnicamente possibili per rendere
                i Dati personali irrecuperabili o non riproducibili in
                conformità alle leggi applicabili.
            </p>
            <li>UTILIZZO DEL MICROSITO DA PARTE DI MINORI </li>
            <br />
            <p>
                Il presente Microsito non è rivolto a persone di età inferiore a
                diciotto (18) anni e non raccogliamo consapevolmente dati
                personali di minori.
            </p>
            <li>I TUOI DIRITTI </li>
            <br />
            <p>
                Puoi chiedere di esercitare i tuoi diritti di accesso,
                rettifica, cancellazione, limitazione del trattamento,
                portabilità dei dati, opposizione al trattamento ovvero
                esercitare i tuoi diritti in relazione alle decisioni
                automatizzate, tra cui quelle relative alla profilazione dei
                Dati personali elaborati da Qatar Airways, in qualsiasi momento,
                inviando una richiesta dal portale dedicato alle ‘
                <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.qatarairways.com/it-it/subject-access-request-form.html"
                >
                    richieste di accesso dell'interessato
                </a>
                ’ tramite la pagina Contattaci di qatarairways.com.qa. A seconda
                del Paese in cui hai la residenza, potresti aver altri diritti
                sulla privacy, tra cui i diritti riconosciuti dalla legge in
                materia sulla privacy dello Stato della California (California
                Consumer Privacy Act, (“<b>CCPA</b>”).
            </p>
            <p>
                Prima di dar corso alla tua richiesta, Qatar Airways verificherà
                la tua identità, per essere certa di fornire le informazioni
                richieste al soggetto interessato a cui appartengono. Se hai
                affidato la richiesta a un tuo agente autorizzato, per poter
                trasmettere la richiesta per tuo conto, tu o il tuo agente
                autorizzato dovrete produrre una copia dell'autorizzazione
                scritta.
            </p>
            <p>
                Non addebiteremo alcuna tariffa per rispondere a una simile
                richiesta, a meno che la stessa sia palesemente infondata o
                sproporzionata. In seguito all'elaborazione della tua richiesta,
                ti trasmetteremo un elenco delle entità autorizzate al
                trattamento dei dati personali ai sensi della Legge sulla
                protezione dei dati personali della Repubblica Popolare Cinese.
                Se ritieni che Qatar Airways non rispetti le norme sulla privacy
                applicabili, hai il diritto di presentare ricorso avanti a
                un'autorità garante della protezione dei dati personali
                competente.
            </p>
            <p>
                Se non desideri più ricevere le nostre newsletter, puoi
                annullare l'iscrizione in qualsiasi momento cliccando sul link
                "annulla l'iscrizione" che troverai in ogni e-mail contenente la
                nostra newsletter. Inoltre, puoi anche trasmettere una richiesta
                di revoca dell'iscrizione via e-mail, chiamare il nostro
                Servizio clienti o inviare una richiesta scritta al seguente
                indirizzo:
            </p>
            <p>
                Qatar Airways Group Q.C.S.C.
                <br />
                Qatar Airways Tower 1,
                <br />
                PO Box 22550,
                <br />
                Doha, Stato del Qatar.
                <br />
                All'attenzione di: Qatar Airways Direct Marketing Team –
                E-commerce
            </p>
            <p>
                Se chiedi l'annullamento dell'iscrizione alla nostra newsletter
                a mezzo e-mail, telefono o posta ordinaria, la tua richiesta e
                l'aggiornamento dei nostri sistemi per eliminare il tuo
                indirizzo e-mail dalla mailing list potrebbero richiedere fino a
                30 giorni lavorativi.
            </p>
            <li>COME CONTATTARCI O PRESENTARE UN RECLAMO </li>
            <br />
            <p>
                Per eventuali domande sul trattamento dei tuoi Dati personali o
                sulla presente Informativa sulla privacy, ti invitiamo a
                contattarci al seguente indirizzo:
            </p>
            <p>
                Email:{' '}
                <a
                    target="_blank"
                    rel="noreferrer"
                    href="mailto:digitalbrand@qatarairways.com.qa"
                >
                    digitalbrand@qatarairways.com.qa
                </a>
            </p>
            <p>
                Qatar Airways Group Q.C.S.C.
                <br />
                Qatar Airways Tower 1,
                <br />
                PO Box 22550,
                <br />
                Doha, Stato del Qatar.
                <br />
                All'attenzione di: Ufficio Marketing
            </p>
            <p>
                Puoi anche contattare il nostro Responsabile della protezione
                dei dati personali inviando un'e-mail{' '}
                <a
                    target="_blank"
                    rel="noreferrer"
                    href="mailto:dpo@qatarairways.com.qa"
                >
                    qui
                </a>
                . Ti ricordiamo che hai anche il diritto di presentare reclamo a
                un'autorità garante competente.
            </p>
            <br />
        </ol>
    );
}
