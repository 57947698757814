import React from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '../ui/Button';
import styles from './styles.module.scss';
import closeIcon from '../../assets/icons/close-modal.svg';
import { DialogTitle } from '@mui/material';
import clsx from 'clsx';
import ArText from '../../pages/TermsAndConditions/ar';
import EnText from '../../pages/TermsAndConditions/en';
import zhCNText from '../../pages/TermsAndConditions/zh-CN';
import DeText from '../../pages/TermsAndConditions/de';
import FrText from '../../pages/TermsAndConditions/fr';
import ItText from '../../pages/TermsAndConditions/it';
import EsText from '../../pages/TermsAndConditions/es';
import PtText from '../../pages/TermsAndConditions/pt-BR';

interface Props {
    open: boolean;
    title: string;
    text: string;
    submitBtnText: string;
    handleOpen: () => void;
    handleClose: () => void;
    handleSubmit: () => void;
}

export const TermsAgreement = ({
    open,
    title,
    text,
    submitBtnText,
    handleOpen,
    handleClose,
    handleSubmit,
}: Props) => {
    const { t } = useTranslation();
    const isTablet = useMediaQuery('(max-width:768px)');

    let TCText = () => <></>;
    switch (t('legal_notice')) {
        case 'ar':
            TCText = ArText;
            break;
        case 'en':
            TCText = EnText;
            break;
        case 'zh-CN':
            TCText = zhCNText;
            break;
        // case 'de':
        //     TCText = DeText;
        //     break;
        case 'fr':
            TCText = FrText
            break;
        case 'it':
            TCText = ItText;
            break;
        case 'es':
            TCText = EsText;
            break;
        case 'pt-BR':
            TCText = PtText;
            break;
        default:
            break;
    }

    const content = (
        <>
            <button
                type="button"
                id="close-agreement-button"
                className={styles.closeBtn}
                onClick={handleClose}
                title={t('close')}
            >
                <img src={closeIcon} alt="" />
            </button>
            <DialogTitle
                sx={{
                    fontSize: isTablet ? 17 : 32,
                    lineHeight: 1.35,
                    margin: 0,
                    padding: isTablet ? '0.9375rem' : '1rem 0',
                }}
            >
                {title}
            </DialogTitle>
            <div className="qatar-legal">
                <div className="text-container agreements-dialog">
                    <div className={clsx(styles.text, 'text')}>
                        <TCText />
                    </div>
                </div>
            </div>

            <Button
                type="button"
                id="accept-agreement-button"
                className={styles.submitBtn}
                onClick={handleSubmit}
                title={submitBtnText}
            >
                {submitBtnText}
            </Button>
        </>
    );

    return isTablet ? (
        <SwipeableDrawer
            disableSwipeToOpen
            anchor="bottom"
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            className={styles.dialog}
            sx={{
                // blurring the background when the dialog is open
                backdropFilter: open ? 'blur(5px)' : 'none',
            }}
        >
            {content}
        </SwipeableDrawer>
    ) : (
        <Dialog open={open} onClose={handleClose} className={styles.dialog}>
            {content}
        </Dialog>
    );
};
