export default function ptText(){
  return (
    <ol className="decimal">
      <li>VISÃO GERAL</li>
      <br/>
      <p>A <b>Qatar Airways Group Q.C.S.C.</b> (“<b>Qatar Airways</b>”, “<b>nós</b>”, “<b>nossa</b>” ou “<b>a nós</b>”) quer que você se sinta seguro utilizando este site (“<b>Microsite</b>”) e portanto está comprometida com a proteção dos seus Dados Pessoais e seus direitos de privacidade ao acessar.</p>
      <p>Este Aviso de Privacidade da flyqatar.com (“<b>Aviso de Privacidade</b>”) (junto com nossos <a target="_blank" rel="noreferrer" href="/termsandconditions?selLang=pt-br">Termos e Condições</a> e nossa <a target="_blank" rel="noreferrer" href="https://www.qatarairways.com/pt-br/legal/cookie-policy.html">Política de Cookies</a> explica como Dados Pessoais são ou serão processados pela Qatar Airways e nossos fornecedores de serviços. Por “<b>Dados Pessoais</b>”, entendemos qualquer informação relacionada a uma pessoa física identificada ou identificável.</p>
      <p>Leia atentamente o descrito a seguir para entender como processamos seus Dados Pessoais neste Microsite. Ao divulgar Dados Pessoais em nome de outra pessoa, você é responsável e assegura que o indivíduo cujos Dados Pessoais você está fornecendo à Qatar Airways autorizou sua divulgação e foi informado com uma cópia deste Aviso de Privacidade antes de você divulgar tais Dados Pessoais.</p>
      <p>Se alterarmos este Aviso de Privacidade, informaremos a você as alterações publicando a versão atualizada em nosso Microsite. Sujeito às leis aplicáveis, a versão em inglês deste Aviso de Privacidade prevalecerá sobre qualquer versão deste Aviso de Privacidade traduzido para outro idioma.</p>
      <li>QUANDO SE APLICA ESTE AVISO DE PRIVACIDADE?</li>
      <br/>
      <p>Este Aviso de Privacidade se aplica ao processamento dos seus Dados Pessoais neste Microsite.</p>
      <li>QUEM É RESPONSÁVEL PELO PROCESSAMENTO DOS SEUS DADOS PESSOAIS?</li>
      <br/>
      <p>A Qatar Airways, com sua sede registrada na “<b>Qatar Airways Tower 1, PO Box 22550, Doha, Estado do Qatar</b>”, é responsável, como controladora, pelo processamento dos Dados Pessoais descritos neste Aviso de Privacidade.</p>
      <li>COMO COLETAMOS E RECEBEMOS DADOS PESSOAIS</li>
      <br/>
      <p>Nós e nossos fornecedores de serviços podemos coletar e receber Dados Pessoais diretamente de você de várias formas, incluindo:</p>
      <p>
        <u>Através do Microsite</u>: Podemos coletar seus Dados Pessoais através do Microsite. Por exemplo, quando você utiliza nossos serviços oferecidos no Microsite.<br/>
        <u>Off-line</u>: Em locais específicos, como nos shoppings, podemos coletar seus Dados Pessoais com base no seu interesse em utilizar os serviços oferecidos no Microsite. Por exemplo, quando você permite que suas fotos sejam tiradas através dos nossos equipamentos oficiais e carrega no Microsite para receber um video personalizado apresentando você. Nosso Microsite pode conter links que permitam você visitar sites de terceiros. Observe que não temos qualquer controle sobre como os controladores responsáveis por esses sites terceirizados processam seus Dados Pessoais e não nos responsabilizamos ou respondemos pela forma com a qual eles operam ou a maneira como processam seus Dados Pessoais. É importante que você entenda isso e verifique as respectivas políticas de privacidade e os termos de uso do site.</p>
      <li>QUAIS DADOS PESSOAIS SÃO COLETADOS</li>
      <br/>
      <p>
        “<b>Dados Pessoais</b>” são informações que identificam você como indivíduo ou relacionam-se a uma pessoa física identificável, incluindo:<br/>
        <ol className="roman">
          <li>Imagem/fotografia; e</li>
          <li>Endereço de e-mail.</li>
        </ol>
      </p>
      <p>Podemos precisar coletar e processar estes tipos de Dados Pessoais para permitir a você criar um filme de acordo com nossos Termos e Condições. Caso você não forneça as informações que nós requisitamos, pode ser que você não consiga participar na criação do filme em nosso Microsite.</p>
      <li>QUAL É A FINALIDADE DA COLETA DE DADOS PESSOAIS?</li>
      <br/>
      <p>Nós e nossos fornecedores de serviços podem utilizar Dados Pessoais com seu consentimento.</p>
      <p>Sua imagem ou fotografia pode ser utilizada para criar um filme/vídeo conforme sua escolha e permitir que você participe e utilize os serviços fornecidos neste Microsite. Em locais off-line, se você autorizar, sua imagem ou fotografia e o filme/vídeo relativo pode ser temporariamente exposto em nosso outdoor digital localizado no mesmo lugar.</p>
      <p>Nós utilizamos seu e-mail para enviar a você o link para o vídeo/filme gerado após a utilização do nosso Microsite. Não utilizamos seus dados para qualquer outra finalidade. Compartilhar seu endereço de e-mail é opcional, caso você queira receber o filme/vídeo na sua caixa de mensagem do e-mail.</p>
      <p>Ao se cadastrar para nossa newsletter, usamos seu endereço de e-mail para enviar novidades através das newsletters. A qualquer momento, você pode cancelar o recebimento utilizando o link para descadastramento enviado em cada newsletter.</p>
      <li>TOMADA DE DECISÃO AUTOMATIZADA</li>
      <br/>
      <p>Utilizamos Inteligência Artificial (“<b>IA</b>”) para gerar seu filme/vídeo.</p>
      <p>A tecnologia para mudança de rostos utiliza algorítimos avançados de aprendizado de máquina e inteligência artificial para identificar e trocar o rosto de uma pessoa por outro em imagens e vídeos. O processo envolve tipicamente identificação facial, captura das características e síntese facial. Primeiro, o algorítimo detecta as faces no conteúdo, depois, analisa as características principais da face e, por fim, mescla o rosto substituto na imagem ou vídeo original enquanto corresponde tonalidade de pele, iluminação e expressões para assegurar uma transição perfeita.</p>
      <li>COOKIES</li>
      <br/>
      <p>Cookies são pequenos arquivos de informações que armazenam e retém informações sobre sua visita ao nosso Microsite, por exemplo, como você chegou ao nosso site, como você navegou pelo site e que tipo de informação foi do seu interesse. Saiba mais sobre como utilizamos cookies em nossa <a target="_blank" rel="noreferrer" href="https://www.qatarairways.com/pt-br/legal/cookie-policy.html">política de cookies</a>.</p>
      <li>TRANSFERÊNCIA DE DADOS</li>
      <br/>
      <p><u>DENTRO DO QATAR AIRWAYS GROUP</u></p>
      <p>Funcionários da Qatar Airways estão autorizados a acessar seus Dados Pessoais apenas se e no caso quando necessário para servir a um objetivo aplicável à realização de seus trabalhos.</p>
      <p>Como parte de uma organização global com sua sede em Doha, Estado do Qatar, os Dados Pessoais que coletamos podem ser transferidos internacionalmente através do Qatar Airways Group com a finalidade de negócios ou administrativa para países onde a Qatar Airways esteja estabelecida e nos quais as leis de proteção de dados podem não ser tão abrangentes quanto aquelas da Área Econômica Europeia (“<b>AEE</b>”). Onde necessário, a Qatar Airways garantirá que medidas de proteção adequadas sejam adotadas para cumprir com os requisitos de transferência internacional de dados pessoais sob as leis de proteção de dados e de privacidade aplicáveis.</p>
      <p><u>FORA DO QATAR AIRWAYS GROUP</u><br/>Pode ser necessário divulgarmos determinados Dados Pessoais seus nos seguintes casos:</p>
      <ol className="alpha">
        <li>a terceiros, incluindo fornecedores de serviços, para fornecerem um serviço ou realizarem uma função para nós ou que sejam também nomeados por nós relacionados a outros serviços que oferecemos a você, inclusive aqueles que estejam atuando como nossos agentes, incluindo, sem limitação, suporte aos fornecedores de serviço, fornecedores de tecnologia, consultores especializados, conselheiros jurídicos, auditores;</li>
        <li>para terceiros, em conexão com propostas ou atuais financiamentos, securitização, seguros, reestruturação, designações ou outras disposições de toda ou parte dos nossos negócios ou bens ou em relação à defesa de reivindicações: e</li>
        <li>para agentes oficiais, incluindo oficiais de manutenção da ordem, autoridades regulatórias, jurídicas e governamentais: (a) caso acreditemos que a divulgação seja exigida por qualquer lei aplicável, regulamentação ou procedimentos legais (como responder a intimações e ordens judiciais); ou (b) para proteger e defender nossos direitos, ou os direitos ou a segurança de terceiros, inclusive defesa contra causas legais;</li>
      </ol>
      <p>Quando Dados Pessoais são divulgados a terceiros, tomaremos as medidas necessárias contratuais, técnicas e organizacionais para devidamente protegermos tais Dados Pessoais de acordo com a legislação aplicável. A divulgação mencionada acima pode envolver a transferência de Dados Pessoais de e para países fora do seu país de residência, inclusive de e para países nos quais as leis de proteção de dados podem não ser tão abrangentes quanto aquelas da AEE. Onde necessário, a Qatar Airways garantirá que medidas de proteção adequadas sejam adotadas para cumprir com os requisitos de transferência internacional de dados pessoais sob as leis de proteção de dados e de privacidade aplicáveis.</p>
      <li>COMO MANTEMOS SEUS DADOS SEGUROS?</li>
      <br/>
      <p>A Qatar Airways adotou medidas para resguardar seus Dados Pessoais, já que estamos comprometidos em assegurar que seus Dados Pessoais estejam seguros. Em um esforço de prevenir acesso não autorizado, ou divulgação dos seus Dados Pessoais, implementamos medidas de segurança técnica, física e administrativa para proteger seus Dados Pessoais contra destruição ilegal ou acidental ou perda acidental, danos, alteração, acesso ou divulgação não autorizada ou acesso, assim como outras formas de procedimentos ilegais. Embora utilizemos esforços necessários e apropriados para proteger seus Dados Pessoais, não podemos garantir a segurança dos seus Dados Pessoais transmitidos por meio da internet ou conexões similares. Caso você tenha motivos para acreditar que sua interação conosco não está mais segura, notifique-nos imediatamente conforme descrito na seção “<b>Como entrar em contato conosco</b>” abaixo.</p>
      <li>USO DO MICROSITE POR MENORES</li>
      <br/>
      <p>Este Microsite não é direcionado aos indivíduos com idade abaixo de dezoito (18) anos e, conscientemente, não coletamos Dados Pessoais de indivíduos menores de 18 anos.</p>
      <li>RETENÇÃO DE DADOS</li>
      <br/>
      <p>Reteremos os Dados Pessoais pelo tempo necessário para o cumprimento dos objetivos específicos para os quais tenham sido coletados, com objetivos legais ou comerciais da Qatar Airways conforme descrito neste Aviso de Privacidade e conforme a nossa política de retenção de dados, ou conforme necessário de acordo com as leis em questão. Ao excluir os Dados Pessoais, tomamos medidas comerciais cabíveis e técnicas possíveis para tornar seus Dados Pessoais irrecuperáveis ou irreprodutíveis de acordo com as leis aplicáveis</p>
      <li>USO DO MICROSITE POR MENORES</li>
      <br/>
      <p>Este Microsite não é direcionado aos indivíduos com idade abaixo de dezoito (18) anos e, conscientemente, não coletamos Dados Pessoais de indivíduos menores de 18 anos.</p>
      <li>SEUS DIREITOS</li>
      <br/>
      <p>Você pode, a qualquer momento, exercitar seus direitos de acessar, retificar, apagar, restringir o processamento, portabilidade de dados, contestar ou direitos em relação à tomada de decisão individual automatizada, inclusive caracterização de perfil, em relação aos Dados Pessoais que a Qatar Airways processa ao enviar uma solicitação através do portal específico de “<a target="_blank" rel="noreferrer" href="https://www.qatarairways.com/en/subject-access-request-form.html">solicitação de acesso de informações</a>” na página Fale conosco no qatarairways.com.qa. Você pode ter outros direitos de privacidade, inclusive direitos sob a Lei de Privacidade do Consumidor da Califórnia (“<b>CCPA</b>”), dependendo do seu país de residência.</p>
      <p>Antes de proceder com a sua solicitação, verificaremos a sua identidade para garantir que fornecemos as informações solicitadas ao titular dos dados a quem elas pertencem. Caso você tenha autorizado um agente a fazer a solicitação por você, pedimos que você ou seu agente autorizado nos forneça uma cópia por escrito da permissão para fazer a solicitação em seu nome.</p>
      <p>Não cobraremos uma taxa para responder à sua solicitação, a menos que a solicitação possa ser considerada manifestamente infundada ou excessiva. Mediante solicitação, podemos fornecer a você uma lista de manipuladores de informações pessoais de terceiros sob a Lei de Proteção de Informações Pessoais da República Popular da China. Caso você sinta que não estamos cumprindo com as normas aplicáveis de privacidade, você tem o direito de fazer uma reclamação junto à autoridade competente de proteção de dados.</p>
      <p>Caso não queira mais receber as nossas newsletters, você pode se descadastrar a qualquer momento clicando no link "descadastrar" em qualquer e-mail contendo nossa newsletter. Além disso, você pode gerar seu pedido de descadastramento enviando um e-mail ou ligando para nosso Serviço de Atendimento ao Cliente ou enviando uma solicitação por correio para o endereço a seguir:</p>
      <p>
        Qatar Airways Group Q.C.S.C.<br/>
        Qatar Airways Tower 1,<br/>
        PO Box 22550,<br/>
        Doha, State of Qatar.<br/>
        Att: Qatar Airways Direct Marketing Team - Ecommerce
      </p>
      <p>Ao solicitar o seu descadastramento por e-mail, ligação ou postagem, pode levar até 30 dias úteis para processarmos a sua solicitação e atualizar os nossos sistemas para remover o seu endereço de e-mail da nossa lista de endereços.</p>
      <li>COMO ENTRAR EM CONTATO CONOSCO OU APRESENTAR UMA RECLAMAÇÃO</li>
      <br/>
      <p>Caso você tenha alguma dúvida ou perguntas sobre o processamento dos seus Dados Pessoais ou sobre este Aviso de Privacidade, entre em contato com:</p>
      <p>Email: <a target="_blank" rel="noreferrer" href="mailto:digitalbrand@qatarairways.com.qa">digitalbrand@qatarairways.com.qa</a></p>
      <p>
        Qatar Airways Group Q.C.S.C.<br/>
        Qatar Airways Tower 1,<br/>
        PO Box 22550,<br/>
        Doha, State of Qatar.<br/>
        Att: Marketing Department
      </p>
      <p>Você também pode entrar em contato com um Agente de Proteção de Dados enviando um e-mail <a target="_blank" rel="noreferrer" href="mailto:dpo@qatarairways.com.qa">aqui</a>. Observe que você também tem o direito de apresentar uma queixa com uma autoridade supervisora competente.</p>
      <br/>
    </ol>
  )
}
