import React, { useEffect } from 'react';
import clsx from 'clsx';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import ArText from './ar';
import EnText from './en';
import zhCNText from './zh-CN';
import FrText from './fr';
import DeText from './de';
import ItText from './it';
import EsText from './es';
import PtText from './pt-BR';

const TermsAndConditions = () => {
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('tc_page_title');
    }, [t]);

    let TCText = () => <></>;
    switch (t('legal_notice')) {
        case 'ar':
            TCText = ArText;
            break;
        case 'en':
            TCText = EnText;
            break;
        case 'zh-CN':
            TCText = zhCNText;
            break;
        // case 'de':
        //     TCText = DeText;
        //     break;
        case 'fr':
            TCText = FrText;
            break;
        case 'it':
            TCText = ItText;
            break;
        case 'es':
            TCText = EsText;
            break;
        case 'pt-BR':
            TCText = PtText;
            break;
        default:
            break;
    }

    return (
        <div className={clsx(styles.wrap, 'wrap', 'qatar-legal')}>
            <p className={clsx('h1', styles.title)}>{t('tc_content_title')}</p>
            <div className="text-container">
                <div className={clsx(styles.text, 'text')}>
                    <TCText />
                </div>
            </div>
        </div>
    );
};

export default TermsAndConditions;
